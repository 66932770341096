// import React, { useCallback, useEffect, useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { getAllTask } from "../../actions/Expert";
// import { DataGrid } from '@mui/x-data-grid';
// import { Box, Typography, useTheme, Grid } from '@mui/material';
// import { tokens } from '../../theme';
// import moment from 'moment';
// import TaskAltIcon from '@mui/icons-material/TaskAlt';
// import CancelIcon from '@mui/icons-material/Cancel';
// import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';
// import axios from 'axios';

// const Payment = () => {
//   const [expertSingle, setSingleExpert] = useState({});
//   const { complatedTasks } = useSelector((state) => state.expert);
//   const dispatch = useDispatch();
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);

//   useEffect(() => {
//     dispatch(getAllTask());
//   }, [dispatch]);

//   const getExpert = useCallback(async () => {
//     try {
//       const token = localStorage.getItem('admin');
//       const { data } = await axios.get(`/api/v1/expert/me`, {
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: token,
//         },
//       });
//       setSingleExpert(data);
//     } catch (error) {
//       console.log(error);
//     }
//   }, []);

//   useEffect(() => {
//     getExpert();
//   }, [getExpert]);

//   const getStatusColor = (status) => {
//     switch (status) {
//       case 'Pending':
//         return '#FFD700';
//       case 'Assigned':
//         return '#1E90FF';
//       case 'Reschedule':
//         return '#FFA500';
//       case 'Complete':
//         return '#008000';
//       case 'Cancelled':
//         return '#FF0000';
//       default:
//         return '#000000';
//     }
//   };

//   const { totalPendingPayment, totalReceivedPayment, totalLastMonthEarningPayment, totalCurrentMonthEarningPayment } = expertSingle;

//   const columns = [
//     { field: 'candidateName', headerName: 'Candidate Name', width: 150 },
//     { field: 'candidateType', headerName: 'Candidate Type', width: 150 },
//     { field: 'taskType', headerName: 'Task Type', width: 150 },
//     { field: 'technology', headerName: 'Technology', width: 150 },
//     { field: 'estTime', headerName: 'EST Time', width: 200 },
//     { field: 'ISTTIME', headerName: 'IST Time', width: 200 },
//     { field: 'status', headerName: 'Task Status', width: 150, renderCell: (params) => (
//       <Box sx={{ color: getStatusColor(params.value) }}>
//         {params.value}
//       </Box>
//     ) },
//     { field: 'expertPrice', headerName: 'Task Price', width: 150 },
//     { field: 'ExpertPaymentStatus', headerName: 'Payment Status', width: 150, renderCell: (params) => (
//       params.value === "Completed" ? <TaskAltIcon color="success" /> :
//         params.value === "Unpaid" ? <CancelIcon color="error" /> :
//           params.value === "Pending" ? <HourglassDisabledIcon color="action" /> : params.value
//     ) }
//   ];

//   const rows = complatedTasks ? complatedTasks.map((task) => ({
//     id: task._id,
//     estTime: moment(task.ESTTIME).format('D/M/YYYY hh:mm A'),
//     candidateName: task.candidateName,
//     candidateType: task.candidateType,
//     taskType: task.taskType,
//     technology: task.candidateId ? task.candidateId.technology : "",
//     PaymentStatus: task.PaymentStatus,
//     ExpertPaymentStatus: task.ExpertPaymentStatus,
//     ISTTIME: moment(task.ISTTIME).format('D/M/YYYY hh:mm A'),
//     status: task.status,
//     expertPrice: task.expertPrice,
//   })) : [];

//   return (
//     <Box sx={{ width: '100%', mt: 2 }}>
//       <Typography variant="h4" align="center" gutterBottom sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } }}>
//         Payment Details
//       </Typography>

//       <Grid container justifyContent="center" spacing={2} sx={{ mt: 2, mb: 4 }}>
//         <Grid item xs={12} sm={6} md={3}>
//           <Box
//             sx={{
//               width: '100%',
//               maxWidth: 300,
//               boxShadow: 5,
//               borderRadius: 5,
//               bgcolor: colors.blueAccent[800],
//               color: 'white',
//               p: 2,
//               mb: { xs: 2, sm: 0 },
//             }}
//           >
//             <Typography variant="h6" align="center" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>Total Earning</Typography>
//             <Typography variant="h5" align="center" sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>{totalReceivedPayment}</Typography>
//           </Box>
//         </Grid>
//         <Grid item xs={12} sm={6} md={3}>
//           <Box
//             sx={{
//               width: '100%',
//               maxWidth: 300,
//               boxShadow: 5,
//               borderRadius: 5,
//               bgcolor: colors.blueAccent[800],
//               color: 'white',
//               p: 2,
//               mb: { xs: 2, sm: 0 },
//             }}
//           >
//             <Typography variant="h6" align="center" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>Last Month Earning</Typography>
//             <Typography variant="h5" align="center" sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>{totalLastMonthEarningPayment}</Typography>
//           </Box>
//         </Grid>
//         <Grid item xs={12} sm={6} md={3}>
//           <Box
//             sx={{
//               width: '100%',
//               maxWidth: 300,
//               boxShadow: 5,
//               borderRadius: 5,
//               bgcolor: colors.blueAccent[800],
//               color: 'white',
//               p: 2,
//               mb: { xs: 2, sm: 0 },
//             }}
//           >
//             <Typography variant="h6" align="center" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>Total Pending Payment</Typography>
//             <Typography variant="h5" align="center" sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>{totalPendingPayment}</Typography>
//           </Box>
//         </Grid>
//         <Grid item xs={12} sm={6} md={3}>
//           <Box
//             sx={{
//               width: '100%',
//               maxWidth: 300,
//               boxShadow: 5,
//               borderRadius: 5,
//               bgcolor: colors.blueAccent[800],
//               color: 'white',
//               p: 2,
//               mb: { xs: 2, sm: 0 },
//             }}
//           >
//             <Typography variant="h6" align="center" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>Current Month Pending</Typography>
//             <Typography variant="h5" align="center" sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>{totalCurrentMonthEarningPayment}</Typography>
//           </Box>
//         </Grid>
//       </Grid>

//       <Box sx={{ height: 600, width: '95%', mx: 'auto' }}>
//         <DataGrid
//           rows={rows}
//           columns={columns}
//           pageSize={10}
//           rowsPerPageOptions={[5, 10, 20]}
//           checkboxSelection={false}
//           disableSelectionOnClick
//           sx={{
//             '.MuiDataGrid-root': {
//               bgcolor: colors.primary[600],
//             },
//             '.MuiDataGrid-cell': {
//               color: colors.grey[100],
//             },
//             '.MuiDataGrid-columnHeader': {
//               bgcolor: colors.primary[700],
//               color: colors.greenAccent[500],
//             },
//             '.MuiCheckbox-root': {
//               color: colors.grey[100],
//             },
//             '& .Mui-disabled': {
//               pointerEvents: 'none',
//               opacity: 0.6,
//             },
//             '& .highlight-row': {
//               backgroundColor: 'rgba(255, 215, 0, 0.3)',
//             },
//           }}
//         />
//       </Box>
//     </Box>
//   );
// };

// export default Payment;


import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllTask } from "../../actions/Expert";
import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography, useTheme, Grid } from '@mui/material';
import { tokens } from '../../theme';
import moment from 'moment';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';
import axios from 'axios';

const Payment = () => {
  const [expertSingle, setSingleExpert] = useState({});
  const { complatedTasks } = useSelector((state) => state.expert);
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    dispatch(getAllTask());
  }, [dispatch]);

  const getExpert = useCallback(async () => {
    try {
      const token = localStorage.getItem('admin');
      const { data } = await axios.get(`/api/v1/expert/me`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      });
      setSingleExpert(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getExpert();
  }, [getExpert]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Pending':
        return '#FFD700';
      case 'Assigned':
        return '#1E90FF';
      case 'Reschedule':
        return '#FFA500';
      case 'Complete':
        return '#008000';
      case 'Cancelled':
        return '#FF0000';
      default:
        return '#000000';
    }
  };

  const { totalPendingPayment, totalReceivedPayment, totalLastMonthEarningPayment, totalCurrentMonthEarningPayment,totalCurrentMonthPendingPayment } = expertSingle;

  const columns = [
    { field: 'candidateName', headerName: 'Candidate Name', width: 150 },
    { field: 'candidateType', headerName: 'Candidate Type', width: 150 },
    { field: 'taskType', headerName: 'Task Type', width: 150 },
    { field: 'technology', headerName: 'Technology', width: 150 },
    { field: 'estTime', headerName: 'EST Time', width: 200 },
    { field: 'ISTTIME', headerName: 'IST Time', width: 200 },
    { field: 'status', headerName: 'Task Status', width: 150, renderCell: (params) => (
      <Box sx={{ color: getStatusColor(params.value) }}>
        {params.value}
      </Box>
    ) },
    { field: 'expertPrice', headerName: 'Task Price', width: 150 },
    { field: 'ExpertPaymentStatus', headerName: 'Payment Status', width: 150, renderCell: (params) => (
      params.value === "Completed" ? <TaskAltIcon color="success" /> :
        params.value === "Unpaid" ? <CancelIcon color="error" /> :
          params.value === "Pending" ? <HourglassDisabledIcon color="action" /> : params.value
    ) }
  ];

  const rows = complatedTasks ? complatedTasks.map((task) => ({
    id: task._id,
    estTime: moment(task.ESTTIME).format('D/M/YYYY hh:mm A'),
    candidateName: task.candidateName,
    candidateType: task.candidateType,
    taskType: task.taskType,
    technology: task.candidateId ? task.candidateId.technology : "",
    PaymentStatus: task.PaymentStatus,
    ExpertPaymentStatus: task.ExpertPaymentStatus,
    ISTTIME: moment(task.ISTTIME).format('D/M/YYYY hh:mm A'),
    status: task.status,
    expertPrice: task.expertPrice,
  })) : [];

  return (
    <Box sx={{ width: '100%', p: { xs: 2, md: 4 } }}>
      <Grid container justifyContent="center" spacing={2} sx={{ mt: 2, mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              width: '100%',
              maxWidth: 300,
              boxShadow: 5,
              borderRadius: 5,
              bgcolor: colors.blueAccent[800],
              color: 'white',
              p: 2,
              mb: { xs: 2, sm: 0 },
            }}
          >
            <Typography variant="h6" align="center" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>Total Earning</Typography>
            <Typography variant="h5" align="center" sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>{totalReceivedPayment}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              width: '100%',
              maxWidth: 300,
              boxShadow: 5,
              borderRadius: 5,
              bgcolor: colors.blueAccent[800],
              color: 'white',
              p: 2,
              mb: { xs: 2, sm: 0 },
            }}
          >
            <Typography variant="h6" align="center" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>Last Month Earning</Typography>
            <Typography variant="h5" align="center" sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>{totalLastMonthEarningPayment}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              width: '100%',
              maxWidth: 300,
              boxShadow: 5,
              borderRadius: 5,
              bgcolor: colors.blueAccent[800],
              color: 'white',
              p: 2,
              mb: { xs: 2, sm: 0 },
            }}
          >
            <Typography variant="h6" align="center" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>Total Pending Payment</Typography>
            <Typography variant="h5" align="center" sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>{totalPendingPayment}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              width: '100%',
              maxWidth: 300,
              boxShadow: 5,
              borderRadius: 5,
              bgcolor: colors.blueAccent[800],
              color: 'white',
              p: 2,
              mb: { xs: 2, sm: 0 },
            }}
          >
            <Typography variant="h6" align="center" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>Current Month Earning</Typography>
            <Typography variant="h5" align="center" sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>{totalCurrentMonthEarningPayment}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              width: '100%',
              maxWidth: 300,
              boxShadow: 5,
              borderRadius: 5,
              bgcolor: colors.blueAccent[800],
              color: 'white',
              p: 2,
              mb: { xs: 2, sm: 0 },
            }}
          >
            <Typography variant="h6" align="center" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>Current Month Pending</Typography>
            <Typography variant="h5" align="center" sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>{totalCurrentMonthPendingPayment}</Typography>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ height: 500, width: '100%', mt: 2 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5, 10, 20]}
          checkboxSelection={false}
          disableSelectionOnClick
          sx={{
            bgcolor: colors.primary[600],
            '& .MuiDataGrid-cell': {
              color: colors.grey[100],
            },
            '& .MuiDataGrid-columnHeader': {
              bgcolor: colors.primary[700],
              color: colors.greenAccent[500],
            },
            '& .MuiCheckbox-root': {
              color: colors.grey[100],
            },
            '& .Mui-disabled': {
              pointerEvents: 'none',
              opacity: 0.6,
            },
            '& .highlight-row': {
              backgroundColor: 'rgba(255, 215, 0, 0.3)',
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default Payment;
