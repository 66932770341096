import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, 
  TextField,
  Button,
  FormControl,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { fetchAllTasks, giveFeedback } from '../../actions/Admin';
import toast from 'react-hot-toast';

const GiveFeedBack = ({ open, onClose,taskId }) => {
  const [feedback, setFeedback] = useState('');
  const [screenshot, setScreenshot] = useState(null);
 const dispatch=useDispatch();

  const handleFileChange = (e) => {
    setScreenshot(e.target.files[0]);
  };

  const handleSubmit = () => {
     dispatch(giveFeedback({taskId,feedback,screenshot}))
     .then(() => {
      toast.success('Feedback submitted successfully!');
      dispatch(fetchAllTasks());
      onClose();
    })
    .catch(() => {
      toast.error('Failed to Feedback submitt');
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Give Feedback</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <DialogTitle><sup>*</sup>Screenshot</DialogTitle>
          <TextField required type="file" onChange={handleFileChange} />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
          required
            label="Feedback"
            multiline
            rows={3}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GiveFeedBack;
