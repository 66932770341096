import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCandidate, updateCandidate } from '../../actions/Admin';
import { FaRegEye, FaEdit } from 'react-icons/fa';
import axios from 'axios';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  useTheme,
  Box,
  Typography
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import EditCandidate from './EditCandidate';

export default function BasicTable() {
  const [cvUrl, setCvUrl] = React.useState(null);
  const [openCV, setOpenCV] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [editCandidate, setEditCandidate] = React.useState({});
  const { candidates } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  React.useEffect(() => {
    dispatch(getAllCandidate());
  }, [dispatch]);

  const getCv = async (candidateId) => {
    const token = localStorage.getItem('admin');
    let config = {
      method: 'get',
      url: `/api/v1/admin/candidate/${candidateId}/cv`,
      headers: {
        Authorization: token,
      },
      responseType: 'blob',
    };
    try {
      const response = await axios(config);
      const file = new Blob([response.data], { type: response.data.type });
      const fileURL = URL.createObjectURL(file);
      setCvUrl(fileURL);
      setOpenCV(true);
    } catch (error) {
      console.error('Error fetching the CV:', error);
    }
  };
 
  const handleEditClick = (candidate) => {
    setEditCandidate(candidate);
    setOpenEdit(true);
  };

  const handleEditChange = (e) => {
    const { name, value, files } = e.target;
    setEditCandidate({
      ...editCandidate,
      [name]: name === 'cv' ? files[0] : value,
    });
  };

  const handleEditSubmit = async () => {
    const formData = new FormData();
    for (const key in editCandidate) {
      formData.append(key, editCandidate[key]);
    }
    dispatch(updateCandidate(editCandidate._id, formData)).then((result) => {
      if (result.success) {
        dispatch(getAllCandidate());
        setOpenEdit(false);
      }
    });
  };

  const handleCloseCV = () => {
    setCvUrl(null);
    setOpenCV(false);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const columns = [
    { field: 'name', headerName: 'CANDIDATE NAME', flex: 1 },
    { field: 'candidateType', headerName: 'CANDIDATE TYPE', flex: 1 },
    { field: 'pocId', headerName: 'POC', flex: 1, renderCell: (params) => params.row.pocId?.name || '' },
    { field: 'email', headerName: 'EMAIL', flex: 1 },
    { field: 'phone', headerName: 'PHONE', flex: 1 },
    { field: 'role', headerName: 'ROLE', flex: 1 },
    { field: 'technology', headerName: 'TECHNOLOGY', flex: 1 },
    {
      field: 'cv',
      headerName: 'CV',
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="outlined"
          sx={{ color: colors.grey[100], borderColor: colors.grey[100] }}
          disabled={!params.row.cv}
          onClick={() => getCv(params.row._id)}
        >
          <FaRegEye />
        </Button>
      ),
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="outlined"
          sx={{ color: colors.grey[100], borderColor: colors.grey[100] }}
          onClick={() => handleEditClick(params.row)}
        >
          <FaEdit />
        </Button>
      ),
    },
  ];

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px',marginLeft:"15rem" }}>
        <Box sx={{ height: '70vh', width: '90%' }}>
          <Typography variant="h6" component="div" sx={{ textAlign: 'center', padding: '10px' }}>
            Candidate Details
          </Typography>
          <DataGrid
            rows={candidates || []}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            getRowId={(row) => row._id}
            disableColumnMenu
            autoHeight
            disableExtendRowFullWidth
            sx={{
            '.MuiDataGrid-root': {
              bgcolor: colors.primary[600],
            },
            '.MuiDataGrid-cell': {
              color: colors.grey[100],
            },
            '.MuiDataGrid-columnHeader': {
              bgcolor: colors.primary[700],
              color: colors.greenAccent[500],
            },
            '.MuiCheckbox-root': {
              color: colors.grey[100],
            },
            '& .Mui-disabled': {
              pointerEvents: 'none',
              opacity: 0.6,
            },
          }}
          />
        </Box>

        <Dialog open={openCV} onClose={handleCloseCV} fullWidth maxWidth="lg">
          <DialogTitle sx={{ backgroundColor: colors.primary[600], color: colors.grey[100] }}>View Candidate CV</DialogTitle>
          <DialogContent sx={{ backgroundColor: colors.primary[700], color: colors.grey[100] }}>
            {cvUrl && (
              <iframe src={cvUrl} title="Candidate CV" style={{ width: '100%', height: '600px', border: 'none' }} />
            )}
          </DialogContent>
          <DialogActions sx={{ backgroundColor: colors.primary[700], color: colors.grey[100] }}>
            <Button onClick={handleCloseCV} sx={{ color: colors.grey[100] }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <EditCandidate
          open={openEdit}
          handleClose={handleCloseEdit}
          candidate={editCandidate}
          handleEditChange={handleEditChange}
          handleEditSubmit={handleEditSubmit}
        />
      </Box>
    </>
  );
}
