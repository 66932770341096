import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearError } from "../../reducers/POC";
import toast from "react-hot-toast";
import Loader from "../Loading/Loading";
// import { getAllCandidate, getAllTask, addSubPoc } from "../../actions/POC";
import { getAllCandidate, getAllTask,  } from "../../actions/POC";
import {
  Container,
  Typography,
  Button,
  Fab,
  // Dialog,
  // DialogTitle,
  // DialogContent,
  // DialogContentText,
  // TextField,
  // DialogActions,
  Box, 
  Grid,
  useTheme,
} from "@mui/material";
import AddTask from "./AddTask";
import moment from "moment";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';

const DashboardPOC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const { tasks, isLoading, error } = useSelector((state) => state.poc);
  const [formOpen, setFormOpen] = useState(false);
  // const [subPoc, setSubPoc] = useState("");
  // const [open, setOpen] = useState(false);
  // const [formType, setFormType] = useState("");
  // const [currentTaskId, setCurrentTaskId] = useState(null);
  const [pocSingle, setSinglePoc] = useState({});

  const getPoc = async () => {
    try {
      const token = localStorage.getItem("admin");
      const { data } = await axios.get(`/api/v1/poc/me`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      setSinglePoc(data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getPoc();
  }, []);

  const {
    totalPendingPayment,
    currentMonthRemainingAmount,
    totalCandidateCount,
    currentMonthTasks,
    totalTasks,
  } = pocSingle;

  useEffect(() => {
    dispatch(getAllTask());
    dispatch(getAllCandidate());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
  }, [error, dispatch]);

  const handleAddTaskClick = () => {
    setFormOpen(true);
  };

  const handleFormClose = () => {
    setFormOpen(false);
  };

  // const handleAddTagClick = (taskId) => {
  //   setCurrentTaskId(taskId);
  //   setOpen(true);
  //   setFormType("tag");
  // };

  // const handleSubmitSubPoc = () => {
  //   dispatch(addSubPoc({ taskId: currentTaskId, subPoc }));
  //   setOpen(false);
  //   setSubPoc("");
  // };

  const getStatusColor = (status) => {
    switch (status) {
      case "Pending":
        return "#FFD700";
      case "Assigned":
        return "#1E90FF";
      case "Reschedule":
        return "#FFA500";
      case "Complete":
        return "#008000";
      case "Cancelled":
        return "#FF0000";
      default:
        return "#000000";
    }
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const getRelativeDate = (date) => {
    const now = moment().startOf("day");
    const targetDate = moment(date).startOf("day");
    const diff = targetDate.diff(now, "days");
    const time = moment(date).format("hh:mm A");

    if (diff === 0) {
      return `Today ${time}`;
    } else if (diff === 1) {
      return `Tomorrow ${time}`;
    } else if (diff === -1) {
      return `Yesterday ${time}`;
    } else {
      return moment(date).format("D/M/YYYY hh:mm A");
    }
  };

  const filteredTasks = tasks.filter((task) => {
    const istTimeRelative = getRelativeDate(task.ISTTIME);
    return (
      istTimeRelative.startsWith("Today") ||
      istTimeRelative.startsWith("Tomorrow") ||
      istTimeRelative.startsWith("Yesterday")
    );
  });

  const rows = filteredTasks.map((task) => ({
    id: task._id,
    candidateName: task.candidateName,
    candidateType: task.candidateType,
    technology: task.candidateId?task.candidateId.technology:"",
    taskType: task.taskType,
    pocPrice: task.pocPrice,
    istTime: getRelativeDate(task.ISTTIME),
    estTime: getRelativeDate(task.ESTTIME),
    taskDuration: `${task.hour}:${task.minute}`,
    subPoc: task.candidateId && task.candidateId.subPoc ? task.candidateId.subPoc : '-',
    paymentStatus: task.PaymentStatus,
    status: task.status,
    pocId: task.pocId ? task.pocId.name : "",
  }));

  const columns = [
    { field: "estTime", headerName: "EST TIME", flex: 1.5 },
    {
      field: "candidateName",
      headerName: "CANDIDATE NAME",
      flex: 2,
      renderCell: (params) => (
        <Box>
          <Typography sx={{ color: colors.grey[100] }}>
            {params.row.candidateName}
          </Typography>
          <Typography sx={{ color: colors.grey[100] }}>
            {params.technology}
          </Typography>
        </Box>
      ),
    },
    { field: "technology", headerName: "TECHNOLOGY", flex: 1,rendercell:(params)=>(
      <Typography>{params.row.candidateId.technology}</Typography>
    ) },
    {
      field: "taskDetails",
      headerName: "TASK TYPE / POC PRICE(₹)",
      flex: 2,
      renderCell: (params) => (
        <Box>
          <Typography sx={{ color: colors.grey[100] }}>
            {params.row.taskType}
          </Typography>
          <Typography sx={{ color: colors.grey[100] }}>
            ₹{params.row.pocPrice}
          </Typography>
        </Box>
      ),
    },
 
    { field: "istTime", headerName: "IST TIME", flex: 1.5 },
    { field: "taskDuration", headerName: "TASK DURATION", flex: 1 },
    { field: "subPoc", headerName: "TAG", flex: 1 },
    // { field: "paymentStatus", headerName: "PAYMENT(₹)", flex: 1 },
    {
      field: 'paymentStatus',
      headerName: 'Payment(₹)',
      width: 150,
       headerAlign: 'center', align: 'center',
      renderCell: (params) => (
        params.value === 'Completed' ? <TaskAltIcon color='success' /> :
        params.value === 'Unpaid' ? <CancelIcon color='error' /> :
        params.value === 'Pending' ? <HourglassDisabledIcon color='action' /> :
        params.value
      ),
    },
    {
      field: "status",
      headerName: "STATUS",
      flex: 1.5,
      renderCell: (params) => (
        <Fab
          variant="extended"
          style={{
            color: "black",
            backgroundColor: getStatusColor(params.value),
            height: "1.5rem",
            width: "7rem",
          }}
        >
          <strong>{params.value}</strong>
        </Fab>
      ),
    },
  ];

  return (
    <>
      <Grid
        container
        justifyContent="center"
        spacing={2}
        style={{
          marginTop: "10px",
          minWidth: "60%",
          maxWidth: "100%",
          position: "sticky",
        }}
      >
        <Grid item xs={6} sm={4}>
          <Box
            width="100%"
            maxWidth="300px"
            boxShadow={5}
            borderRadius={5}
            bgcolor={colors.primary[400]}
            color={colors.grey[100]}
            p={2}
            mx="auto"
          >
            <Typography variant="h6" align="center">
              Total Candidate
            </Typography>
            <Typography variant="h5" align="center">
              {totalCandidateCount}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Box
            width="100%"
            maxWidth="300px"
            boxShadow={5}
            borderRadius={5}
            bgcolor={colors.primary[400]}
            color={colors.grey[100]}
            p={2}
            mx="auto"
          >
            <Typography variant="h6" align="center">
              Monthly Tasks
            </Typography>
            <Typography variant="h5" align="center">
              {currentMonthTasks}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Box
            width="100%"
            maxWidth="300px"
            boxShadow={5}
            borderRadius={5}
            bgcolor={colors.primary[400]}
            color={colors.grey[100]}
            p={2}
            mx="auto"
          >
            <Typography variant="h6" align="center">
              Total Tasks
            </Typography>
            <Typography variant="h5" align="center">
              {totalTasks}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Box
            width="100%"
            maxWidth="300px"
            boxShadow={5}
            borderRadius={5}
            bgcolor={colors.primary[400]}
            color={colors.grey[100]}
            p={2}
            mx="auto"
          >
            <Typography variant="h6" align="center">
              Total Remaining Amount
            </Typography>
            <Typography variant="h5" align="center">
              {totalPendingPayment}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Box
            width="100%"
            maxWidth="300px"
            boxShadow={5}
            borderRadius={5}
            bgcolor={colors.primary[400]}
            color={colors.grey[100]}
            p={2}
            mx="auto"
          >
            <Typography variant="h6" align="center">
              Monthly Remaining Amount
            </Typography>
            <Typography variant="h5" align="center">
              {currentMonthRemainingAmount}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* <Dialog
        open={open && formType === "tag"}
        onClose={handleClose}
        aria-labelledby="company-form-dialog-title"
      >
        <DialogTitle id="tag-form-dialog-title">Add Tag</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill in the details to add a new tag.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="subPoc"
            label="Tag Name"
            placeholder="Enter Tag Name"
            type="text"
            fullWidth
            value={subPoc}
            onChange={(e) => setSubPoc(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmitSubPoc} color="primary" >
            Add
          </Button>
        </DialogActions>
      </Dialog> */}
      
      <Container style={{ maxWidth: "1750px"}}>
        <Button
          variant="outlined"
          sx={{
            backgroundColor: colors.greenAccent[500],
            color: colors.grey[900],
            position: "relative",
            "&:hover": {
              backgroundColor: colors.greenAccent[700],
              borderColor: colors.greenAccent[700],
            },
          }}
          onClick={handleAddTaskClick}
        >
          Add Task
        </Button>
        {formOpen && <AddTask open={formOpen} handleClose={handleFormClose} />}
        {isLoading ? (
          <Loader />
        ) : (
          <Box sx={{ height: "60vh", width: "100%", marginTop: "3rem" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              sortModel={[{ field: "estTime", sort: "desc" }]}
              sx={{
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: `${colors.blueAccent[100]} !important `,
                  color: colors.greenAccent[500],
                },
                "& .MuiDataGrid-cell": {
                  color: colors.grey[100],
                },
                "& .MuiDataGrid-row:hover": {
                  backgroundColor: colors.primary[600],
                },
                "& .MuiDataGrid-footerContainer": {
                  backgroundColor: colors.primary[600],
                  color: colors.grey[100],
                },
                "& .MuiButton-contained": {
                  color: colors.grey[100],
                },
              }}
            />
          </Box>
        )}
      </Container>
    </>
  );
};

export default DashboardPOC;
