import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  Paper
} from "@mui/material";
import { userLogin } from "../../actions/User";
import { useDispatch } from "react-redux";
import bg from  "./bg.jpeg"
import logo from "../Images/Head_ekkiz.png"


const Login = () => {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("admin");
  const dispatch = useDispatch();

  const loginHandler = (e) => {
    e.preventDefault();
    dispatch(userLogin(phone, password, userType));
  };

  return (
    <Box
     sx={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow:"hidden"
      }}
    >
      <Container maxWidth="xs">
        <Paper
          elevation={6}
          sx={{
            padding: "30px",
            borderRadius: "10px",
            backgroundColor: "rgba(0, 0, 0, 0)",
            backdropFilter: "blur(0px)",
            mb:10,
          }}
        >
          <Box
            component="form"
            onSubmit={loginHandler}
            sx={{
              mt: 1,
              mb: 5,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h4" component="h1" gutterBottom>
              <img src={logo} alt="LOGO" style={{borderRadius:"1rem", boxShadow: "5px 5px 20px 6px white, -5px -5px 20px 6px white" }} height="70px" width="220px"/>
            </Typography>
            <TextField
            autoComplete="off"
              fullWidth
              margin="normal"
              label="Phone"
              variant="outlined"
              type="text"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Password"
              variant="outlined"
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>User Type</InputLabel>
              <Select
                value={userType}
                onChange={(e) => setUserType(e.target.value)}
                label="User Type"
              >
                <MenuItem value="admin">--Select--</MenuItem>
                <MenuItem value="poc">COMPANY</MenuItem>
                <MenuItem value="expert">EXPERT</MenuItem>
              </Select>
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default Login;
