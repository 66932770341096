import {createSlice} from "@reduxjs/toolkit"

const initialState={
    isLoading: false,
    error: null,
    tasks: [],
    TillPaymentTasks: [],
    PerPaymentTasks: [],
    candidates: [],
}


const pocSlice=createSlice({
    name:"poc",
    initialState,
    reducers:{
        requestStart: (state) => {
            state.isLoading = false;
            state.error = null;
          },
          requestFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
          },
          clearError: (state) => {
              state.error = null;
            },
        addCandidateSuccess:(state,action)=>{
            state.isLoading = false;
      state.candidates.push(action.payload);
        },
        getAllCandidateSuccess: (state, action) => {
            state.isLoading = false;
            state.candidates=action.payload;
          },
        addTaskSuccess:(state,action)=>{
            state.isLoading = false;
      state.tasks.push(action.payload);
        },
        addSubPocSuccess:(state,action)=>{
            const updatedTasks = state.tasks.map((task) =>
        task._id === action.payload._id ? action.payload : task
      );
      state.isLoading = false;
      state.tasks = updatedTasks;
        },
        getAllTaskSuccess:(state,action)=>{
            state.isLoading=false
            state.tasks=action.payload
        },
        getPerPaymentTasksSuccess:(state,action)=>{
            state.isLoading=false
            state.PerPaymentTasks=action.payload
          },
          getTillPaymentTasksSuccess:(state,action)=>{
            state.isLoading=false
            state.TillPaymentTasks=action.payload
        },
        clearPoc: (state) => {
      state.isLoading= false;
      state.error= null;
      state.tasks= null;
      state.TillPaymentTasks= null;
      state.PerPaymentTasks= null
      state.candidates= null
    },
    } 
})

export const {requestStart,requestFailure,addCandidateSuccess,addTaskSuccess,addSubPocSuccess,getAllCandidateSuccess,getAllTaskSuccess,getPerPaymentTasksSuccess,getTillPaymentTasksSuccess,clearError,clearPoc}=pocSlice.actions

export default pocSlice.reducer