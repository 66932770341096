import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAllTasks, updateTaskTime } from '../../actions/Admin'; 
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from '@mui/material';
import toast from 'react-hot-toast';
import moment from 'moment';

const UpdateTime = ({ taskId, currentISTTime, onClose }) => {
  const dispatch = useDispatch();
  const [istTime, setIstTime] = useState('');

  useEffect(() => {
    if (currentISTTime) {
      const formattedDate = moment(currentISTTime, 'DD/MM/YYYY hh:mm A').format('YYYY-MM-DDTHH:mm');
      setIstTime(formattedDate);
    }
  }, [currentISTTime]);

  const handleUpdateISTTime = () => {
    if (taskId && istTime) {
      const formData = {
        taskId,
        ISTTIME: istTime
      };
      dispatch(updateTaskTime(formData))
        .then(() => {
          toast('IST Time updated successfully'); 
          dispatch(fetchAllTasks());
          onClose();
        })
        .catch(() => {
          toast('Failed to update IST Time'); 
        });
    } else {
      toast('Please enter IST Time'); 
    }
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Update IST Time <img src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/255px-Flag_of_India.svg.png" width="25px" style={{ top: "5px" }} alt="IND FLAG" /></DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="ist-time"
          label="IST Time"
          type="datetime-local"
          fullWidth
          value={istTime}
          onChange={(e) => setIstTime(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleUpdateISTTime} color="primary">
          Update IST Time
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateTime;
