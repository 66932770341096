import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, Select, InputLabel, MenuItem, FormControl, useTheme } from '@mui/material';
import { addCandidate, getAllPoc } from '../../actions/Admin';
import BasicTable from './CandidateTable';
import { tokens } from '../../theme';

function AddCandidate() {
    const [open, setOpen] = useState(false);
    const [formType, setFormType] = useState('');
    const [formData, setFormData] = useState({
        pocId: '',
        name: '',
        candidateType: '',
        email: '',
        phone: '',
        technology: '',
        cv: null,
    });
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { poc } = useSelector((state) => state.admin);
    const candidateTypes = [{ label: "PER CALL", value: "perCall" }, { label: "TILL PLACEMENT", value: "tillPlacement" }];
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllPoc());
    }, [dispatch]);

    const handleClose = () => {
        setOpen(false);
        setFormType('');
        setFormData({
            pocId: '',
            candidateType: "",
            name: '',
            email: '',
            phone: '',
            technology: '',
            cv: null,
        });
    }; 

    const technologyOptions = [
        "Business Analyst",
        "Financial Analyst",
        "Project Manager",
        "Scrum Master",
        "Data Analyst",
        "Data Engineer",
        "Data Scientists",
        "Java Developer",
        "Java Full Stack Developer",
        "Software Engineer",
        "Python Developer",
        "Front-End Developer",
        "Backend Developer",
        "Cyber Security Analyst",
        "Network Engineer",
        "ASP .NET Developer",
        "Others"
    ];

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: files && files.length > 0 ? files[0] : value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = new FormData();
        Object.entries(formData).forEach(([key, value]) => {
            data.append(key, value);
        });
        dispatch(addCandidate(data));
        handleClose();
    };

    const openDialog = () => {
        setOpen(true);
        setFormType('poc');
    };

    return (
        <>
            <Button style={{ marginLeft: "20rem", marginTop: "20px" }} variant="outlined" sx={{ color: colors.grey[100], borderColor: colors.grey[100] }} onClick={openDialog}>
                Add Candidate
            </Button>
            <Dialog mt={10} open={open && formType === 'poc'} onClose={handleClose} aria-labelledby="poc-form-dialog-title">
                <form onSubmit={handleSubmit}>
                    <DialogTitle style={{ textAlign: 'center' }} id="poc-form-dialog-title">Add Candidate</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ textAlign: 'center' }}>
                            Please fill in the details for the Candidate.
                        </DialogContentText>
                        <br />
                        <FormControl fullWidth>
                            <InputLabel
                                id="poc-id-label"
                                sx={{
                                    color: colors.greenAccent[500],
                                    '&.Mui-focused': {
                                        color: colors.greenAccent[500],
                                    },
                                }}
                            >
                                Select POC
                            </InputLabel>
                            <Select
                                label="Select POC"
                                labelId="poc-id-label"
                                id="poc-id"
                                required
                                name="pocId"
                                value={formData.pocId}
                                onChange={handleChange}
                                sx={{
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.greenAccent[500],
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.greenAccent[500],
                                    },
                                }}
                            >
                                {poc.poc && poc.poc.map((p) => (
                                    <MenuItem key={p._id} value={p._id}>{p.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <br />
                        <br />
                        <FormControl fullWidth>
                            <InputLabel
                                id="candidateType-label"
                                sx={{
                                    color: colors.greenAccent[500],
                                    '&.Mui-focused': {
                                        color: colors.greenAccent[500],
                                    },
                                }}
                            >
                                Candidate Type
                            </InputLabel>
                            <Select
                                label="Candidate Type"
                                labelId="candidateType-label"
                                id="candidateType"
                                required
                                name="candidateType"
                                value={formData.candidateType}
                                onChange={handleChange}
                                sx={{
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.greenAccent[500],
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.greenAccent[500],
                                    },
                                }}
                            >
                                {candidateTypes.map((ctype, i) => (
                                    <MenuItem key={i} value={ctype.value}>{ctype.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            margin="dense"
                            id="candidate-name"
                            name="name"
                            label="Candidate Name"
                            type="text"
                            required
                            fullWidth
                            value={formData.name}
                            onChange={handleChange}
                            sx={{
                                '& .MuiInputLabel-root': {
                                    color: colors.greenAccent[500],
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: colors.greenAccent[500],
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.greenAccent[500],
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.greenAccent[500],
                                    },
                                    '& input::placeholder': {
                                        color: colors.greenAccent[500],
                                    },
                                },
                            }}
                        />
                        <TextField
                            margin="dense"
                            id="candidate-email"
                            name="email"
                            label="Email"
                            type="email"
                            required
                            fullWidth
                            value={formData.email}
                            onChange={handleChange}
                            sx={{
                                '& .MuiInputLabel-root': {
                                    color: colors.greenAccent[500],
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: colors.greenAccent[500],
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.greenAccent[500],
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.greenAccent[500],
                                    },
                                    '& input::placeholder': {
                                        color: colors.greenAccent[500],
                                    },
                                },
                            }}
                        />
                        <TextField
                            margin="dense"
                            id="candidate-mobile"
                            name="phone"
                            label="Mobile"
                            type="text"
                            required
                            fullWidth
                            value={formData.phone}
                            onChange={handleChange}
                            sx={{
                                '& .MuiInputLabel-root': {
                                    color: colors.greenAccent[500],
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: colors.greenAccent[500],
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.greenAccent[500],
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.greenAccent[500],
                                    },
                                    '& input::placeholder': {
                                        color: colors.greenAccent[500],
                                    },
                                },
                            }}
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel
                                id="technology-label"
                                sx={{
                                    color: colors.greenAccent[500],
                                    '&.Mui-focused': {
                                        color: colors.greenAccent[500],
                                    },
                                }} 
                            >
                                Technology
                            </InputLabel>
                            <Select
                                labelId="technology-label"
                                id="technology"
                                required
                                name="technology"
                                value={formData.technology}
                                onChange={handleChange}
                                sx={{
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.greenAccent[500],
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.greenAccent[500],
                                    },
                                }}
                            >
                                {technologyOptions.map((tech, index) => (
                                    <MenuItem key={index} value={tech}>{tech}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <InputLabel
                            id="cv-label"
                            sx={{
                                color: colors.greenAccent[500],
                                '&.Mui-focused': {
                                    color: colors.greenAccent[500],
                                },
                            }}
                        >
                            <strong>Upload CV</strong>
                        </InputLabel>
                        <TextField
                            margin="dense"
                            id="cv"
                            name="cv"
                            type="file"
                            fullWidth
                            onChange={handleChange}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.greenAccent[500],
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.greenAccent[500],
                                    },
                                },
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" sx={{ color: "red", borderColor: "red" }}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="outlined" sx={{ color: colors.greenAccent[500], borderColor: colors.greenAccent[500] }}>
                            Add
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            <h1 style={{ textAlign: "center" }}>All Candidates</h1>
            <BasicTable />
        </>
    );
}

export default AddCandidate;
