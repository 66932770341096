// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { getAllCandidate } from '../../actions/POC';
// import { FaDownload } from 'react-icons/fa';
// import axios from 'axios';
// import {
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Button,
//   Box,
//   useTheme
// } from '@mui/material';
// import { DataGrid, GridToolbar } from '@mui/x-data-grid';
// import { tokens } from '../../theme';
// import Sidebar from '../layout/CsideBar';
// import { Link } from 'react-router-dom';

// const BasicTable = () => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const [cvUrl, setCvUrl] = useState(null);
//   const [open, setOpen] = useState(false);
//   const { candidates } = useSelector((state) => state.poc);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(getAllCandidate());
//   }, [dispatch]);

//   const getCv = async (candidateId) => {
//     const token = localStorage.getItem('admin');
//     let config = {
//       method: 'get',
//       url: `/api/v1/poc/candidate/${candidateId}/cv`,
//       headers: {
//         Authorization: token,
//       },
//       responseType: 'blob',
//     };
    
//     const response = await axios.request(config);
//     const file = new Blob([response.data], { type: response.data.type });
//     const fileURL = URL.createObjectURL(file);
//     setCvUrl(fileURL);
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setCvUrl(null);
//     setOpen(false);
//   };

  

//   const columns = [
//     { field: 'name', headerName: 'CANDIDATE NAME', flex: 1, renderCell: (params) => (
//         <Link style={{ textDecoration: "none", color: colors.grey[100] }} to={`/poc/make/payment?mobile=${params.row.phone}`}>
//           {params.value.toUpperCase()}
//         </Link>
//       ),
//     },
//     { field: 'candidateType', headerName: 'CANDIDATE TYPE', flex: 1 },
//     { field: 'email', headerName: 'EMAIL', flex: 1 },
//     { field: 'phone', headerName: 'PHONE', flex: 1 },
//     { field: 'technology', headerName: 'TECHNOLOGY', flex: 1 },
//     {
//       field: 'viewTasks',
//       headerName: 'VIEW TASKS',
//       flex: 1,
//       renderCell: (params) => (
//         <Button variant="outlined" color="primary">
//           View Tasks
//         </Button>
//       ),
//     },
//     {
//       field: 'cv',
//       headerName: 'CV',
//       flex: 1,
//       renderCell: (params) => (
//         <Button variant="outlined"   disabled={!params.row.cv} color="secondary" onClick={() => getCv(params.row._id)}>
//           <FaDownload />
//         </Button>
//       ),
//     },
//   ];

//   return (
//     <> 
//       <Sidebar />
//       <Box m="20px">
//         <Box
//           m="40px 0 0 0"
//           sx={{

//             position:"relative",
//             maxWidth:"99%",
//             display:"Block",
//             "& .MuiDataGrid-root": {
//               border: "none",
//             },
//             "& .MuiDataGrid-cell": {
//               borderBottom: "none",
//               color: colors.grey[100],
//             },
//             "& .name-column--cell": {
//               color: colors.greenAccent[300],
//             },
//             "& .MuiDataGrid-columnHeaders": {
//               backgroundColor: colors.blueAccent[700],
//               borderBottom: "none",
//               color: colors.grey[100],
//             },
//             "& .MuiDataGrid-virtualScroller": {
//               backgroundColor: colors.primary[400],
//             },
//             "& .MuiDataGrid-footerContainer": {
//               borderTop: "none",
//               backgroundColor: colors.blueAccent[700],
//             },
//             "& .MuiCheckbox-root": {
//               color: `${colors.greenAccent[200]} !important`,
//             },
//             "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
//               color: `${colors.grey[100]} !important`,
//             },
//             [theme.breakpoints.down('md')]: {
//               "& .MuiDataGrid-columnHeaders": {
//                 fontSize: '0.8rem',
//               },
             
//             },
//           }}
//         >
//           <DataGrid
//             rows={candidates||[]}
//             columns={columns}
//             pageSize={10}
//             getRowId={(row) => row._id}
//             rowsPerPageOptions={[10]}
//             disableSelectionOnClick
//             components={{ Toolbar: GridToolbar }}
//             autoHeight
//           />
//         </Box>

//         <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
//           <DialogTitle>View Candidate CV</DialogTitle>
//           <DialogContent>
//             {cvUrl && (
//               <iframe src={cvUrl} title="Candidate CV" style={{ width: '100%', height: '600px', border: 'none' }} />
//             )}
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleClose} color="primary">
//               Close
//             </Button>
//           </DialogActions>
//         </Dialog>
//       </Box>
//     </>
//   );
// };

// export default BasicTable;


import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCandidate, updateCandidate } from "../../actions/POC";
import { FaRegEye, FaEdit } from "react-icons/fa";
import axios from "axios";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  useTheme,
  Box,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import EditCandidate from "./EditCandidate";


export default function BasicTable() {
  const [cvUrl, setCvUrl] = useState(null);
  const [openCV, setOpenCV] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editCandidate, setEditCandidate] = useState({});


  const { candidates } = useSelector((state) => state.poc);
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    dispatch(getAllCandidate());
  }, [dispatch]);

  const getCv = async (candidateId) => {
    const token = localStorage.getItem("admin");
    let config = {
      method: "get",
      url: `/api/v1/poc/candidate/${candidateId}/cv`,
      headers: {
        Authorization: token,
      },
      responseType: "blob",
    };
    try {
      const response = await axios(config);
      const file = new Blob([response.data], { type: response.data.type });
      const fileURL = URL.createObjectURL(file);
      setCvUrl(fileURL);
      setOpenCV(true);
    } catch (error) {
      console.error("Error fetching the CV:", error);
    }
  };

  const handleEditClick = (candidate) => {
    setEditCandidate(candidate);
    setOpenEdit(true);
  };

  const handleEditChange = (e) => {
    const { name, value, files } = e.target;
    setEditCandidate({
      ...editCandidate,
      [name]: name === "cv" ? files[0] : value,
    });
  };

  const handleEditSubmit = async () => {
    const formData = new FormData();
    for (const key in editCandidate) {
      formData.append(key, editCandidate[key]);
    }
    dispatch(updateCandidate(editCandidate._id, formData)).then((result) => {
      if (result.success) {
        dispatch(getAllCandidate());
        setOpenEdit(false);
      }
    });
  };

  const handleCloseCV = () => {
    setCvUrl(null);
    setOpenCV(false);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };





  const columns = [
    { field: "name", headerName: "CANDIDATE NAME", flex: 1 },
    { field: "candidateType", headerName: "CANDIDATE TYPE", flex: 1 },
    { field: "email", headerName: "EMAIL", flex: 1 },
    { field: "phone", headerName: "PHONE", flex: 1 },
    { field: "role", headerName: "ROLE", flex: 1 },
    { field: "technology", headerName: "TECHNOLOGY", flex: 1 },
    {
      field: "cv",
      headerName: "CV",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="outlined"
          sx={{ color: colors.grey[100], borderColor: colors.grey[100] }}
          disabled={!params.row.cv}
          onClick={() => getCv(params.row._id)}
        >
          <FaRegEye />
        </Button>
      ),
    },
    {
      field: "subPoc",
      headerName: "TAG",
      flex: 1,
      renderCell: (params) => params.row.subPoc || "-",
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      flex: 1,
      renderCell: (params) => (
        <>
          <Button
            variant="outlined"
            sx={{ color: colors.grey[100], borderColor: colors.grey[100] }}
            onClick={() => handleEditClick(params.row)}
          >
            <FaEdit />
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        <Box sx={{ height: "70vh", width: "90%" }}>
          <Typography variant="h6" component="div" sx={{ textAlign: "center", padding: "10px" }}>
            Candidate Details
          </Typography>
          <DataGrid
            rows={candidates || []}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            getRowId={(row) => row._id}
            disableColumnMenu
            autoHeight
            disableExtendRowFullWidth
            sx={{
              ".MuiDataGrid-root": {
                bgcolor: colors.primary[600],
              },
              ".MuiDataGrid-cell": {
                color: colors.grey[100],
              },
              ".MuiDataGrid-columnHeader": {
                bgcolor: colors.primary[700],
                color: colors.greenAccent[500],
              },
              ".MuiCheckbox-root": {
                color: colors.grey[100],
              },
              "& .Mui-disabled": {
                pointerEvents: "none",
                opacity: 0.6,
              },
            }}
          />
        </Box>

        <Dialog open={openCV} onClose={handleCloseCV} fullWidth maxWidth="lg">
          <DialogTitle sx={{ backgroundColor: colors.primary[600], color: colors.grey[100] }}>
            View Candidate CV
          </DialogTitle>
          <DialogContent sx={{ backgroundColor: colors.primary[700], color: colors.grey[100] }}>
            {cvUrl && (
              <iframe src={cvUrl} title="Candidate CV" style={{ width: "100%", height: "600px", border: "none" }} />
            )}
          </DialogContent>
          <DialogActions sx={{ backgroundColor: colors.primary[700], color: colors.grey[100] }}>
            <Button onClick={handleCloseCV} sx={{ color: colors.grey[100] }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <EditCandidate
          open={openEdit}
          handleClose={handleCloseEdit}
          candidate={editCandidate}
          handleEditChange={handleEditChange}
          handleEditSubmit={handleEditSubmit}
        />
      </Box>
    </>
  );
}
