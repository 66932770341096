import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Box,
  useTheme,
} from '@mui/material';
import { tokens } from '../../theme';


const EditPoc = ({ open, handleClose, pocs, handleEditChange, handleEditSubmit }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
 
  let sx={
                                '& .MuiInputLabel-root': {
                                    color: colors.greenAccent[500],
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: colors.greenAccent[500],
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.greenAccent[500],
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.greenAccent[500],
                                    },
                                    '& input::placeholder': {
                                        color: colors.greenAccent[500],
                                    },
                                },
                            }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
       <DialogTitle  sx={{ backgroundColor: colors.primary[600], color: colors.grey[100]}}>
        Edit Expert Details
      </DialogTitle> 
      
      <DialogContent sx={{ backgroundColor: colors.primary[700], color: colors.grey[100], }}>
        <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 3,padding:2 }}>
          <TextField
            label="Name"
            name="name"
            value={pocs.name || ''}
            onChange={handleEditChange}
            fullWidth
            variant="outlined"
            sx={sx}
          /> 
          <TextField
            label="Email"
            name="email"
            value={pocs.email || ''}
            onChange={handleEditChange}
            fullWidth
            variant="outlined"
            sx={sx}
          />
          <TextField
            label="Phone"
            name="phone"
            value={pocs.phone || ''}
            onChange={handleEditChange}
            fullWidth
            variant="outlined"
            sx={sx}
          />
          <TextField
            label="TILL PLACEMENT INTERVIEW"
            name="tillPlacementInterview"
            value={pocs.tillPlacementInterview || ''}
            onChange={handleEditChange}
            fullWidth
            variant="outlined"
            sx={sx}
          />
          <TextField
            label="INTERVIEW"
            name="interview"
            value={pocs.interview || ''}
            onChange={handleEditChange}
            fullWidth
            variant="outlined"
            sx={sx}
          />
          <TextField
            label="JD SESSION"
            name="jdSession"
            value={pocs.jdSession || ''}
            onChange={handleEditChange}
            fullWidth
            variant="outlined"
            sx={sx}
          />
          <TextField
            label="ASSESSMENT"
            name="assessment"
            value={pocs.assessment || ''}
            onChange={handleEditChange}
            fullWidth
            variant="outlined"
            sx={sx}
          />
          <TextField
            label="RU SESSION"
            name="ruSession"
            value={pocs.ruSession || ''}
            onChange={handleEditChange}
            fullWidth
            variant="outlined"
            sx={sx}
          />
          <TextField
            label="TRAINING"
            name="training"
            value={pocs.training || ''}
            onChange={handleEditChange}
            fullWidth
            variant="outlined"
            sx={sx}
          />
          <TextField
            label="RESUME BUILDING"
            name="resumeBuilding"
            value={pocs.resumeBuilding || 0}
            onChange={handleEditChange}
            fullWidth
            variant="outlined"
            sx={sx}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: colors.primary[700], color: colors.grey[100] }}>
        <Button onClick={handleClose} sx={{ color:"red", borderColor: "red" }}>
          Cancel
        </Button>
        <Button type='submit' onClick={handleEditSubmit} variant="outlined" sx={{ color:colors.greenAccent[500], borderColor:colors.greenAccent[500]  }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPoc;
