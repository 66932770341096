import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAllTasks, updateTaskPrice } from '../../actions/Admin';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  IconButton,
  FormControl,
} from '@mui/material';
import toast from 'react-hot-toast';

const UpdatePrice = ({ open, onClose, task }) => {
  console.log(task);
  const dispatch = useDispatch();
  const [expertPrice, setExpertPrice] = useState(task.expertPrice);
  const [pocPrice, setPocPrice] = useState(task.pocPrice);

  const handleSave = () => {
    dispatch(updateTaskPrice({ taskId: task.id, expertPrice, pocPrice }))
      .then(() => {
        toast.success('Price updated successfully');
        dispatch(fetchAllTasks());
        onClose();
      })
      .catch(() => {
        toast.error('Failed to update price');
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Update Prices
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Expert Price"
            type="number"
            value={expertPrice}
            onChange={(e) => setExpertPrice(e.target.value)}
            fullWidth
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="POC Price"
            type="number"
            value={pocPrice}
            onChange={(e) => setPocPrice(e.target.value)}
            fullWidth
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdatePrice;
