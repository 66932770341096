import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { clearError } from '../../reducers/Admin';
import toast from 'react-hot-toast';
import { fetchAllCompanyTasks, getAllExpert, assignToExpert } from '../../actions/Admin';
import Loading from '../Loading/Loading';
import moment from 'moment';
import {
  Container,
  Typography,
  Box,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputLabel,
  Fab,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import { tokens } from '../../theme';

const CompanyTasks = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const { tasks, isLoading, error, experts } = useSelector((state) => state.admin);
  const [open, setOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [link, setLink] = useState('');
  const [istTime, setIstTime] = useState('');
  const [filter, setFilter] = useState('remainToAssign');
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchAllCompanyTasks());
    dispatch(getAllExpert());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
  }, [error, dispatch]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const status = query.get('status');
    if (status) {
      setFilter(status);
    }
  }, [location.search]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAssignExpert = () => {
    if (selectedTask && link && istTime) {
      const formData = {
        taskId: selectedTask.taskId,
        expertId: selectedTask.expertId,
        ISTTIME: istTime,
        link: link,
      };
      dispatch(assignToExpert(formData));
      setOpen(false);
    } else {
      toast.error('Please fill in all fields');
    }
  };

  const handleAssignButtonClick = (task) => {
    setSelectedTask({
      taskId: task._id,
      expertId: '',
      ISTTIME: task.ISTTIME || '',
    });
    setIstTime(task.ISTTIME || '');
    setLink('');
    setOpen(true);
  };

  const handleExpertChange = (event) => {
    setSelectedTask({
      ...selectedTask,
      expertId: event.target.value,
    });
  };

  const filteredTasks = tasks.filter((task) => {
    if (filter === 'pending') {
      return task.status === 'Pending' && task.expertId;
    } else if (filter === 'remainToAssign') {
      return !task.expertId;
    }
    return true;
  });

  const columns = [
    { field: 'ESTTIME', headerName: 'EST TIME', flex: 1.2, renderCell: (params) => moment(params.row.ESTTIME).format("DD/MM/YYYY hh:mm A") || ''  },
    { field: 'companyName', headerName: 'COMPANY', flex: 1 },
    { field: 'candidateName', headerName: 'CANDIDATE', flex: 1 },
    { field: 'taskType', headerName: 'TASK TYPE', flex: 1 },
    { field: 'pocId', headerName: 'POC NAME', flex: 1, renderCell: (params) => params.row.pocId?.name || '' },
    { field: 'pocPrice', headerName: 'POC PRICE (₹)', flex: 1 },
    { field: 'ISTTIME', headerName: 'IST TIME', flex: 1.2 },
    {
      field: 'status',
      headerName: 'STATUS',
      flex: 1,
      renderCell: (params) => (
        <Fab variant="extended" style={{ color: 'black', backgroundColor: getStatusColor(params.value), height: '1.5rem', width: '6.5rem' }}>
          <strong>{params.value}</strong>
        </Fab>
      ),
    },
    {
      field: 'actions',
      headerName: 'ACTION',
      flex: 1.5,
      renderCell: (params) => (
        <Button onClick={() => handleAssignButtonClick(params.row)} sx={{ color: colors.grey[100] }}>
          {params.row.expertId ? 'Reassign Expert' : 'Assign Expert'}
        </Button>
      ),
    },
  ];

  const getStatusColor = (status) => {
    switch (status) {
      case 'Pending':
        return '#FFD700';
      case 'Assigned':
        return '#1E90FF';
      case 'Reschedule':
        return '#FFA500';
      case 'Complete':
        return '#008000';
      case 'Cancelled':
        return '#FF0000';
      default:
        return '#000000';
    }
  };
  return (
    <>
      <Dialog
        style={{ height: '60%' }}
        open={open}
        onClose={handleClose}
        aria-labelledby="assignExpert-form-dialog-title"
      >
        <DialogTitle id="assignExpert-form-dialog-title" sx={{ backgroundColor: colors.primary[500], color: colors.grey[100] }}>
          Assign Expert
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: colors.primary[600], color: colors.grey[100] }}>
          <InputLabel>
            <strong>Select Expert</strong>
          </InputLabel>
          <Select
            labelId="assignExpert-id-label"
            id="assignExpert-id"
            value={selectedTask ? selectedTask.expertId : ''}
            onChange={handleExpertChange}
            fullWidth
            sx={{ color: colors.grey[100] }}
          >
            {experts.experts &&
              experts.experts.map((exp) => (
                <MenuItem key={exp._id} value={exp._id}>
                  {exp.name}
                </MenuItem>
              ))}
          </Select>
          <TextField
            autoFocus
            margin="dense"
            id="taskid"
            name="taskId"
            label="Task Id"
            placeholder="Enter Task Id"
            type="text"
            fullWidth
            value={selectedTask ? selectedTask.taskId : ''}
            disabled
            sx={{ color: colors.grey[100] }}
          />
          <br />
          <br />
          <InputLabel>
            <strong>IST Time & Date</strong>
          </InputLabel>
          <TextField
            autoFocus
            margin="dense"
            id="isttime"
            name="ISTTIME"
            type="datetime-local"
            fullWidth
            value={istTime}
            onChange={(e) => setIstTime(e.target.value)}
            sx={{ color: colors.grey[100] }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="task-link"
            name="link"
            label="Task Link"
            placeholder="Enter Task Link"
            type="text"
            fullWidth
            value={link}
            onChange={(e) => setLink(e.target.value)}
            sx={{ color: colors.grey[100] }}
          />
        </DialogContent>
        <DialogActions sx={{ backgroundColor: colors.primary[600], color: colors.grey[100] }}>
          <Button onClick={handleClose} sx={{ color: colors.grey[100] }}>
            Cancel
          </Button>
          <Button onClick={handleAssignExpert} sx={{ color: colors.grey[100] }}>
            Assign
          </Button>
        </DialogActions>
      </Dialog>

      <Container sx={{ minWidth: '95%', padding: '20px', backgroundColor: colors.primary[500]}}>
        {isLoading ? (
          <Loading />
        ) : (
          <Box mt={5}>
            <Typography mb={10} variant="h4" align="center" gutterBottom sx={{ color: colors.grey[100] }}>
              Company Tasks
            </Typography>
            <Box sx={{ height: '70vh', width:'90%',marginLeft:"11rem"}}>
              <DataGrid
                rows={filteredTasks || []}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                getRowId={(row) => row._id}
                disableColumnMenu
                autoHeight
                disableExtendRowFullWidth
                sx={{
            '.MuiDataGrid-root': {
              bgcolor: colors.primary[600],
            },
            '.MuiDataGrid-cell': {
              color: colors.grey[100],
            },
            '.MuiDataGrid-columnHeader': {
              bgcolor: colors.primary[700],
              color: colors.greenAccent[500],
            },
            '.MuiCheckbox-root': {
              color: colors.grey[100],
            },
            '& .Mui-disabled': {
              pointerEvents: 'none',
              opacity: 0.6,
            },
          }}
              />
            </Box>
          </Box>
        )}
      </Container>
    </>
  );
};

export default CompanyTasks;
