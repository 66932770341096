import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearError } from '../../reducers/Expert';
import toast from 'react-hot-toast';
import { getAllTask, todayTask } from '../../actions/Expert';
import { tokens } from '../../theme';
import moment from "moment"
import Loading from '../Loading/Loading';
import {
  Container,
  Box,
  Tabs,
  Tab,
  Paper,
  useTheme,
  Button
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const ExpertTasks = () => {
  const dispatch = useDispatch();
  const { tasks, todaytasks, isLoading, error } = useSelector((state) => state.expert);
  const [filter, setFilter] = useState('today');
  const [slide, setSlide] = useState(false);
  const theme=useTheme();
  const colors=tokens(theme.palette.mode);

  useEffect(() => {
    if (filter === 'today') {
      dispatch(todayTask());
    } else {
      dispatch(getAllTask());
    }
  }, [dispatch, filter]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
  }, [error, dispatch]);

  const handleFilterChange = (event, newValue) => {
    setFilter(newValue);
    setSlide(true);
    setTimeout(() => {
      setSlide(false);
    }, 300);
  };

  const columns = [

    {
      field: 'ESTTIME',
      flex: 1,
      headerName: (
        <>
          EST TIME &nbsp; <img src="https://cdn.britannica.com/33/4833-004-828A9A84/Flag-United-States-of-America.jpg" width="25px" style={{ top: "5px" }} alt="US FLAG" />
        </>
      ),
      width: 150
    },
    { field: 'candidateName', headerName: 'Candidate Name', flex: 1 },
    { field: 'candidateType', headerName: 'Candidate TYPE', flex: 1 },
    { field: 'taskType', headerName: 'Task Type', flex: 1 },
    {
      field: 'ISTTIME',
      headerName: (
        <>
          IST TIME &nbsp; <img src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/255px-Flag_of_India.svg.png" width="25px" style={{ top: "5px" }} alt="BHARAT FLAG" />
        </>
      ),
      width: 150
    },
    { field: 'expertPrice', headerName: 'Price(₹)',  flex: 0.3, type: 'number', align: 'left' },
    { field: 'expertPaymentStatus', headerName: 'Payment Status(₹)',  flex: 0.6, type: 'number' },
    { field: 'status', headerName: 'Status', flex: 1 },
    { field: 'link', headerName: 'LINK', flex: 1, renderCell: (params) => (
      <Button  disabled={params.row.status !== 'Assigned'}  variant="contained" color="secondary">
        <a href={params.row.link} style={{ color: 'white', textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
          JOIN
        </a>
      </Button>
    ) },
  ];

  const rows = (filter === 'today' ? todaytasks : tasks).map((task, index) => ({
    id: task._id,
    ISTTIME: moment(task.ISTTIME).format('D/M/YYYY hh:mm A'), 
    ESTTIME: moment(task.ESTTIME).format('D/M/YYYY hh:mm A'), 
    candidateName: task.candidateName,
    candidateType: task.candidateType,
    taskType: task.taskType,
    expertPrice: task.expertPrice,
    expertPaymentStatus: task.ExpertPaymentStatus,
    status: task.status,
    link: task.link
  }));

  return (
    <Container style={{ maxWidth: '98%' }}>
      {isLoading ? (
        <Loading />
      ) : (
        <Box mt={3} >
          <Box mb={2} textAlign="center">
            <Tabs
              value={filter}
              onChange={handleFilterChange}
              indicatorColor="primary"
               textColor="secondary"
              color="warning"
              centered
            >
              <Tab  value="today" color={colors.grey[500]} label="Today Task" />
              <Tab  textColor="secondary" value="all"  color={colors.grey[500]} label="All Tasks" />
            </Tabs>
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}  component={Paper} className={slide ? 'slide' : ''} style={{ height: 600, width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              sx={{
            '.MuiDataGrid-root': {
              bgcolor: colors.primary[600],
            },
            '.MuiDataGrid-cell': {
              color: colors.grey[100],
            },
            '.MuiDataGrid-columnHeader': {
              bgcolor: colors.primary[700],
              color: colors.greenAccent[500],
            },
            '.MuiCheckbox-root': {
              color: colors.grey[100],
            },
            '& .Mui-disabled': {
              pointerEvents: 'none',
              opacity: 0.6,
            }
          }}
            />
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default ExpertTasks;
