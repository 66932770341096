// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import moment from 'moment';
// import { fetchAllTasks, updateTaskStatus } from '../../actions/Admin';
// import { clearError } from '../../reducers/Admin';
// import toast from 'react-hot-toast';
// import Loader from '../Loading/Loading';
// import AddTask from './AddTask';
// import UpdateTime from './UpdateTime';
// import UpdateDurationDialog from './UpdateDuration';
// import GiveFeedback from './GiveFeedBack';
// import axios from 'axios';
// import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
// import {
//   Container,
//   Button,
//   Typography,
//   FormControl,
//   Select,
//   MenuItem,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Grid,
//   Box,
//   useTheme,
// } from '@mui/material';
// import { DataGrid, GridToolbarExport } from '@mui/x-data-grid';
// import { tokens } from '../../theme';
// import { FaRegEye, FaRegEdit } from 'react-icons/fa';

// const ManageTasks = () => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const dispatch = useDispatch();
//   const { tasks, isLoading, error } = useSelector((state) => state.admin);

//   const [addTaskOpen, setAddTaskOpen] = useState(false);
//   const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
//   const [fbUrl, setFBUrl] = useState(null);
//   const [feedback, setFeedback] = useState(null);
//   const [updateTimeOpen, setUpdateTimeOpen] = useState(false);
//   const [updateDurationOpen, setUpdateDurationOpen] = useState(false);
//   const [selectedTask, setSelectedTask] = useState(null);
//   const [giveFeedbackOpen, setGiveFeedbackOpen] = useState(false);
//   const [sortModel, setSortModel] = useState([
//     {
//       field: 'estTime',
//       sort: 'desc',
//     },
//   ]);

//   const [popupOpen, setPopupOpen] = useState(false);
//   const [candidateDetails, setCandidateDetails] = useState({ email: '', phone: '' });
//   const [loadingCandidateDetails, setLoadingCandidateDetails] = useState(false);

//   useEffect(() => {
//     dispatch(fetchAllTasks());
//   }, [dispatch]);

//   useEffect(() => {
//     if (error) {
//       toast.error(error);
//       dispatch(clearError());
//     }
//   }, [error, dispatch]);

//   const handleStatusChange = (taskId, event) => {
//     const newStatus = event.target.value;
//     dispatch(updateTaskStatus({ taskId, status: newStatus }))
//       .then(() => {
//         toast.success('Status updated successfully');
//         dispatch(fetchAllTasks());
//       })
//       .catch(() => {
//         toast.error('Failed to update status');
//       });
//   };

//   const handleOpenUpdateTime = (taskId, istTime) => {
//     setSelectedTask({ taskId, istTime });
//     setUpdateTimeOpen(true);
//   };

//   const handleCloseUpdateTime = () => {
//     setUpdateTimeOpen(false);
//   };

//   const handleOpenUpdateDuration = (task) => {
//     setSelectedTask(task);
//     setUpdateDurationOpen(true);
//   };

//   const handleCloseUpdateDuration = () => {
//     setUpdateDurationOpen(false);
//   };

//   const handleAddTaskClick = () => {
//     setAddTaskOpen(true);
//   };

//   const handleAddTaskClose = () => {
//     setAddTaskOpen(false);
//   };

//   const handleOpenGiveFeedback = (taskId) => {
//     setSelectedTask({ taskId });
//     setGiveFeedbackOpen(true);
//   };

//   const handleCloseGiveFeedback = () => {
//     setGiveFeedbackOpen(false);
//   };

//   const getFeedbackScreenshot = async (taskId) => {
//     try {
//       const token = localStorage.getItem('admin');
//       const config = {
//         method: 'get',
//         url: `/api/v1/admin/task/${taskId}/screenshot`,
//         headers: {
//           Authorization: token,
//         },
//       };
//       const response = await axios.request(config);
//       const base64Image = response.data.task.screenshot.data;
//       const fb = response.data.task.feedback;
//       setFeedback(fb);
//       const scURL = `data:${response.headers['content-type'].toLowerCase()};base64,${base64Image}`;
//       setFBUrl(scURL);
//       setFeedbackDialogOpen(true);
//     } catch (error) {
//       toast.error(error.response.data.message);
//     }
//   }; 

//   const handleCloseFeedbackDialog = () => {
//     setFBUrl(null);
//     setFeedbackDialogOpen(false);
//   };

//   const getRelativeDate = (date) => {
//     const now = moment().startOf('day');
//     const targetDate = moment(date).startOf('day');
//     const diff = targetDate.diff(now, 'days');
//     const time = moment(date).format('hh:mm A');

//   //   if (diff === 0) {
//   //     return { text: `Today ${time}`, isRelative: true, sortValue: 0 };
//   //   } else if (diff === 1) {
//   //     return { text: `Tomorrow ${time}`, isRelative: true, sortValue: 1 };
//   //   } else if (diff === -1) {
//   //     return { text: `Yesterday ${time}`, isRelative: true, sortValue: -1 };   
//   //   } else {
//   //     return { text: moment(date).format('D/M/YYYY hh:mm A'), isRelative: false, sortValue: diff > 0 ? diff + 2 : diff };
//   //   }
//   // };



//   if (diff === 0) {
//       return { text: `Today ${time}`, isRelative: true };
//     } else if (diff === 1) {
//       return { text: `Tomorrow ${time}`, isRelative: true };
//     } else if (diff === -1) {
//       return { text: `Yesterday ${time}`, isRelative: true };
//     } else {
//       return { text: moment(date).format('D/M/YYYY hh:mm A'), isRelative: false };
//     }
//   };

//   const rows = tasks.map((task) => {
//     const istTimeRelative = getRelativeDate(task.ISTTIME);
//     const estTimeRelative = getRelativeDate(task.ESTTIME);
//     return { 
//       id: task._id,
//       candidateId: task.candidateId, 
//       candidateName: task.candidateId?task.candidateId.name:"",
//       candidateType: task.candidateType, 
//       taskType: task.taskType,
//       technology: task.candidateId?task.candidateId.technology:"",
//       expertName: task.expertId ? task.expertId.name : '',
//       expertPrice: task.expertPrice,
//       pocName: task.pocId ? task.pocId.name : '',
//       pocPrice: task.pocPrice,
//       taskDuration: `${task.hour}:${task.minute}`,
//       istTime: istTimeRelative.text,
//       estTime: estTimeRelative.text,
//       status: task.status,
//       screenshot: task.screenshot,
//     };
//   });

//   const handleCandidateClick = async (candidateId) => {
//     setLoadingCandidateDetails(true);
//     setPopupOpen(true);
//     try {
//       const response = await axios.get(`/api/v1/admin/candidate/${candidateId}`);
//       const { email, phone } = response.data;
//       setCandidateDetails({ email, phone });
//     } catch (error) {
//       toast.error('Failed to fetch candidate details');
//     } finally {
//       setLoadingCandidateDetails(false);
//     }
//   };

//   const columns = [
//     {
//       field: 'estTime',
//       headerName: (
//         <>
//           EST TIME &nbsp; <img src="https://cdn.britannica.com/33/4833-004-828A9A84/Flag-United-States-of-America.jpg" width="25px" style={{ top: "5px" }} alt="US FLAG" />
//         </>
//       ),
//       width: 150
//     },
//     { 
//       field: 'candidateName', 
//       headerName: 'CANDIDATE', 
//       flex: 2, 
//       renderCell: (params) => (
//         <Box onClick={() => handleCandidateClick(params.row.candidateId)}>
//           <Typography sx={{ color: colors.grey[100], cursor: 'pointer' }}>{params.row.candidateName}</Typography>
//           <Typography sx={{ color: colors.grey[100] }}>{params.row.candidateType}</Typography>
//         </Box>
//       ) 
//     },
//     { field: 'technology', headerName: 'TECHNOLOGY', flex: 1 },
//     { field: 'taskType', headerName: 'TASK TYPE', flex: 1 },
//     {
//       field: 'expertDetails',
//       headerName: 'EXPERT',
//       flex: 1,
//       renderCell: (params) => (
//         <Box>
//           <Typography sx={{ color: colors.grey[100] }}>{params.row.expertName}</Typography>
//         </Box>
//       ),
//     },
//     {
//       field: 'pocDetails',
//       headerName: 'POC',
//       flex: 1,
//       renderCell: (params) => (
//         <Box>
//           <Typography sx={{ color: colors.grey[100] }}>{params.row.pocName}</Typography>
//         </Box>
//       ),
//     },
//     { field: 'taskDuration', headerName: 'DURATION', flex: 1 },
//     {
//       field: 'istTime',
//       headerName: (
//         <>
//           IST TIME &nbsp; <img src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/255px-Flag_of_India.svg.png" width="25px" style={{ top: "5px" }} alt="BHARAT FLAG" />
//         </>
//       ),
//       width: 150
//     },
//     {
//       field: 'status',
//       headerName: 'STATUS',
//       flex: 1.5,
//       renderCell: (params) => (
//         <FormControl fullWidth>
//           <Select
//             value={params.value}
//             style={{ color: 'black', backgroundColor: getStatusColor(params.value)}}
//             onChange={(e) => handleStatusChange(params.row.id, e)}
//             sx={{ borderRadius: '50rem', height: '30px', width: '7rem', margin: '0.5rem auto', color: colors.grey[100] }}
//           >
//             <MenuItem value="Pending">
//               <div id="yellow-dot"></div>Pending
//             </MenuItem>
//             <MenuItem value="Assigned">
//               <div id="blue-dot"></div>Assigned
//             </MenuItem>
//             <MenuItem value="Reschedule">
//               <div id="orange-dot"></div>Reschedule
//             </MenuItem>
//             <MenuItem value="Complete">
//               <div id="green-dot"></div>Complete
//             </MenuItem>
//             <MenuItem value="Cancelled">
//               <div id="red-dot"></div>Cancelled
//             </MenuItem>
//           </Select>
//         </FormControl>
//       ),
//     }, 
//     {
//       field: 'updateTime',
//       headerName: 'TIME',
//       flex: 1,
//       renderCell: (params) => (
//         <Button variant="contained" sx={{ backgroundColor: colors.primary[300], color: colors.grey[900] }} onClick={() => handleOpenUpdateTime(params.row.id, params.row.istTime)}>
//           <FaRegEdit />
//         </Button>
//       ),
//     },
//     {
//       field: 'updateDuration',
//       headerName: 'DURATION',
//       flex: 1,
//       renderCell: (params) => (
//         <Button variant="contained" sx={{ backgroundColor: colors.primary[300], color: colors.grey[900] }} onClick={() => handleOpenUpdateDuration(params.row)}>
//           <FaRegEdit />
//         </Button>
//       ),
//     },
//     {
//       field: 'giveFeedback',
//       headerName: 'FEEDBACK',
//       flex: 1,
//       renderCell: (params) => (
//         <Button variant="contained" sx={{ backgroundColor: colors.primary[300], color: colors.grey[900] }} onClick={() => handleOpenGiveFeedback(params.row.id)}>
//           <ThumbUpAltRoundedIcon />
//         </Button>
//       ),
//     },
//     {
//       field: 'viewFeedback',
//       headerName: 'VIEW',
//       flex: 1,
//       renderCell: (params) => (
//         <Button variant="contained" sx={{ backgroundColor: colors.primary[300], color: colors.grey[900] }} onClick={() => getFeedbackScreenshot(params.row.id)} disabled={!params.row.screenshot}>
//           <FaRegEye />
//         </Button>
//       ),
//     },
//   ];


//   const getStatusColor = (status) => {
//     switch (status) {
//       case 'Pending':
//         return '#FFD700';
//       case 'Assigned':
//         return '#1E90FF';
//       case 'Reschedule':
//         return '#FFA500';
//       case 'Complete':
//         return '#008000';
//       case 'Cancelled':
//         return '#FF0000';
//       default:
//         return '#000000';
//     }
//   };

//   return (
//     <>
//       <Box></Box>
//       <Container
//         sx={{
//           minWidth: '90%',
//           padding: '20px',
//           backgroundColor: colors.primary[500],
//         }}
//       >
//         <Grid container justifyContent="space-between" alignItems="center" sx={{ marginBottom: '20px' }}>
//           <Typography variant="h4" align="center" sx={{ color: colors.grey[100] }}>
//             Manage Tasks
//           </Typography>
//           <Button variant="outlined" onClick={handleAddTaskClick} sx={{ color: colors.grey[100], borderColor: colors.grey[100] }}>
//             Add Task
//           </Button>
//         </Grid>

//         {addTaskOpen && <AddTask open={addTaskOpen} handleClose={handleAddTaskClose} />}

//         {updateTimeOpen && (
//           <UpdateTime taskId={selectedTask.taskId} currentISTTime={selectedTask.istTime} onClose={handleCloseUpdateTime} />
//         )}

       
//         {updateDurationOpen && (
//           <UpdateDurationDialog open={updateDurationOpen} onClose={handleCloseUpdateDuration} task={selectedTask} />
//         )}

//         {giveFeedbackOpen && (
//           <GiveFeedback open={giveFeedbackOpen} onClose={handleCloseGiveFeedback} taskId={selectedTask.taskId} />
//         )}

//         {isLoading ? (
//           <Loader />
//         ) : (
//           <Box sx={{ height: '80vh',minWidth: '60%',maxWidth: '110%' ,width: '110%', marginLeft: "-6rem" }}>
//             <DataGrid
//               rows={rows}
//               columns={columns}
//               pageSize={10}
//               rowsPerPageOptions={[10]}
//               sortModel={sortModel}
//               components={{
//           Toolbar: GridToolbarExport,
//         }}
//               onSortModelChange={(model) => setSortModel(model)}
//               sx={{
//                 '& .MuiDataGrid-columnHeaders': {
//                   backgroundColor: colors.primary[700],
//                   color: '#94E2CD',
//                 },
//                 '& .MuiDataGrid-cell': {
//                   color: colors.grey[100],
//                 },
//                 '& .MuiDataGrid-row:hover': {
//                   backgroundColor: colors.primary[600],
//                 },
//                 '& .MuiDataGrid-footerContainer': {
//                   backgroundColor: colors.primary[600],
//                   color: colors.grey[100],
//                 },
//                 '& .MuiButton-contained': {
//                   color: colors.grey[100],
//                 },
//               }}
//             />
//           </Box>
//         )}

//         <Dialog open={feedbackDialogOpen} onClose={handleCloseFeedbackDialog} fullWidth maxWidth="lg">
//           <DialogTitle sx={{ color: colors.grey[100], backgroundColor: '#3e4396' }}>Feedback</DialogTitle>
//           <DialogContent sx={{ backgroundColor: colors.primary[700] }}>
//             {fbUrl &&(<><p style={{textAlign:"center"}}>{feedback}</p> <img src={fbUrl} alt="Feedback Screenshot"  style={{ width: '100%', maxHeight: '600px', objectFit: 'contain' }} /></>)}
//           </DialogContent>
//           <DialogActions sx={{ backgroundColor: colors.primary[700] }}>
//             <Button onClick={handleCloseFeedbackDialog} sx={{ color: colors.grey[100] }}>
//               Close
//             </Button>
//           </DialogActions>
//         </Dialog>

//         <CandidateDetailsPopup 
//           open={popupOpen} 
//           onClose={() => setPopupOpen(false)} 
//           details={candidateDetails} 
//           loading={loadingCandidateDetails}
//         />
//       </Container>
//     </>
//   );
// };

// const CandidateDetailsPopup = ({ open, onClose, details, loading }) => (
//   <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
//     <DialogTitle>Candidate Details</DialogTitle>
//     <DialogContent>
//       {loading ? (
//         <Typography>Loading...</Typography>
//       ) : (
//         <>
//           <Typography>Email: {details.email}</Typography>
//           <Typography>Phone: {details.phone}</Typography>
//         </>
//       )}
//     </DialogContent>
//     <DialogActions>
//       <Button onClick={onClose}>Close</Button>
//     </DialogActions>
//   </Dialog>
// );

// export default ManageTasks;




import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { fetchAllTasks, updateTaskStatus } from '../../actions/Admin';
import { clearError } from '../../reducers/Admin';
import toast from 'react-hot-toast';
import Loader from '../Loading/Loading';
import AddTask from './AddTask';
import UpdateTime from './UpdateTime';
import UpdateDurationDialog from './UpdateDuration';
import GiveFeedback from './GiveFeedBack';
import axios from 'axios';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import {
  Container,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Box,
  useTheme,
} from '@mui/material';
import { DataGrid, GridToolbarExport } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import { FaRegEye, FaRegEdit } from 'react-icons/fa';

const ManageTasks = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const { tasks, isLoading, error } = useSelector((state) => state.admin);

  const [addTaskOpen, setAddTaskOpen] = useState(false);
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const [fbUrl, setFBUrl] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [updateTimeOpen, setUpdateTimeOpen] = useState(false);
  const [updateDurationOpen, setUpdateDurationOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [giveFeedbackOpen, setGiveFeedbackOpen] = useState(false);
  const [sortModel, setSortModel] = useState([
    {
      field: 'estTime',
      sort: 'desc',
    },
  ]);

  const [popupOpen, setPopupOpen] = useState(false);
  const [candidateDetails, setCandidateDetails] = useState({ email: '', phone: '' });
  const [loadingCandidateDetails, setLoadingCandidateDetails] = useState(false);

  useEffect(() => {
    dispatch(fetchAllTasks());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
  }, [error, dispatch]);

  const handleStatusChange = async (taskId, event) => {
  const newStatus = event.target.value;

  try {
    // Dispatch the action and await the result
    const result = await dispatch(updateTaskStatus({ taskId, status: newStatus }));
    if (result.success) {
      toast.success(result.message);
      dispatch(fetchAllTasks());
    } else {
      toast.error(result.message);
    }
  } catch (error) {
    toast.error('An unexpected error occurred. Please try again.');
  }
};


  const handleOpenUpdateTime = (taskId, istTime) => {
    setSelectedTask({ taskId, istTime });
    setUpdateTimeOpen(true);
  };

  const handleCloseUpdateTime = () => {
    setUpdateTimeOpen(false);
  };

  const handleOpenUpdateDuration = (task) => {
    setSelectedTask(task);
    setUpdateDurationOpen(true);
  };

  const handleCloseUpdateDuration = () => {
    setUpdateDurationOpen(false);
  };

  const handleAddTaskClick = () => {
    setAddTaskOpen(true);
  };

  const handleAddTaskClose = () => {
    setAddTaskOpen(false);
  };

  const handleOpenGiveFeedback = (taskId) => {
    setSelectedTask({ taskId });
    setGiveFeedbackOpen(true);
  };

  const handleCloseGiveFeedback = () => {
    setGiveFeedbackOpen(false);
  };

  const getFeedbackScreenshot = async (taskId) => {
    try {
      const token = localStorage.getItem('admin');
      const config = {
        method: 'get',
        url: `/api/v1/admin/task/${taskId}/screenshot`,
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.request(config);
      const base64Image = response.data.task.screenshot.data;
      const fb = response.data.task.feedback;
      setFeedback(fb);
      const scURL = `data:${response.headers['content-type'].toLowerCase()};base64,${base64Image}`;
      setFBUrl(scURL);
      setFeedbackDialogOpen(true);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }; 

  const handleCloseFeedbackDialog = () => {
    setFBUrl(null);
    setFeedbackDialogOpen(false);
  };

  const getRelativeDate = (date) => {
    const now = moment().startOf('day');
    const targetDate = moment(date).startOf('day');
    const diff = targetDate.diff(now, 'days');
    const time = moment(date).format('hh:mm A');

    if (diff === 0) {
      return { text: `Today ${time}`, isRelative: true };
    } else if (diff === 1) {
      return { text: `Tomorrow ${time}`, isRelative: true };
    } else if (diff === -1) {
      return { text: `Yesterday ${time}`, isRelative: true };
    } else {
      return { text: moment(date).format('D/M/YYYY hh:mm A'), isRelative: false };
    }
  };

  const rows = tasks
   .filter((task) => {
     const isToday = moment(task.ISTTIME).isSame(moment(), 'day');
     return( task.status === "Pending"||task.status === "Assigned")||((task.status === "Assigned"||task.status === "Reschedule"||task.status === "Complete"||task.status === "Cancelled"||task.status === "Pending") && isToday)
   })
  .map((task) => {
    const istTimeRelative = getRelativeDate(task.ISTTIME);
    const estTimeRelative = getRelativeDate(task.ESTTIME);
// 
    return { 
      id: task._id,
      candidateId: task.candidateId, 
      candidateName: task.candidateId ? task.candidateId.name : "",
      candidateType: task.candidateType, 
      taskType: task.taskType,
      technology: task.candidateId ? task.candidateId.technology : "",
      expertName: task.expertId ? task.expertId.name : '',
      expertPrice: task.expertPrice,
      pocName: task.pocId ? task.pocId.name : '',
      pocPrice: task.pocPrice,
      taskDuration: `${task.hour}:${task.minute}`,
      istTime: istTimeRelative.text,
      estTime: estTimeRelative.text,
      status: task.status,
      screenshot: task.screenshot,
    };
  });


  const handleCandidateClick = async (candidateId) => {
    setLoadingCandidateDetails(true);
    setPopupOpen(true);
    try {
      const response = await axios.get(`/api/v1/admin/candidate/${candidateId}`);
      const { email, phone } = response.data;
      setCandidateDetails({ email, phone });
    } catch (error) {
      toast.error('Failed to fetch candidate details');
    } finally {
      setLoadingCandidateDetails(false);
    }
  };

  const columns = [
    {
      field: 'estTime',
      headerName: (
        <>
          EST TIME &nbsp; <img src="https://cdn.britannica.com/33/4833-004-828A9A84/Flag-United-States-of-America.jpg" width="25px" style={{ top: "5px" }} alt="US FLAG" />
        </>
      ),
      width: 150
    },
    { 
      field: 'candidateName', 
      headerName: 'CANDIDATE', 
      flex: 2, 
      renderCell: (params) => (
        <Box onClick={() => handleCandidateClick(params.row.candidateId)}>
          <Typography sx={{ color: colors.grey[100], cursor: 'pointer' }}>{params.row.candidateName}</Typography>
          <Typography sx={{ color: colors.grey[100] }}>{params.row.candidateType}</Typography>
        </Box>
      ) 
    },
    { field: 'technology', headerName: 'TECHNOLOGY', flex: 1 },
    { field: 'taskType', headerName: 'TASK TYPE', flex: 1 },
    {
      field: 'expertDetails',
      headerName: 'EXPERT',
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography sx={{ color: colors.grey[100] }}>{params.row.expertName}</Typography>
        </Box>
      ),
    },
    {
      field: 'pocDetails',
      headerName: 'POC',
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography sx={{ color: colors.grey[100] }}>{params.row.pocName}</Typography>
        </Box>
      ),
    },
    { field: 'taskDuration', headerName: 'DURATION', flex: 1 },
    {
      field: 'istTime',
      headerName: (
        <>
          IST TIME &nbsp; <img src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/255px-Flag_of_India.svg.png" width="25px" style={{ top: "5px" }} alt="BHARAT FLAG" />
        </>
      ),
      width: 150
    },
    {
      field: 'status',
      headerName: 'STATUS',
      flex: 1.8,
      renderCell: (params) => (
        <FormControl fullWidth>
          <Select
            value={params.value}
            style={{ color: 'black', backgroundColor: getStatusColor(params.value)}}
            onChange={(e) => handleStatusChange(params.row.id, e)}
            sx={{ borderRadius: '50rem', height: '30px', width: '6.8rem', margin: '0.5rem auto', color: colors.grey[100] }}
          >
            <MenuItem value="Pending">
              <div id="yellow-dot"></div>Pending
            </MenuItem>
            <MenuItem value="Assigned">
              <div id="blue-dot"></div>Assigned
            </MenuItem>
            <MenuItem value="Reschedule">
              <div id="orange-dot"></div>Reschedule
            </MenuItem>
            <MenuItem value="Complete">
              <div id="green-dot"></div>Complete
            </MenuItem>
            <MenuItem value="Cancelled">
              <div id="red-dot"></div>Cancelled
            </MenuItem>
          </Select>
        </FormControl>
      ),
    }, 
    {
      field: 'updateTime',
      headerName: 'TIME',
      flex: 1,
      renderCell: (params) => (
        <Button variant="contained" sx={{ backgroundColor: colors.primary[300], color: colors.grey[900] }} onClick={() => handleOpenUpdateTime(params.row.id, params.row.istTime)}>
          <FaRegEdit />
        </Button>
      ),
    },
    {
      field: 'updateDuration',
      headerName: 'DURATION',
      flex: 1,
      renderCell: (params) => (
        <Button variant="contained" sx={{ backgroundColor: colors.primary[300], color: colors.grey[900] }} onClick={() => handleOpenUpdateDuration(params.row)}>
          <FaRegEdit />
        </Button>
      ),
    },
    {
      field: 'giveFeedback',
      headerName: 'FEEDBACK',
      flex: 1,
      renderCell: (params) => (
        <Button variant="contained" sx={{ backgroundColor: colors.primary[300], color: colors.grey[900] }} onClick={() => handleOpenGiveFeedback(params.row.id)}>
          <ThumbUpAltRoundedIcon />
        </Button>
      ),
    },
    {
      field: 'viewFeedback',
      headerName: 'VIEW',
      flex: 1,
      renderCell: (params) => (
        <Button variant="contained" sx={{ backgroundColor: colors.primary[300], color: colors.grey[900] }} onClick={() => getFeedbackScreenshot(params.row.id)} disabled={!params.row.screenshot}>
          <FaRegEye />
        </Button>
      ),
    },
  ];

  const getStatusColor = (status) => {
    switch (status) {
      case 'Pending':
        return '#FFD700';
      case 'Assigned':
        return '#1E90FF';
      case 'Reschedule':
        return '#FFA500';
      case 'Complete':
        return '#008000';
      case 'Cancelled':
        return '#FF0000';
      default:
        return '#000000';
    }
  };

  return (
    <>
      <Box></Box>
      <Container
        sx={{
          minWidth: '85%',
          padding: '20px',
          backgroundColor: colors.primary[500],
          marginLeft:"15rem"
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center" sx={{ marginBottom: '20px' }}>
          <Typography variant="h4" align="center" sx={{ color: colors.grey[100] }}>
            Manage Tasks
          </Typography>
          <Button variant="outlined" onClick={handleAddTaskClick} sx={{ color: colors.grey[100], borderColor: colors.grey[100] }}>
            Add Task
          </Button>
        </Grid>

        {addTaskOpen && <AddTask open={addTaskOpen} handleClose={handleAddTaskClose} />}

        {updateTimeOpen && (
          <UpdateTime taskId={selectedTask.taskId} currentISTTime={selectedTask.istTime} onClose={handleCloseUpdateTime} />
        )}

        {updateDurationOpen && (
          <UpdateDurationDialog open={updateDurationOpen} onClose={handleCloseUpdateDuration} task={selectedTask} />
        )}

        {giveFeedbackOpen && (
          <GiveFeedback open={giveFeedbackOpen} onClose={handleCloseGiveFeedback} taskId={selectedTask.taskId} />
        )} 

        {isLoading ? (
          <Loader />
        ) : (
          <Box sx={{ height: '80vh', minWidth: '100%', width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              sortModel={sortModel}
              components={{
                Toolbar: GridToolbarExport,
              }}
              onSortModelChange={(model) => setSortModel(model)}
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: colors.primary[700],
                  color: '#94E2CD',
                },
                '& .MuiDataGrid-cell': {
                  color: colors.grey[100],
                },
                '& .MuiDataGrid-row:hover': {
                  backgroundColor: colors.primary[600],
                },
                '& .MuiDataGrid-footerContainer': {
                  backgroundColor: colors.primary[600],
                  color: colors.grey[100],
                },
                '& .MuiButton-contained': {
                  color: colors.grey[100],
                },
              }}
            />
          </Box>
        )}

        <Dialog open={feedbackDialogOpen} onClose={handleCloseFeedbackDialog} fullWidth maxWidth="lg">
          <DialogTitle sx={{ color: colors.grey[100], backgroundColor: '#3e4396' }}>Feedback</DialogTitle>
          <DialogContent sx={{ backgroundColor: colors.primary[700] }}>
            {fbUrl && (<><p style={{ textAlign: "center" }}>{feedback}</p> <img src={fbUrl} alt="Feedback Screenshot" style={{ width: '100%', maxHeight: '600px', objectFit: 'contain' }} /></>)}
          </DialogContent>
          <DialogActions sx={{ backgroundColor: colors.primary[700] }}>
            <Button onClick={handleCloseFeedbackDialog} sx={{ color: colors.grey[100] }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <CandidateDetailsPopup 
          open={popupOpen} 
          onClose={() => setPopupOpen(false)} 
          details={candidateDetails} 
          loading={loadingCandidateDetails}
        />
      </Container>
    </>
  );
};

const CandidateDetailsPopup = ({ open, onClose, details, loading }) => (
  <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
    <DialogTitle>Candidate Details</DialogTitle>
    <DialogContent>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <>
          <Typography>Email: {details.email}</Typography>
          <Typography>Phone: {details.phone}</Typography>
        </>
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </Dialog>
);

export default ManageTasks;
