import { configureStore} from "@reduxjs/toolkit"
import loginReducer from "../reducers/User"
import adminReducer from "../reducers/Admin"
import pocReducer from "../reducers/POC"
import expertReducer from "../reducers/Expert"

export const store=configureStore({
    reducer:{
        user:loginReducer,
        admin:adminReducer,
        poc:pocReducer,
        expert:expertReducer
    }
}) 

