import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAllTasks, updateTaskDuration } from '../../actions/Admin';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  IconButton,
  FormControl,
} from '@mui/material';
import toast from 'react-hot-toast';

const UpdateDuration = ({ open, onClose, task }) => {
  const dispatch = useDispatch();
  const [hour, setUpdateHour] = useState(task.taskDuration[0] || 0); 
  const [minute, setUpdateMinute] = useState(task.taskDuration[2] || 0);
  
  const handleSave = () => {
    dispatch(updateTaskDuration({ taskId: task.id, hour, minute }))
      .then(() => {
        toast.success('Duration updated successfully');
        dispatch(fetchAllTasks());
        onClose();
      })
      .catch(() => {
        toast.error('Failed to update duration');
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Update Duration
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <FormControl  margin="normal">
          <TextField
            label="Update Hour"
            type="number"
            name='hour'
            value={hour}
            onChange={(e) => setUpdateHour(e.target.value)}
          />
        </FormControl> 

        <FormControl  margin="normal">
          <TextField
            label="Update Minute"
            type="number"
            name='minute'
            value={minute}
            onChange={(e) => setUpdateMinute(e.target.value)}
            
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateDuration;