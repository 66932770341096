import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Box, Typography } from '@mui/material';

const Clock = ({ timezone, label, colors }) => {
  const [time, setTime] = useState(moment.tz(timezone).format('hh:mm:ss A'));

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment.tz(timezone).format('hh:mm:ss A'));
    }, 1000);
    return () => clearInterval(interval);
  }, [timezone]);

  return (
    <Box sx={{ marginTop: 2 }}>
      <Typography variant="subtitle1" sx={{ color: colors.grey[100] }}>
        {label}
      </Typography>
      <Typography variant="h6" sx={{ color: colors.grey[100] }}>
        {time}
      </Typography>
    </Box>
  );
};

export default Clock;
