// import React, { useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { addCandidate } from '../../actions/POC';
// import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, FormControl, InputLabel, Select, MenuItem,useTheme } from '@mui/material';
// import CandidateTable from "./CandidateTable"
// import toast from 'react-hot-toast';
// import { tokens } from '../../theme';


// const AddCandidate = () => {
//   const dispatch = useDispatch();
// const theme=useTheme();
// const colors=tokens(theme.palette.mode)

//   const [formData, setFormData] = useState({
//     candidateType: '',
//     name: '',
//     email: '',
//     phone: '',
//     cv: null,
//     technology: ''
//   });
  
//   const [open, setOpen] = useState(false);
  
//   const candidateTypes = [
//     { label: "PER CALL", value: "perCall" },
//     { label: "TILL PLACEMENT", value: "tillPlacement" }
//   ];
  
//   const handleChange = (e) => {
//     if (e.target.name === 'cv') {
//       setFormData({ ...formData, cv: e.target.files[0] });
//     } else {
//       setFormData({ ...formData, [e.target.name]: e.target.value });
//     }
//   };

//   const handleSubmit = () => {
//     const formDataToSend = new FormData();
//     formDataToSend.append('candidateType', formData.candidateType);
//     formDataToSend.append('name', formData.name);
//     formDataToSend.append('email', formData.email);
//     formDataToSend.append('phone', formData.phone);
//     formDataToSend.append('cv', formData.cv);
//     formDataToSend.append('technology', formData.technology);

//     dispatch(addCandidate(formDataToSend));
//     toast.success("candidate Added")
//     handleClose();
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setFormData({
//       candidateType: '', 
//       name: '',
//       email: '',
//       phone: '',
//       cv: null,
//       technology: ''
//     });
//   };

//   return (
//     <div>
     
//       <Dialog open={open} onClose={handleClose}>
//         <DialogTitle>Add Candidate</DialogTitle>
//         <DialogContent>
//           <FormControl fullWidth>
//             <InputLabel id="candidateType-label">Candidate Type</InputLabel>
//             <Select
//               labelId="candidateType-label"
//               id="candidateType"
//               required
//               name="candidateType"
//               value={formData.candidateType}
//               onChange={handleChange}
//             >
//               {candidateTypes.map((ctype, index) => (
//                 <MenuItem key={index} value={ctype.value}>{ctype.label}</MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//           <FormControl fullWidth margin="normal">
//             <TextField
//               name="name"
//               label="Name"
//               type='text'
//               value={formData.name}
//               onChange={handleChange}
//             />
//           </FormControl>
//           <FormControl fullWidth margin="normal">
//             <TextField
//               name="email"
//               label="Email"
//               type="email"
//               value={formData.email}
//               onChange={handleChange}
//             />
//           </FormControl>
//           <FormControl fullWidth margin="normal">
//             <TextField
//               name="phone"
//               label="Phone"
//               type='text'
//               value={formData.phone}
//               onChange={handleChange}
//             />
//           </FormControl>
//           <FormControl fullWidth margin="normal">
//             <TextField
//               name="technology"
//               label="Technology"
//               type='text'
//               value={formData.technology}
//               onChange={handleChange}
//             />
//           </FormControl>
//           <FormControl fullWidth margin="normal">
//             <InputLabel><strong>Upload CV</strong></InputLabel>
//             <br /><br />
//             <TextField
//               id="upload-cv"
//               type="file"
//               onChange={handleChange}
//               name="cv"
//             />
//           </FormControl>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} variant='outlined' sx={{ color:"red", borderColor:"red"  }}>
//             Cancel
//           </Button>
//           <Button onClick={handleSubmit} variant='outlined' sx={{ color:colors.greenAccent[500], borderColor:colors.greenAccent[500]  }}>
//             Add Candidate
//           </Button>
//         </DialogActions>
//       </Dialog>
//       <h1 style={{ textAlign: "center" }}>All Candidates</h1>
//       <Button variant='outlined' sx={{ color:colors.greenAccent[500], borderColor:colors.greenAccent[500]  }}  style={{ marginLeft: "10rem",marginBottom: "2rem" }} onClick={() => setOpen(true)}>Add Candidate</Button>
//       <CandidateTable />
//     </div>
//   );
// };

// export default AddCandidate;



// import React, { useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { addCandidate } from '../../actions/POC';
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   TextField,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   useTheme,
// } from '@mui/material';
// import CandidateTable from "./CandidateTable";
// import toast from 'react-hot-toast';
// import { tokens } from '../../theme';

// const AddCandidate = () => {
//   const dispatch = useDispatch();
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);

//   const [formData, setFormData] = useState({
//     candidateType: '',
//     name: '',
//     email: '',
//     phone: '',
//     cv: null,
//     technology: ''
//   });

//   const [open, setOpen] = useState(false);

//   const candidateTypes = [
//     { label: "PER CALL", value: "perCall" },
//     { label: "TILL PLACEMENT", value: "tillPlacement" }
//   ];

//   const technologyOptions = [
//     "Business Analyst",
//     "Financial Analyst",
//     "Project Manager",
//     "Scrum Master",
//     "Data Analyst",
//     "Data Engineer",
//     "Data Scientists",
//     "Java Developer",
//     "Java Full Stack Developer",
//     "Software Engineer",
//     "Python Developer",
//     "Front-End Developer",
//     "Backend Developer",
//     "Cyber Security Analyst",
//     "Network Engineer",
//     "Others"
//   ];

//   const handleChange = (e) => {
//     if (e.target.name === 'cv') {
//       setFormData({ ...formData, cv: e.target.files[0] });
//     } else {
//       setFormData({ ...formData, [e.target.name]: e.target.value });
//     }
//   };

//   const handleSubmit = () => {
//     const formDataToSend = new FormData();
//     formDataToSend.append('candidateType', formData.candidateType);
//     formDataToSend.append('name', formData.name);
//     formDataToSend.append('email', formData.email);
//     formDataToSend.append('phone', formData.phone);
//     formDataToSend.append('cv', formData.cv);
//     formDataToSend.append('technology', formData.technology);

//     dispatch(addCandidate(formDataToSend));
//     toast.success("Candidate Added");
//     handleClose();
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setFormData({
//       candidateType: '',
//       name: '',
//       email: '',
//       phone: '',
//       cv: null,
//       technology: ''
//     });
//   };

//   return (
//     <div>
//       <Dialog open={open} onClose={handleClose}>
//         <DialogTitle>Add Candidate</DialogTitle>
//         <DialogContent>
//           <FormControl fullWidth>
//             <InputLabel id="candidateType-label">Candidate Type</InputLabel>
//             <Select
//               labelId="candidateType-label"
//               id="candidateType"
//               required
//               name="candidateType"
//               value={formData.candidateType}
//               onChange={handleChange}
//             >
//               {candidateTypes.map((ctype, index) => (
//                 <MenuItem key={index} value={ctype.value}>{ctype.label}</MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//           <FormControl fullWidth margin="normal">
//             <TextField
//               name="name"
//               label="Name"
//               type="text"
//               value={formData.name}
//               onChange={handleChange}
//             />
//           </FormControl>
//           <FormControl fullWidth margin="normal">
//             <TextField
//               name="email"
//               label="Email"
//               type="email"
//               value={formData.email}
//               onChange={handleChange}
//             />
//           </FormControl>
//           <FormControl fullWidth margin="normal">
//             <TextField
//               name="phone"
//               label="Phone"
//               type="text"
//               value={formData.phone}
//               onChange={handleChange}
//             />
//           </FormControl>
//           <FormControl fullWidth margin="normal">
//             <InputLabel id="technology-label">Technology</InputLabel>
//             <Select
//               labelId="technology-label"
//               id="technology"
//               required
//               name="technology"
//               value={formData.technology}
//               onChange={handleChange}
//             >
//               {technologyOptions.map((tech, index) => (
//                 <MenuItem key={index} value={tech}>{tech}</MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//           <FormControl fullWidth margin="normal">
//             <InputLabel><strong>Upload CV</strong></InputLabel>
//             <br /><br />
//             <TextField
//               id="upload-cv"
//               type="file"
//               onChange={handleChange}
//               name="cv"
//             />
//           </FormControl>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} variant='outlined' sx={{ color: "red", borderColor: "red" }}>
//             Cancel
//           </Button>
//           <Button onClick={handleSubmit} variant='outlined' sx={{ color: colors.greenAccent[500], borderColor: colors.greenAccent[500] }}>
//             Add Candidate
//           </Button>
//         </DialogActions>
//       </Dialog>
//       <h1 style={{ textAlign: "center" }}>All Candidates</h1>
//       <Button variant='outlined' sx={{ color: colors.greenAccent[500], borderColor: colors.greenAccent[500] }} style={{ marginLeft: "10rem", marginBottom: "2rem" }} onClick={() => setOpen(true)}>Add Candidate</Button>
//       <CandidateTable />
//     </div>
//   );
// };

// export default AddCandidate;


import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addCandidate } from '../../actions/POC';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
} from '@mui/material';
import CandidateTable from "./CandidateTable";
import toast from 'react-hot-toast';
import { tokens } from '../../theme';

const AddCandidate = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [formData, setFormData] = useState({
    candidateType: '',
    name: '',
    email: '',
    phone: '',
    cv: null,
    technology: '',
    subPoc: ''
  });

  const [open, setOpen] = useState(false);

  const candidateTypes = [
    { label: "PER CALL", value: "perCall" },
    { label: "TILL PLACEMENT", value: "tillPlacement" }
  ];

  const technologyOptions = [
    "Business Analyst",
    "Financial Analyst",
    "Project Manager",
    "Scrum Master",
    "Data Analyst",
    "Data Engineer",
    "Data Scientists",
    "Java Developer",
    "Java Full Stack Developer",
    "Software Engineer",
    "Python Developer",
    "Front-End Developer",
    "Backend Developer",
    "Cyber Security Analyst",
    "Network Engineer",
    "ASP .NET Developer",
    "Others"
  ];

  const handleChange = (e) => {
    if (e.target.name === 'cv') {
      setFormData({ ...formData, cv: e.target.files[0] });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = () => {
    const formDataToSend = new FormData();
    formDataToSend.append('candidateType', formData.candidateType);
    formDataToSend.append('name', formData.name);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('phone', formData.phone);
    formDataToSend.append('cv', formData.cv);
    formDataToSend.append('technology', formData.technology);
    formDataToSend.append('subPoc', formData.subPoc);

    dispatch(addCandidate(formDataToSend));
    toast.success("Candidate Added");
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setFormData({
      candidateType: '',
      name: '',
      email: '',
      phone: '',
      cv: null,
      technology: '',
      subPoc: ''
    });
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Candidate</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel
              id="candidateType-label"
              sx={{
                color: colors.greenAccent[500],
                '&.Mui-focused': {
                  color: colors.greenAccent[500],
                },
              }}
            >
              Candidate Type
            </InputLabel>
            <Select
              labelId="candidateType-label"
              id="candidateType"
              required
              name="candidateType"
              value={formData.candidateType}
              onChange={handleChange}
              sx={{
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.greenAccent[500],
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.greenAccent[500],
                },
              }}
            >
              {candidateTypes.map((ctype, index) => (
                <MenuItem key={index} value={ctype.value}>{ctype.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              name="name"
              label="Name"
              type="text"
              value={formData.name}
              onChange={handleChange}
              sx={{
                '& .MuiInputLabel-root': {
                  color: colors.greenAccent[500],
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: colors.greenAccent[500],
                },
                '& .MuiOutlinedInput-root': {
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.greenAccent[500],
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.greenAccent[500],
                  },
                  '& input::placeholder': {
                    color: colors.greenAccent[500],
                  },
                },
              }}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              name="email"
              label="Email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              sx={{
                '& .MuiInputLabel-root': {
                  color: colors.greenAccent[500],
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: colors.greenAccent[500],
                },
                '& .MuiOutlinedInput-root': {
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.greenAccent[500],
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.greenAccent[500],
                  },
                  '& input::placeholder': {
                    color: colors.greenAccent[500],
                  },
                },
              }}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              name="phone"
              label="Phone"
              type="text"
              value={formData.phone}
              onChange={handleChange}
              sx={{
                '& .MuiInputLabel-root': {
                  color: colors.greenAccent[500],
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: colors.greenAccent[500],
                },
                '& .MuiOutlinedInput-root': {
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.greenAccent[500],
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.greenAccent[500],
                  },
                  '& input::placeholder': {
                    color: colors.greenAccent[500],
                  },
                },
              }}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel
              id="technology-label"
              sx={{
                color: colors.greenAccent[500],
                '&.Mui-focused': {
                  color: colors.greenAccent[500],
                },
              }}
            >
              Technology
            </InputLabel>
            <Select
              labelId="technology-label"
              id="technology"
              required
              name="technology"
              value={formData.technology}
              onChange={handleChange}
              sx={{
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.greenAccent[500],
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.greenAccent[500],
                },
              }}
            >
              {technologyOptions.map((tech, index) => (
                <MenuItem key={index} value={tech}>{tech}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel><strong>Upload CV</strong></InputLabel>
            <br /><br />
            <TextField
              id="upload-cv"
              type="file"
              onChange={handleChange}
              name="cv"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              name="subPoc"
              label="TAG"
              type="text"
              value={formData.subPoc}
              onChange={handleChange}
              sx={{
                '& .MuiInputLabel-root': {
                  color: colors.greenAccent[500],
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: colors.greenAccent[500],
                },
                '& .MuiOutlinedInput-root': {
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.greenAccent[500],
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: colors.greenAccent[500],
                  },
                  '& input::placeholder': {
                    color: colors.greenAccent[500],
                  },
                },
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleClose} 
            variant='outlined' 
            sx={{ 
              color: "red", 
              borderColor: "red",
              '&:hover': {
                borderColor: colors.greenAccent[500],
              },
              '&.Mui-focused': {
                borderColor: colors.greenAccent[500],
              },
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit} 
            variant='outlined' 
            sx={{ 
              color: colors.greenAccent[500], 
              borderColor: colors.greenAccent[500],
              '&:hover': {
                borderColor: colors.greenAccent[700],
              },
              '&.Mui-focused': {
                borderColor: colors.greenAccent[700],
              },
            }}
          >
            Add Candidate
          </Button>
        </DialogActions>
      </Dialog>
      <h1 style={{ textAlign: "center" }}>All Candidates</h1>
      <Button 
        variant='outlined' 
        sx={{ 
          color: colors.greenAccent[500], 
          borderColor: colors.greenAccent[500],
          '&:hover': {
            borderColor: colors.greenAccent[700],
          },
          '&.Mui-focused': {
            borderColor: colors.greenAccent[700],
          },
        }}  
        style={{ marginLeft: "10rem", marginBottom: "2rem" }} 
        onClick={() => setOpen(true)}
      >
        Add Candidate
      </Button>
      <CandidateTable />
    </div>
  );
};

export default AddCandidate;
