import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../reducers/User';
import { clearPoc } from '../../reducers/POC';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  Assignment as AssignmentIcon,
  Person as PersonIcon,
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { tokens } from '../../theme';
import Clock from './Clock';
import Tekizz from "../Images/Head_ekkiz.png"

const CSidebar = ({ isSidebarOpen, toggleSidebar }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
 
  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem('admin');
    localStorage.removeItem('userType');
    dispatch(clearPoc());
    dispatch(logout());
    window.location.reload();
  };

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open 
      sx={{
        width: isSidebarOpen ? 220 : 60, 
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isSidebarOpen ? 200 : 60,
          boxSizing: 'border-box',
          overflowX: 'hidden',
          backgroundColor: colors.primary[500],
          color: colors.grey[100],
        },
      }}
    >
      <Box 
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: theme.spacing(2),
          justifyContent: isSidebarOpen ? 'space-between' : 'center',
        }}
      >
        {isSidebarOpen && (
          <>
          <img src={Tekizz} height="50px" alt="Tekizz" />
          </>
        )}
        <IconButton onClick={toggleSidebar} sx={{ color: colors.grey[100] }} aria-label="toggle sidebar">
          {isSidebarOpen ? <ChevronLeftIcon /> : <MenuIcon />}
        </IconButton>
      </Box>
      <List>
        <Tooltip title="Dashboard" placement="right" disableHoverListener={isSidebarOpen}>
          <ListItem button component={Link} to="/poc/dashboard">
            <ListItemIcon sx={{ color: colors.grey[100], minWidth: isSidebarOpen ? 'auto' : 'unset' }}>
              <DashboardIcon />
            </ListItemIcon>
            {isSidebarOpen && <ListItemText primary="Dashboard" />}
          </ListItem>
        </Tooltip>
        <Tooltip title="All Tasks" placement="right" disableHoverListener={isSidebarOpen}>
          <ListItem button component={Link} to="/poc/alltask">
            <ListItemIcon sx={{ color: colors.grey[100], minWidth: isSidebarOpen ? 'auto' : 'unset' }}>
              <AssignmentIcon />
            </ListItemIcon>
            {isSidebarOpen && <ListItemText primary="All Tasks" />}
          </ListItem>
        </Tooltip>
        <Tooltip title="Candidate" placement="right" disableHoverListener={isSidebarOpen}>
          <ListItem button component={Link} to="/poc/add/candidate">
            <ListItemIcon sx={{ color: colors.grey[100], minWidth: isSidebarOpen ? 'auto' : 'unset' }}>
              <PersonIcon />
            </ListItemIcon>
            {isSidebarOpen && <ListItemText primary="Candidate" />}
          </ListItem>
        </Tooltip>
        <Tooltip title="Manage Payment" placement="right" disableHoverListener={isSidebarOpen}>
          <ListItem button component={Link} to="/poc/make/payment">
            <ListItemIcon sx={{ color: colors.grey[100], minWidth: isSidebarOpen ? 'auto' : 'unset' }}>
              <AssignmentIcon />
            </ListItemIcon>
            {isSidebarOpen && <ListItemText primary="Manage Payment" />}
          </ListItem>
        </Tooltip>
        {/* <Tooltip title="Payment Success" placement="right" disableHoverListener={isSidebarOpen}>
          <ListItem button component={Link} to="/poc/payment/success">
            <ListItemIcon sx={{ color: colors.grey[100], minWidth: isSidebarOpen ? 'auto' : 'unset' }}>
              <AssignmentIcon />
            </ListItemIcon>
            {isSidebarOpen && <ListItemText primary="Payment Succeess" />}
          </ListItem>
        </Tooltip> */}
      </List>
      {isSidebarOpen && (
        <Box sx={{ padding: theme.spacing(2) }}>
            <Button variant="contained" color="secondary" onClick={handleLogout}>
              Logout
            </Button>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: theme.spacing(2) }}>
              <img src="https://cdn.britannica.com/33/4833-004-828A9A84/Flag-United-States-of-America.jpg" alt="USA FLAG" style={{ marginRight: '1rem',width: '30px', height: '20px' }} />
              <Clock timezone="America/New_York" label="EST TIME" colors={colors} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: theme.spacing(2)   }}>
              <img src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/255px-Flag_of_India.svg.png" alt="IND FLAG" style={{marginRight: '1rem', width: '30px', height: '20px' }} />
              <Clock  timezone="Asia/Kolkata" label="IST TIME" colors={colors} />

            </Box>

          </Box>
      )}
    </Drawer>
  );
};

export default CSidebar;
