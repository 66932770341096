import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../reducers/User';
import { clearAdmin } from '../../reducers/Admin';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  Assignment as AssignmentIcon,
  Person as PersonIcon,
  Business as BusinessIcon,
  People as PeopleIcon,
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ExpandMoreOutlined,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { tokens } from '../../theme';
import Clock from './Clock';
import './responsive.css'; 
import Tekizz from "../Images/Head_ekkiz.png"

const Sidebar = ({ isSidebarOpen, toggleSidebar }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem('admin');
    localStorage.removeItem('userType');
    dispatch(clearAdmin());
    dispatch(logout()); 
    window.location.reload();
  };

  return (
    <div className="sidebar">
      <Drawer 
        variant="persistent"
        anchor="left"
        open
        sx={{
          // width: isSidebarOpen ? 'var(--sidebar-width)' : '60px',
          width: isSidebarOpen ? '0px' : '6px',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            // width: isSidebarOpen ? 'var(--sidebar-width)' : '60px',
          // width: isSidebarOpen ? '20px' : '60px',
            boxSizing: 'border-box',
            overflowX: 'hidden',
            backgroundColor: colors.primary[500],
            color: colors.grey[100],
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(2),
            justifyContent: 'space-between',
          }}
        >
          {isSidebarOpen && (
            <>
          <img src={Tekizz} height="50px" alt="Tekizz" />
          </>
          )}
          <IconButton onClick={toggleSidebar} sx={{ color: colors.grey[100] }}>
            {isSidebarOpen ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
        </Box>
        <List>
          <Tooltip title="DASHBOARD" placement="right" disableHoverListener={isSidebarOpen}>
            <ListItem button component={Link} to="/admin/dashboard">
              <ListItemIcon sx={{ color: colors.grey[100] }}>
                <DashboardIcon />
              </ListItemIcon>
              {isSidebarOpen && <ListItemText primary="DASHBOARD" />}
            </ListItem>
          </Tooltip>
          <Accordion
            sx={{
              backgroundColor: isSidebarOpen ? 'inherit' : 'transparent',
              boxShadow: 'none',
            }}
          >
            <AccordionSummary
              expandIcon={isSidebarOpen ? <ExpandMoreOutlined sx={{ color: colors.grey[100] }} /> : null}
              sx={{ display: 'flex', alignItems: 'center', color: colors.grey[100] }}
            >
              <ListItemIcon sx={{ color: colors.grey[100] }}>
                <AssignmentIcon />
              </ListItemIcon>
              {isSidebarOpen && <Typography>CompanyTasks</Typography>}
            </AccordionSummary>
            {isSidebarOpen && (
              <AccordionDetails>
                <List>
                  <Tooltip title="AssignTasks" placement="right" disableHoverListener={isSidebarOpen}>
                    <ListItem button component={Link} to="/admin/companyTasks?status=remainToAssign">
                      <ListItemText primary="AssignTasks" />
                    </ListItem>
                  </Tooltip>
                  <Tooltip title="ReassignTask" placement="right" disableHoverListener={isSidebarOpen}>
                    <ListItem button component={Link} to="/admin/companyTasks?status=pending">
                      <ListItemText primary="ReassignTask" />
                    </ListItem>
                  </Tooltip>
                </List>
              </AccordionDetails>
            )}
          </Accordion>
          <Tooltip title="POC" placement="right" disableHoverListener={isSidebarOpen}>
            <ListItem button component={Link} to="/admin/add/poc/company">
              <ListItemIcon sx={{ color: colors.grey[100] }}>
                <BusinessIcon />
              </ListItemIcon>
              {isSidebarOpen && <ListItemText primary="POC" />}
            </ListItem>
          </Tooltip>
          <Tooltip title="Expert" placement="right" disableHoverListener={isSidebarOpen}>
            <ListItem button component={Link} to="/admin/add/expert">
              <ListItemIcon sx={{ color: colors.grey[100] }}>
                <PeopleIcon />
              </ListItemIcon>
              {isSidebarOpen && <ListItemText primary="Expert" />}
            </ListItem>
          </Tooltip>
          <Tooltip title="Candidate" placement="right" disableHoverListener={isSidebarOpen}>
            <ListItem button component={Link} to="/admin/add/candidate">
              <ListItemIcon sx={{ color: colors.grey[100] }}>
                <PersonIcon />
              </ListItemIcon>
              {isSidebarOpen && <ListItemText primary="Candidate" />}
            </ListItem>
          </Tooltip>
          <Tooltip title="MANAGE" placement="right" disableHoverListener={isSidebarOpen}>
            <ListItem button component={Link} to="/admin/manageTasks">
              <ListItemIcon sx={{ color: colors.grey[100] }}>
                <AssignmentIcon />
              </ListItemIcon>
              {isSidebarOpen && <ListItemText primary="MANAGE" />}
            </ListItem>
          </Tooltip>
        </List>
        {isSidebarOpen && (
          <Box sx={{ padding: theme.spacing(2) }}>
            <Button variant="contained" color="secondary" onClick={handleLogout}>
              Logout
            </Button>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: theme.spacing(2) }}>
              <img src="https://cdn.britannica.com/33/4833-004-828A9A84/Flag-United-States-of-America.jpg" alt="USA FLAG" style={{ marginRight: '1rem', width: '30px', height: '20px' }} />
              <Clock timezone="America/New_York" label="EST TIME" colors={colors} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: theme.spacing(2) }}>
              <img src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/255px-Flag_of_India.svg.png" alt="IND FLAG" style={{ marginRight: '1rem', width: '30px', height: '20px' }} />
              <Clock style={{ fontSize: '50rem' }} timezone="Asia/Kolkata" label="IST TIME" colors={colors} />
            </Box>
          </Box>
        )}
      </Drawer>
    </div>
  );
};

export default Sidebar;
