// import React, { useEffect, useState } from 'react';
// import {
//   Box, TextField,  MenuItem, Select, FormControl,
//   Checkbox, Button, Typography, Grid, Fab, Dialog, DialogActions,
//   DialogContent, DialogContentText, DialogTitle, useTheme
// } from '@mui/material';
// import { Phone, AssignmentTurnedIn } from '@mui/icons-material';
// import { useSelector, useDispatch } from 'react-redux';
// import { useLocation } from 'react-router-dom';
// import { PerPayment, TillPayment } from '../../actions/POC';
// import payment from "./RazorPay";
// import { DataGrid } from '@mui/x-data-grid';
// import { tokens } from '../../theme';
// import moment from "moment";
// import { useNavigate } from 'react-router-dom';

// const taskTypes = [
//   { label: 'INTERVIEW', value: 'interview' },
//   { label: 'ASSESSMENT', value: 'assessment' },
//   { label: 'JD SESSION', value: 'jdSession' },
//   { label: 'RU SESSION', value: 'ruSession' },
//   { label: 'RESUME BUILDING', value: 'resumeBuilding' },
//   { label: 'TRAINING', value: 'training' },
// ];

// const App = () => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const location = useLocation();
//   const queryParams = new URLSearchParams(location.search);
//   const mobile = queryParams.get('mobile');
//   const [search, setSearch] = useState(mobile || '');
//   const [taskType, setTaskType] = useState('');
//   const [selectedTasks, setSelectedTasks] = useState([]);
//   const [taskView, setTaskView] = useState('perCall');
//   const [manualTillPlacementAmount, setManualTillPlacementAmount] = useState('');
//   const [openDialog, setOpenDialog] = useState(false);
//   const { TillPaymentTasks, PerPaymentTasks } = useSelector((state) => state.poc);
//   const dispatch = useDispatch();
//   const navigate=useNavigate()

//   useEffect(() => {
//     dispatch(PerPayment());
//   }, [dispatch]);

//   useEffect(() => { 
//     if (mobile) {
//       dispatch(TillPayment(mobile));
//     }
//   }, [dispatch, mobile]);

//   useEffect(() => {
//     if (taskView === 'tillPlacement') {
//       setSelectedTasks(TillPaymentTasks.map(task => task._id));
//     }
//   }, [TillPaymentTasks, taskView]);

//   const handleSearchChange = (e) => setSearch(e.target.value);

//   const handleTaskTypeChange = (e) => setTaskType(e.target.value);

//   const handleTillPlacementSearch = (e) => {
//     e.preventDefault();
//     dispatch(TillPayment(search));
//   }; 

//   const handleSelectTask = (taskId) => {
//     setSelectedTasks((prevSelectedTasks) =>
//       prevSelectedTasks.includes(taskId)
//         ? prevSelectedTasks.filter((id) => id !== taskId)
//         : [...prevSelectedTasks, taskId]
//     );
//   };

//   const getTasksForView = () => {
//     if (taskView === 'perCall') {
//       return PerPaymentTasks;
//     }
//     if (taskView === 'tillPlacement') {
//       return search
//         ? TillPaymentTasks.filter(task => task.candidateId?.phone.includes(search))
//         : TillPaymentTasks;
//     }
//     return [];
//   };

//   const filteredTasks = getTasksForView().filter(task =>
//     task.candidateId.phone.toLowerCase().includes(search.toLowerCase()) &&
//     (!taskType || task.taskType === taskType)
//   );

//   const totalPrice = filteredTasks.reduce((sum, task) => (
//     selectedTasks.includes(task._id) ? sum + task.pocPrice : sum
//   ), 0);

//   const tillPlacementPrice = TillPaymentTasks?.[0]?.pocId?.tillPlacementInterview || 0;
//   const manualAmount = parseFloat(manualTillPlacementAmount) || 0;
//   const totalTillPlacementPrice = tillPlacementPrice + totalPrice;
//   const remainingAmount = TillPaymentTasks[0]?.candidateId?.TillCandidateRemainingAmountToPay || 0;

//   const handlePerCallView = () => {
//     setTaskView('perCall');
//     setSearch('');
//   };

//   const openPaymentDialog = () => {
//     setOpenDialog(true);
//   };

//   const closePaymentDialog = () => {
//     setOpenDialog(false);
//   };

//   const makePayment = () => {
//     const amount = taskView === 'tillPlacement' ? manualAmount : totalPrice;
//     payment({ amount, tasksIds: selectedTasks },navigate,dispatch);
//     closePaymentDialog();
//   };

//   const handleManualAmountChange = (e) => {
//     setManualTillPlacementAmount(e.target.value);
//   };

//   const columns = [
    
//    {
//       field: 'select',
//       headerName: 'Select',
//       width: 100,
//       renderCell: (params) => (
//        <Checkbox
//           checked={selectedTasks.includes(params.row.id)}
//           onChange={() => handleSelectTask(params.row.id)}
//           disabled={taskView === 'tillPlacement'}
//         />
//       )
//     },
//     {
//       field: 'ISTTIME',
//       headerName: (
//         <>
//           IST TIME &nbsp; <img src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/255px-Flag_of_India.svg.png" width="25px" style={{ top: "5px" }} alt="BHARAT FLAG" />
//         </>
//       ),
//       width: 150
//     },
//     { field: 'candidateName', headerName: 'Candidate Name', flex: 1 },
//     { field: 'candidateType', headerName: 'Candidate Type', flex: 1 },
//     {
//       field: 'taskType',
//       headerName: 'Task Type',
//       flex: 1,
//       renderHeader: () => (
//         <FormControl variant="outlined" sx={{ width: '100%' }}>
//           <Select
//             value={taskType}
//             onChange={handleTaskTypeChange}
//             displayEmpty
//           >
//             <MenuItem value="">{taskView === "perCall" ? "ALL" : "---Select---"}</MenuItem>
//             {taskTypes.map((type) => (
//               <MenuItem key={type.value} value={type.value}>
//                 {type.label}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//       )
//     },
  
//     { field: 'taskDuration', headerName: 'DURATION', flex: 1 },
//     { field: 'paymentStatus', headerName: 'Payment Status', flex: 1 },
//     { field: 'status', headerName: 'Task Status', flex: 1 },
//     { field: 'pocPrice', headerName: 'Task Price', flex: 1 }
//   ].filter(Boolean); 

//   const rows = filteredTasks.map(task => ({
//     id: task._id,
//     candidateName: task.candidateName,
//     ISTTIME: moment(task.ISTTIME).format('D/M/YYYY hh:mm A'),
    
//     candidateType: task.candidateType,
//     taskType: task.taskType,
//     taskDuration: `${task.hour}:${task.minute}`,
//     date: new Date(task.createdAt).toLocaleDateString(),
//     paymentStatus: task.PaymentStatus,
//     status: task.status,
//     pocPrice: task.pocPrice
//   }));

//   return (
//     <>
//       <Grid container>
//         <Grid item xs={0.1}>
//         </Grid>
//         <Grid item xs={11.9}>
//           <Box sx={{ p: 2 }}>
//             <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 3 }}>
//               <Fab
//                 variant="extended"
//                 color={taskView === 'perCall' ? 'warning' : 'default'}
//                 onClick={handlePerCallView}
//               >
//                 <Phone /> PER CALL
//               </Fab>
//               <Fab
//               disabled
//                 variant="extended"
//                 color={taskView === 'tillPlacement' ? 'warning' : 'default'}
//                 onClick={() => setTaskView('tillPlacement')}
//               >
//                 <AssignmentTurnedIn /> TILL PLACEMENT
//               </Fab>
//             </Box>
            
//             {/* pay button and total */}
//             <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
//               {taskView === 'perCall' ? (
//                 <>
//                   <Typography variant="h6">Total Price: ₹{totalPrice}</Typography>
//                   <Button sx={{ml:2}} variant="contained" onClick={makePayment} color="secondary" disabled={totalPrice === 0}>
//                     Proceed to Pay
//                   </Button>
//                 </>
//               ) : (
//                 <>
//                   <div>
//                     <Typography variant="h6">Till Placement Price: ₹{tillPlacementPrice} + Task Price: ₹{totalPrice}</Typography>
//                     <Typography variant="h6">Total Till Placement: ₹{totalTillPlacementPrice}</Typography>
//                     <Typography variant="h6">Remaining To Pay: ₹{remainingAmount}</Typography>
//                   </div>
//                   <Button variant="contained" onClick={openPaymentDialog} color="primary" disabled={totalTillPlacementPrice === 0}>
//                     Proceed to Pay
//                   </Button>
//                 </>
//               )}
//             </Box>

//             {taskView === 'tillPlacement' && (
//               <Box sx={{ mt: 2,ml: 30,mr: 50  }}>
//                 <Grid container spacing={2} alignItems="center">
//                   <Grid item sx={{ mt: 2,}} xs={8}>
//                     <TextField
//                       fullWidth
//                       label="Search by Phone Number"
//                       variant="outlined"
//                       value={search}
//                       onChange={handleSearchChange}
//                     />
//                   </Grid>
//                   <Grid item xs={4}>
//                     <Button
//                       fullWidth
//                       variant="contained"
//                       color="secondary"
//                       onClick={handleTillPlacementSearch}
//                     >
//                       Search
//                     </Button>
//                   </Grid>
//                 </Grid>
//               </Box>
//             )}

//             <Box
//               m="40px 0 0 0"
//               height="60vh"
//               // sx={{
//               //   "& .MuiDataGrid-root": {
//               //     border: "none",
//               //   },
//               //   "& .MuiDataGrid-cell": {
//               //     borderBottom: "none",
//               //     color: colors.grey[100],
//               //   },
//               //   "& .name-column--cell": {
//               //     color: colors.greenAccent[300],
//               //   },
//               //   "& .MuiDataGrid-columnHeaders": {
//               //     bgcolor: colors.primary[700],
//               //     borderBottom: "none",
//               //     color: colors.greenAccent[500],
//               //   },
//               //   "& .MuiDataGrid-virtualScroller": {
//               //     backgroundColor: colors.primary[400],
//               //   },
//               //   "& .MuiDataGrid-footerContainer": {
//               //     borderTop: "none",
//               //     backgroundColor: colors.blueAccent[700],
//               //   },
//               //   "& .MuiCheckbox-root": {
//               //     color: `${colors.greenAccent[200]} !important`,
//               //   },
//               //   "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
//               //     color: `${colors.grey[100]} !important`,
//               //   },
//               //   [theme.breakpoints.down('md')]: {
//               //     "& .MuiDataGrid-columnHeaders": {
//               //       fontSize: '0.8rem',
//               //     },
//               //     "& .MuiDataGrid-cell": {
//               //       fontSize: '0.75rem',
//               //     },
//               //   },
//               // }}

//               sx={{
//               ".MuiDataGrid-root": {
//                 bgcolor: colors.primary[400],
//               },
//               ".MuiDataGrid-cell": {
//                 color: colors.grey[100],
//               },
//               ".MuiDataGrid-columnHeader": {
//                 bgcolor: colors.primary[650],
//                 color: colors.greenAccent[500],
//               },
//               ".MuiCheckbox-root": {
//                 color: `${colors.greenAccent[200]} !important`
//               },
//               "& .Mui-disabled": {
//                 pointerEvents: "none",
//                 opacity: 0.6,
//               },
//             }}
//             >
//               <DataGrid
//                 rows={rows}
//                 columns={columns}
//                 pageSize={10}
//                 rowsPerPageOptions={[10]}
//                 disableSelectionOnClick={true}
//                 autoHeight
//               />
//             </Box>
//           </Box>
//         </Grid>
//       </Grid>

//       <Dialog open={openDialog} onClose={closePaymentDialog}>
//         <DialogTitle>Enter Manual Till Placement Amount</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             Please enter the amount you wish to pay manually for the till placement payment.
//           </DialogContentText>
//           <TextField
//             margin="dense"
//             label="Manual Till Placement Amount"
//             type="number"
//             fullWidth
//             variant="outlined"
//             value={manualTillPlacementAmount}
//             onChange={handleManualAmountChange}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={closePaymentDialog} color="secondary">
//             Cancel
//           </Button>
//           <Button onClick={makePayment} color="primary">
//             Proceed to Pay
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// };

// export default App; 




import React, { useEffect, useState } from 'react';
import {
  Box, TextField, MenuItem, Select, FormControl,
  Checkbox, Button, Typography, Grid, Fab, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle, useTheme
} from '@mui/material';
import { Phone, AssignmentTurnedIn } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PerPayment, TillPayment } from '../../actions/POC';
import payment from "./RazorPay";
import { DataGrid, GridToolbarExport } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const taskTypes = [
  { label: 'INTERVIEW', value: 'interview' },
  { label: 'ASSESSMENT', value: 'assessment' },
  { label: 'JD SESSION', value: 'jdSession' },
  { label: 'RU SESSION', value: 'ruSession' },
  { label: 'RESUME BUILDING', value: 'resumeBuilding' },
  { label: 'TRAINING', value: 'training' },
];

const App = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mobile = queryParams.get('mobile');
  const [search, setSearch] = useState(mobile || '');
  const [taskType, setTaskType] = useState('');
  const [tag, setTag] = useState('');
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [taskView, setTaskView] = useState('perCall');
  const [manualTillPlacementAmount, setManualTillPlacementAmount] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const { TillPaymentTasks, PerPaymentTasks } = useSelector((state) => state.poc);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const uniqueTags = Array.from(new Set([...PerPaymentTasks].map(task => task.candidateId.subPoc ? task.candidateId.subPoc : "-")));

  useEffect(() => {
    dispatch(PerPayment());
  }, [dispatch]);

  useEffect(() => { 
    if (mobile) {
      dispatch(TillPayment(mobile));
    }
  }, [dispatch, mobile]);

  useEffect(() => {
    if (taskView === 'tillPlacement') {
      setSelectedTasks(TillPaymentTasks.map(task => task._id));
    }
  }, [TillPaymentTasks, taskView]);

  const handleSearchChange = (e) => setSearch(e.target.value);

  const handleTaskTypeChange = (e) => setTaskType(e.target.value);
  const handleTagChange = (e) => setTag(e.target.value);

  const handleTillPlacementSearch = (e) => {
    e.preventDefault();
    dispatch(TillPayment(search));
  }; 

  const handleSelectTask = (taskId) => {
    setSelectedTasks((prevSelectedTasks) =>
      prevSelectedTasks.includes(taskId)
        ? prevSelectedTasks.filter((id) => id !== taskId)
        : [...prevSelectedTasks, taskId]
    );
  };

  const getTasksForView = () => {
    if (taskView === 'perCall') {
      return PerPaymentTasks;
    }
    if (taskView === 'tillPlacement') {
      return search
        ? TillPaymentTasks.filter(task => task.candidateId?.phone.includes(search))
        : TillPaymentTasks;
    }
    return [];
  };

  const filteredTasks = getTasksForView().filter(task =>
    task.candidateId.phone.toLowerCase().includes(search.toLowerCase()) &&
    (!taskType || task.taskType === taskType) &&
    (!tag || task.candidateId.subPoc === tag)
  );

  const totalPrice = filteredTasks.reduce((sum, task) => (
    selectedTasks.includes(task._id) ? sum + task.pocPrice : sum
  ), 0);

  const tillPlacementPrice = TillPaymentTasks?.[0]?.pocId?.tillPlacementInterview || 0;
  const manualAmount = parseFloat(manualTillPlacementAmount) || 0;
  const totalTillPlacementPrice = tillPlacementPrice + totalPrice;
  const remainingAmount = TillPaymentTasks[0]?.candidateId?.TillCandidateRemainingAmountToPay || 0;

  const handlePerCallView = () => {
    setTaskView('perCall');
    setSearch('');
  };

  const openPaymentDialog = () => {
    setOpenDialog(true);
  };

  const closePaymentDialog = () => {
    setOpenDialog(false);
  };

  const makePayment = () => {
    const amount = taskView === 'tillPlacement' ? manualAmount : totalPrice;
    payment({ amount, tasksIds: selectedTasks }, navigate, dispatch);
    closePaymentDialog();
  };

  const handleManualAmountChange = (e) => {
    setManualTillPlacementAmount(e.target.value);
  };

  const handleExportExcel = () => {
    const ws = XLSX.utils.json_to_sheet(rows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Tasks");
    XLSX.writeFile(wb, "tasks.xlsx");
  };

  const handleExportPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [['IST TIME', 'Candidate Name', 'Candidate Type', 'Task Type', 'Tag', 'DURATION', 'Payment Status', 'Task Status', 'Task Price']],
      body: rows.map(row => [
        row.ISTTIME, row.candidateName, row.candidateType, row.taskType, row.tag,
        row.taskDuration, row.paymentStatus, row.status, row.pocPrice
      ])
    });
    doc.save('tasks.pdf');
  };

  const handleExportCSV = () => {
    const csvContent = [
      ['IST TIME', 'Candidate Name', 'Candidate Type', 'Task Type', 'Tag', 'DURATION', 'Payment Status', 'Task Status', 'Task Price'],
      ...rows.map(row => [
        row.ISTTIME, row.candidateName, row.candidateType, row.taskType, row.tag,
        row.taskDuration, row.paymentStatus, row.status, row.pocPrice
      ])
    ].map(e => e.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "tasks.csv");
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const columns = [
    {
      field: 'select',
      headerName: 'Select',
      width: 100,
      renderCell: (params) => (
        <Checkbox
          checked={selectedTasks.includes(params.row.id)}
          onChange={() => handleSelectTask(params.row.id)}
          disabled={taskView === 'tillPlacement'}
        />
      )
    },
    {
      field: 'ISTTIME',
      headerName: (
        <>
          IST TIME &nbsp; <img src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/255px-Flag_of_India.svg.png" width="25px" style={{ top: "5px" }} alt="BHARAT FLAG" />
        </>
      ),
      width: 150
    },
    { field: 'candidateName', headerName: 'Candidate Name', flex: 1 },
    { field: 'candidateType', headerName: 'Candidate Type', flex: 1 },
    {
      field: 'taskType',
      headerName: 'Task Type',
      flex: 1,
      renderHeader: () => (
        <FormControl variant="outlined" sx={{ width: '100%' }}>
          <Select
            value={taskType}
            onChange={handleTaskTypeChange}
            displayEmpty
          >
            <MenuItem value="">{taskView === "perCall" ? "ALL" : "---Select---"}</MenuItem>
            {taskTypes.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    },
    {
      field: 'tag',
      headerName: 'Tag',
      flex: 1,
      renderHeader: () => (
        <FormControl variant="outlined" sx={{ width: '100%' }}>
          <Select
            value={tag}
            onChange={handleTagChange}
            displayEmpty
          >
            <MenuItem value="">{taskView === "perCall" ? "ALL" : "---Select---"}</MenuItem>
            {uniqueTags.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    },
    { field: 'taskDuration', headerName: 'DURATION', flex: 1 },
    { field: 'paymentStatus', headerName: 'Payment Status', flex: 1 },
    { field: 'status', headerName: 'Task Status', flex: 1 },
    { field: 'pocPrice', headerName: 'Task Price', flex: 1 }
  ];

  const rows = filteredTasks.map(task => ({
    id: task._id,
    candidateName: task.candidateName,
    ISTTIME: moment(task.ISTTIME).format('D/M/YYYY hh:mm A'),
    candidateType: task.candidateType,
    taskType: task.taskType,
    tag: task.candidateId.subPoc ? task.candidateId.subPoc : "-",
    taskDuration: `${task.hour}:${task.minute}`,
    date: new Date(task.createdAt).toLocaleDateString(),
    paymentStatus: task.PaymentStatus,
    status: task.status,
    pocPrice: task.pocPrice
  }));

  return (
    <>
      <Grid container>
        <Grid item xs={0.1}>
        </Grid>
        <Grid item xs={11.9}>
          <Box sx={{ p: 2 }}>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 3 }}>
              <Fab
                variant="extended"
                color={taskView === 'perCall' ? 'warning' : 'default'}
                onClick={handlePerCallView}
              >
                <Phone /> PER CALL
              </Fab>
              <Fab
                disabled
                variant="extended"
                color={taskView === 'tillPlacement' ? 'warning' : 'default'}
                onClick={() => setTaskView('tillPlacement')}
              >
                <AssignmentTurnedIn /> TILL PLACEMENT
              </Fab>
            </Box>
            
            {/* pay button and total */}
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Button sx={{ ml: 2 }} variant="contained" onClick={handleExportExcel} color="secondary">
                Export to Excel
              </Button>
              <Button sx={{ ml: 2 }} variant="contained" onClick={handleExportPDF} color="error">
                Export to PDF
              </Button>
              <Button sx={{ ml: 2,mr: 5 }} variant="contained" onClick={handleExportCSV} color="warning">
                Export to CSV
              </Button>
              
              {taskView === 'perCall' ? (
                <>
                  <Typography variant="h6">Total Price: ₹{totalPrice}</Typography>
                  <Button sx={{ ml: 2 }} variant="contained" onClick={makePayment} color="secondary" disabled={totalPrice === 0}>
                    Proceed to Pay
                  </Button>
                </>
              ) : (
                <>
                  <div>
                    <Typography variant="h6">Till Placement Price: ₹{tillPlacementPrice} + Task Price: ₹{totalPrice}</Typography>
                    <Typography variant="h6">Total Till Placement: ₹{totalTillPlacementPrice}</Typography>
                    <Typography variant="h6">Remaining To Pay: ₹{remainingAmount}</Typography>
                  </div>
                  <Button variant="contained" onClick={openPaymentDialog} color="primary" disabled={totalTillPlacementPrice === 0}>
                    Proceed to Pay
                  </Button>
                </>
              )}
              
            </Box>

            {taskView === 'tillPlacement' && (
              <Box sx={{ mt: 2, ml: 30, mr: 50 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item sx={{ mt: 2 }} xs={8}>
                    <TextField
                      fullWidth
                      label="Search by Phone Number"
                      variant="outlined"
                      value={search}
                      onChange={handleSearchChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={handleTillPlacementSearch}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}

            <Box
              m="40px 0 0 0"
              height="60vh"
              sx={{
                ".MuiDataGrid-root": {
                  bgcolor: colors.primary[400],
                },
                ".MuiDataGrid-cell": {
                  color: colors.grey[100],
                },
                ".MuiDataGrid-columnHeader": {
                  bgcolor: colors.primary[650],
                  color: colors.greenAccent[500],
                },
                ".MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`
                },
                "& .Mui-disabled": {
                  pointerEvents: "none",
                  opacity: 0.6,
                },
              }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableSelectionOnClick={true}
                autoHeight
                components={{
                  Toolbar: GridToolbarExport,
                }}
                id="taskTable"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Dialog open={openDialog} onClose={closePaymentDialog}>
        <DialogTitle>Enter Manual Till Placement Amount</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the amount you wish to pay manually for the till placement payment.
          </DialogContentText>
          <TextField
            margin="dense"
            label="Manual Till Placement Amount"
            type="number"
            fullWidth
            variant="outlined"
            value={manualTillPlacementAmount}
            onChange={handleManualAmountChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closePaymentDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={makePayment} color="primary">
            Proceed to Pay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default App;
