import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Box,
  useTheme,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import { tokens } from '../../theme';

const EditCandidate = ({ open, handleClose, candidate, handleEditChange, handleEditSubmit }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const technologyOptions = [
    "Business Analyst",
    "Financial Analyst",
    "Project Manager",
    "Scrum Master",
    "Data Analyst",
    "Data Engineer",
    "Data Scientists",
    "Java Developer",
    "Java Full Stack Developer",
    "Software Engineer",
    "Python Developer",
    "Front-End Developer",
    "Backend Developer",
    "Cyber Security Analyst",
    "Network Engineer",
    "ASP .NET Developer",
    "Others"
  ];

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ backgroundColor: colors.primary[600], color: colors.grey[100] }}>
        Edit Candidate Details
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: colors.primary[700], color: colors.grey[100] }}>
        <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 2 }}>
          <TextField
            label="Name"
            name="name"
            value={candidate.name || ''}
            onChange={handleEditChange}
            fullWidth
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': {
                color: colors.greenAccent[500],
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: colors.greenAccent[500],
              },
              '& .MuiOutlinedInput-root': {
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.greenAccent[500],
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.greenAccent[500],
                },
                input: { color: colors.grey[100] },
              },
              backgroundColor: colors.primary[600],
            }}
          />
          <TextField
            label="Email"
            name="email"
            value={candidate.email || ''}
            onChange={handleEditChange}
            fullWidth
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': {
                color: colors.greenAccent[500],
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: colors.greenAccent[500],
              },
              '& .MuiOutlinedInput-root': {
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.greenAccent[500],
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.greenAccent[500],
                },
                input: { color: colors.grey[100] },
              },
              backgroundColor: colors.primary[600],
            }}
          />
          <TextField
            label="Phone"
            name="phone"
            value={candidate.phone || ''}
            onChange={handleEditChange}
            fullWidth
            variant="outlined"
            sx={{
              '& .MuiInputLabel-root': {
                color: colors.greenAccent[500],
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: colors.greenAccent[500],
              },
              '& .MuiOutlinedInput-root': {
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.greenAccent[500],
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.greenAccent[500],
                },
                input: { color: colors.grey[100] },
              },
              backgroundColor: colors.primary[600],
            }}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel
              id="technology-label"
              sx={{
                color: colors.greenAccent[500],
                '&.Mui-focused': {
                  color: colors.greenAccent[500],
                },
              }}
            >
              Technology
            </InputLabel>
            <Select
              labelId="technology-label"
              id="technology"
              required
              name="technology"
              value={candidate.technology || ''}
              onChange={handleEditChange}
              sx={{
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.greenAccent[500],
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.greenAccent[500],
                },
                backgroundColor: colors.primary[600],
                color: colors.grey[100],
              }}
            >
              {technologyOptions.map((tech, index) => (
                <MenuItem key={index} value={tech}>{tech}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            fullWidth
            name="cv"
            type="file"
            variant="outlined"
            onChange={handleEditChange}
            sx={{
              '& .MuiOutlinedInput-root': {
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.greenAccent[500],
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.greenAccent[500],
                },
                input: { color: colors.grey[100] },
              },
              backgroundColor: colors.primary[600],
            }}
            InputLabelProps={{
              shrink: true,
              sx: {
                color: colors.greenAccent[500],
                '&.Mui-focused': {
                  color: colors.greenAccent[500],
                },
              },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: colors.primary[700], color: colors.grey[100] }}>
        <Button onClick={handleClose} sx={{ color: colors.grey[100] }}>
          Cancel
        </Button>
        <Button type="submit" onClick={handleEditSubmit} sx={{ color: colors.greenAccent[500], borderColor: colors.greenAccent[500] }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCandidate;
