import axios from 'axios';
import toast from 'react-hot-toast';
import {requestStart, requestFailure,addCandidateSuccess,addTaskSuccess,addSubPocSuccess,getAllCandidateSuccess,getAllTaskSuccess, getPerPaymentTasksSuccess, getTillPaymentTasksSuccess} from '../reducers/POC';


const getAuthHeaders = () => {
  const token =localStorage.getItem("admin");
  return {
    headers: {
      "Content-Type": "application/json",
      "Authorization": token,
    },
    withCredentials: true,
  }; 
};
const getFileAuthHeaders = () => {
  const token =localStorage.getItem("admin");
  return {
    headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": token,
    },
    withCredentials: true,
  };
};

export const addCandidate = (candidateData) => async (dispatch) => {
  try {
    dispatch(requestStart());
    const { data } = await axios.post(`/api/v1/poc/addCandidate`, candidateData, getFileAuthHeaders());
    dispatch(addCandidateSuccess(data.candidate));
    toast.success('Candidate added ');
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
  }
};

export const getAllCandidate = () => async (dispatch) => {
  try {
    dispatch(requestStart());
    const { data } = await axios.get(`/api/v1/poc/allCandidate`,  getAuthHeaders());
    dispatch(getAllCandidateSuccess(data.candidate));
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
  }
};

export const addTask = (taskData) => async (dispatch) => {
  try {
    dispatch(requestStart());
    const { data } = await axios.post(`/api/v1/poc/addTask`, taskData, getFileAuthHeaders());
    dispatch(addTaskSuccess(data.task));
    toast.success('Task added ');
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
  }
};
export const addSubPoc = ({taskId,subPoc}) => async (dispatch) => {
  try {
    dispatch(requestStart());
    const { data } = await axios.post(`/api/v1/poc/addSubPoc`, {taskId,subPoc}, getAuthHeaders());
    dispatch(addSubPocSuccess(data.task))
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
  } 
};

export const getAllTask = () => async (dispatch) => {
  try {
    dispatch(requestStart());
    const { data } = await axios.get(`/api/v1/poc/dashboard`, getAuthHeaders());
    dispatch(getAllTaskSuccess(data.tasks));
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
  }
};
export const PerPayment = () => async (dispatch) => {
  try {
    dispatch(requestStart());
    const { data } = await axios.get(`/api/v1/poc/perpayment`, getAuthHeaders());
    console.log(data)
    dispatch(getPerPaymentTasksSuccess(data.tasks));
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
  }
};
export const TillPayment = (phone) => async (dispatch) => {
  try {
    dispatch(requestStart());
    const { data } = await axios.post(`/api/v1/poc/geTcComTasks`,{phone},getAuthHeaders());
    dispatch(getTillPaymentTasksSuccess(data.tasks));
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
  }
};


export const updateCandidate = (candidateId, formData) => async (dispatch) => {
  try {
    console.log("formData",formData);
    console.log("candidateId",candidateId);
    dispatch(requestStart());
    const { data } = await axios.put(`/api/v1/poc/updateCandidate/${candidateId}`, formData, getFileAuthHeaders());
    console.log(data);
    toast.success('Candidate updated successfully!');
    return { success: true };
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
    return { success: false, message: error.response.data.message };
  }
};