import React from 'react';
import { FidgetSpinner, Vortex } from 'react-loader-spinner';

const Loading = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh",backgroundColor:"#14263D" }}>
      <div>
        <FidgetSpinner
          type="TailSpin"
          color="#00BFGG"
          height={700}
          width={200}
        />
        <Vortex
          visible={true}
          height={700}
          width={200}
          ariaLabel="vortex-loading"
          wrapperStyle={{}}
          wrapperClass="vortex-wrapper"
          colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
        />
      </div>
    </div>
  );
};

export default Loading;
