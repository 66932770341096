import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: null, 
  companies: [],
  poc:[],
  pocSingle: [],
  candidates: [],
  experts: [],
  tasks: [],
  PAtasks: [],
  feedback: [], 
};
const adminSlice = createSlice({
  name: 'admin',
  initialState, 
  reducers: {
    requestStart: (state) => {
      state.isLoading = false;
      state.error = null;
    }, 
    requestFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    clearError: (state) => {
        state.error = null;
      },
    addCompanySuccess: (state, action) => {
      state.isLoading = false;
      state.companies.push(action.payload);
    }, 
    getAllCompanySuccess: (state, action) => {
      state.isLoading = false;
      state.companies=action.payload;
    },
    addPocSuccess: (state, action) => {
      state.isLoading = false;
      state.poc.poc.push(action.payload.p);
    },
    getPocSuccess: (state, action) => {
      state.isLoading = false;
      state.pocSingle.push(action.payload);
    },
    getAllPocSuccess: (state, action) => {
      state.isLoading = false;
      state.poc=action.payload;
    },
    addCandidateSuccess: (state, action) => {
      state.isLoading = false;
      state.candidates.push(action.payload);
    },
    getAllCandidateSuccess: (state, action) => {
      state.isLoading = false;
      state.candidates=action.payload;
    },
    addExpertSuccess: (state, action) => {
      state.isLoading = false;
      state.experts.experts.push(action.payload); 
    },
    getExpertSuccess: (state, action) => {
      state.isLoading = false;
      state.pocSingle.push(action.payload);
    },
    getAllExpertSuccess: (state, action) => {
      state.isLoading = false;
      state.experts=action.payload;
    },
    addTaskSuccess: (state, action) => {
      state.isLoading = false;
      state.tasks.push(action.payload);
      state.PAtasks.push(action.payload);
    },
 
    assignTaskToExpertSuccess: (state, action) => {
      state.isLoading = false;
      state.tasks.push(action.payload);
    }, 

    fetchAllTasksSuccess: (state, action) => {
      state.isLoading = false;
      state.tasks = action.payload.tasks;
      state.PAtasks = action.payload.PAtasks;
    },
    fetchAllCompanyTasksSuccess: (state, action) => {
      state.isLoading = false;
      state.tasks = action.payload;
    },
    fetchTaskSuccess: (state, action) => {
      state.isLoading = false;
      state.tasks = state.tasks.map(task => 
        task.id === action.payload.id ? action.payload : task
      );
    },
    giveFeedbackSuccess: (state, action) => {
      state.isLoading = false;
      state.feedback.push(action.payload);
    },
    clearAdmin: (state) => {
  state.isLoading = false;
  state.error = null;
  state.companies = [];
  state.poc = [];
  state.pocSingle = [];
  state.candidates = [];
  state.experts = [];
  state.tasks = [];
  state.PAtasks = [];
  state.feedback = [];
},
  },
});

export const {
  requestStart, requestFailure,clearError,
  addCompanySuccess,getAllCompanySuccess,addPocSuccess,getPocSuccess,getAllPocSuccess,addCandidateSuccess,getAllCandidateSuccess,addExpertSuccess,getExpertSuccess,getAllExpertSuccess,
  addTaskSuccess,  assignTaskToExpertSuccess,fetchAllTasksSuccess,fetchAllCompanyTasksSuccess,
  fetchTaskSuccess, giveFeedbackSuccess,clearAdmin
} = adminSlice.actions;

export default adminSlice.reducer;
