import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  Typography,
  Box,
  Grid,
  Button,
  useTheme,
  Fab
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { tokens } from '../../theme';
import { useDispatch } from 'react-redux';
import { updateExpertStatus } from '../../actions/Admin';
import toast from 'react-hot-toast';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const ExpertProfile = () => {
  const [expertSingle, setSingleExpert] = useState({});
  const [selectedTasks, setSelectedTasks] = useState([]);
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();

  const getExpert = useCallback(async () => {
    try {
      const token = localStorage.getItem('admin');
      const { data } = await axios.get(`/api/v1/admin/getExpert/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      });
      setSingleExpert(data);
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  useEffect(() => {
    getExpert();
  }, [getExpert]);

  const handleSelectTask = (taskId) => {
    setSelectedTasks((prevSelectedTasks) =>
      prevSelectedTasks.includes(taskId)
        ? prevSelectedTasks.filter((id) => id !== taskId)
        : [...prevSelectedTasks, taskId]
    );
  };

  const calculateTotalAmount = () => {
    if (!expertSingle.eTasks) return 0;
    return expertSingle.eTasks
      .filter((task) => selectedTasks.includes(task._id))
      .reduce((total, task) => total + (task.expertPrice || 0), 0);
  };

  const handleUpdatePaymentStatus = async (status) => {
    if (selectedTasks.length === 0) {
      toast.error('Please select tasks');
      return;
    }
    const totalAmount = calculateTotalAmount();
    const result = await dispatch(updateExpertStatus(id, { taskIds: selectedTasks, status, amount: totalAmount }));
    if (result.success) {
      toast.success('Payment status updated successfully!');
      getExpert();
      setSelectedTasks([]);
    } else {
      toast.error(result.message);
    }
  };

  const { expert, eTasks, totalPendingPayment, totalReceivedPayment } = expertSingle;

  const columns = [
    {
      field: 'select',
      headerName: 'SELECT',
      renderCell: (params) => (
        <Checkbox
          checked={selectedTasks.includes(params.row.id)}
          onChange={() => handleSelectTask(params.row.id)}
          disabled={params.row.ExpertPaymentStatus === 'Unpaid' || params.row.ExpertPaymentStatus === 'Completed'}
        />
      )
    },
    {
      field: 'estTime',
      headerName: (
        <>
          EST TIME &nbsp; <img src="https://cdn.britannica.com/33/4833-004-828A9A84/Flag-United-States-of-America.jpg" width="25px" style={{ top: "5px" }} alt="US FLAG" />
        </>
      ),
    flex:0.5
    },
    { field: 'candidate', headerName: 'CANDIDATE NAME',flex:0.5, renderCell: (params) => (
      <Box>
        <Typography>{params.row.candidateName}</Typography>
        <Typography>{params.row.candidateType}</Typography>
      </Box>
    ) },
    { field: 'taskType', headerName: 'TASK TYPE',flex:0.5 },
    { field: 'technology', headerName: 'TECHNOLOGY',flex:0.5 },
    {
      field: 'PaymentStatus',
      headerName: 'TASK PAYMENT',
     flex:0.5,
      renderCell: (params) => (
        params.value === "Completed" ? <TaskAltIcon color="success" /> :
          params.value === "Unpaid" ? <CancelIcon color="error" /> :
            params.value === "Pending" ? <HourglassDisabledIcon color="action" /> : params.value
      )
    },
    {
      field: 'ExpertPaymentStatus',
      headerName: 'PAYOUT',
     flex:0.5,
      renderCell: (params) => (
        params.value === "Completed" ? <TaskAltIcon color="success" /> :
          params.value === "Unpaid" ? <CancelIcon color="error" /> :
            params.value === "Pending" ? <HourglassDisabledIcon color="action" /> : params.value
      )
    },
    { field: 'expertPrice', headerName: 'TASK PRICE'},
    {
      field: 'status',
      headerName: 'STATUS',
      flex: 1,
      renderCell: (params) => (
        <Fab variant="extended" style={{ color: 'black', backgroundColor: getStatusColor(params.value), height: '1.5rem', width: '7rem' }}>
          <strong>{params.value}</strong>
        </Fab>
      ),
    },
  ];

  const rows = eTasks ? eTasks.map((task) => ({
    id: task._id,
    estTime: moment(task.ESTTIME).format('D/M/YYYY hh:mm A'),
    candidateName: task.candidateName,
    candidateType: task.candidateType,
    taskType: task.taskType,
    technology: task.candidateId ? task.candidateId.technology : "",
    PaymentStatus: task.PaymentStatus,
    ExpertPaymentStatus: task.ExpertPaymentStatus,
    ISTTIME: moment(task.ISTTIME).format('D/M/YYYY hh:mm A'),
    status: task.status,
    expertPrice: task.expertPrice,
  })) : [];

  const totalSelectedAmount = calculateTotalAmount();
  const getStatusColor = (status) => {
    switch (status) {
      case 'Pending':
        return '#FFD700';
      case 'Assigned':
        return '#1E90FF';
      case 'Reschedule':
        return '#FFA500';
      case 'Complete':
        return '#008000';
      case 'Cancelled':
        return '#FF0000';
      default:
        return '#000000';
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.text('Tekiezz It Services', 10, 10);
    doc.text('Date: ' + moment().format('DD/MM/YYYY'), 10, 30);

    const items = [
      ['Candidate', 'Candidate Type', 'Tasks','Status' ,'Date', 'Price'],
      ...rows
        .filter((row) => selectedTasks.includes(row.id))
        .map((row) => [
          row.candidateName,
          row.candidateType,
          row.taskType,
          row.status,
          moment(row.estTime).format('D/M/YYYY'),
          `${row.expertPrice}`,
        ]),
    ];

    doc.autoTable({
      startY: 50,
      head: [items[0]],
      body: items.slice(1),
    });

    doc.text('Total:'+totalSelectedAmount, 140, doc.previousAutoTable.finalY + 10);

    const string = doc.output('datauristring');
    const newWindow = window.open();
    newWindow.document.write(`
      <html>
        <head>
          <title>Invoice Preview</title>
        </head>
        <body>
          <iframe src="${string}" width="100%"  height="800px"></iframe>
          <br />
          <button style="background-color: green;color:white;" id="downloadButton">Download PDF</button>
          <button style="background-color: red;color:white;" id="closeButton">Close</button>
        </body>
      </html>
    `);
    newWindow.document.getElementById('downloadButton').addEventListener('click', () => {
      doc.save('invoice.pdf');
    });

    newWindow.document.getElementById('closeButton').addEventListener('click', () => {
      newWindow.close();
    });
  };

  return (
    <>
      <Typography variant="h4" align="center" gutterBottom sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } }}>
        {expert?.name}
      </Typography>
      <Grid container justifyContent="center" spacing={2} style={{ marginTop: '20px',marginLeft:"10rem" }}>
        <Grid item xs={12} sm={6} md={3} style={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            width="100%"
            maxWidth="300px"
            boxShadow={5}
            borderRadius={5}
            bgcolor={colors.blueAccent[800]}
            color="white"
            p={2}
            sx={{ mb: { xs: 2, sm: 0 } }}
          >
            <Typography variant="h6" align="center" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>Paid Amount</Typography>
            <Typography variant="h5" align="center" sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>{totalReceivedPayment}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3} style={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            width="100%"
            maxWidth="300px"
            boxShadow={5}
            borderRadius={5}
            bgcolor={colors.blueAccent[800]}
            color="error.contrastText"
            p={2}
            sx={{ mb: { xs: 2, sm: 0 } }}
          >
            <Typography variant="h6" align="center" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>Remaining Amount</Typography>
            <Typography variant="h5" align="center" sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>{totalPendingPayment}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="h6" align="center" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' }, mb: 2 }}>
            Selected Tasks Amount: ₹{totalSelectedAmount}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 2 }}>
          <Button variant="contained" color='secondary' onClick={generatePDF} sx={{ mb: { xs: 2, sm: 0 }, mr: { sm: 2 } }}>
            See Invoice Preview
          </Button>
            <Button variant="contained" color="secondary" onClick={() => handleUpdatePaymentStatus('Completed')} sx={{ mb: { xs: 2, sm: 0 }, mr: { sm: 2 } }}>
              Mark as Completed
            </Button>
            <Button variant="contained" color="warning" onClick={() => handleUpdatePaymentStatus('Pending')} sx={{ mb: { xs: 2, sm: 0 }, mr: { sm: 2 } }}>
              Mark as Pending
            </Button>
            <Button variant="contained" color="error" onClick={() => handleUpdatePaymentStatus('Unpaid')} sx={{ mb: { xs: 2, sm: 0 }, mr: { sm: 2 } }}>
              Mark as Unpaid
            </Button>
          
          </Box>
          {/* <Box sx={{ height: 600, width: '90%', minWidth: '80%', mt: 2,mr:2,marginLeft:"-2rem" }}> */}
          <Box sx={{ height: 550, width: '90%', minWidth: '93%', mt:2,mr:5, position:"relative"}}>
            <DataGrid
              rows={rows}
              columns={columns}
              checkboxSelection={false}
              getRowId={(row) => row.id}
              getRowClassName={(params) => selectedTasks.includes(params.id) ? 'highlight-row' : ''}
              sx={{
                "& .MuiDataGrid-root": { 
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                  color: colors.grey[100],
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                  color: colors.grey[100],
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
                [theme.breakpoints.down('md')]: {
                  "& .MuiDataGrid-columnHeaders": {
                    fontSize: '0.8rem',
                  },
                  "& .MuiDataGrid-cell": {
                    fontSize: '0.75rem',
                  },
                },
                '& .highlight-row': {
                  backgroundColor: 'green',
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ExpertProfile;
