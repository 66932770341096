// import React from 'react';
// import { Box, Typography } from '@material-ui/core';
// import { useLocation } from 'react-router-dom';

// const PaymentSuccess = () => {
//     const searchParams = new URLSearchParams(useLocation().search);
//     const referenceNum = searchParams.get("reference");

//     return (
//         <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
//             <Box textAlign="center">
//                 <Typography variant="h4" style={{ textTransform: 'uppercase' }}>
//                     Order Successful
//                 </Typography>
//                 <Typography variant="body1">
//                     Reference No. {referenceNum}
//                 </Typography>
//             </Box>
//         </Box>
//     );
// };
// export default PaymentSuccess;

import React from 'react';
import { Typography, Card, CardContent, Button, Container } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const PaymentSuccess = () => {
  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        bgcolor: '#009E5C',
        color: '#fff',
        textAlign: 'center',
        padding: 2,
      }}
    >
      <Typography variant="body1" sx={{ mb: 2 }}>
        You will be redirected in 6 seconds
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4 }}>
        Payment Successful
      </Typography>
      <CheckCircleIcon
        sx={{
          fontSize: 60,
          color: '#4caf50',
          bgcolor: '#ffffff',
          borderRadius: '50%',
          padding: '10px',
          mb: 4,
        }}
      />
      <Card
        sx={{
          width: '100%',
          borderRadius: 2,
          boxShadow: 3,
          bgcolor: '#fff',
          color: '#000',
        }}
      >
        <CardContent sx={{ padding: 3 }}>
          <Typography variant="h6" gutterBottom>
            Tekizz IT Services
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Aug 7, 2024, 2:21 PM
          </Typography>
          <Typography variant="h4" gutterBottom>
            ₹2,000
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            UPI: pay_OhvwL7u4DpPqPR
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <Button
              href="https://razorpay.com/support"
              target="_blank"
              sx={{ color: '#00bfa5', textDecoration: 'underline', padding: 0 }}
            >
              Visit razorpay.com/support for queries
            </Button>
          </Typography>
        </CardContent>
      </Card>
      <Typography variant="body2" sx={{ mt: 4 }}>
        Secured by <span style={{ fontWeight: 'bold' }}>Razorpay</span>
      </Typography>
    </Container>
  );
};

export default PaymentSuccess;
