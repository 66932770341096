import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


const InvoiceGenerator = () => {
  const generatePDF = () => {
    const doc = new jsPDF();
    doc.text('Tekiezz It Services', 140, 10);
    doc.text('Date: 09/07/2024', 10, 100);
    
    const items = [
      ['Candidate',"candidateType" ,'Tasks', 'Date', 'Price', 'Total'],
      ['rahul ', 'Till Candidate', 'Interview', "9/7/2024" ,'$10.00', '$30.00'],
      ['champak', 'Per Candidate', 'JD Session',"9/7/2024" ,'$15.00', '$30.00'],
      ['bhagat', 'Per Candidate', 'RU Session', "9/7/2024" ,'$7.00', '$30.00'],
    ];

    doc.autoTable({
      startY: 110,
      head: [items[0]],
      body: items.slice(1),
    });

 
    doc.text('Total: $90.00 Pn malse nai ', 140, doc.previousAutoTable.finalY + 10);

 
    const string = doc.output('datauristring');
    const newWindow = window.open();
    newWindow.document.write(`
      <html>
        <head>
          <title>Invoice Preview</title>
        </head>
        <body>
          <iframe src="${string}" width="50%" margineLeft="10rem" height="600px"></iframe>
          <br />
          <button id="downloadButton">Download PDF</button>
          <button id="closeButton">Close</button>
        </body>
      </html>
    `);
    newWindow.document.getElementById('downloadButton').addEventListener('click', () => {
      doc.save('invoice.pdf');
    });
    
    newWindow.document.getElementById('closeButton').addEventListener('click', () => {
      newWindow.close();
    });
  };

  return (
    <>
    <div style={{marginLeft:"20rem", padding: '20px' }}>
      <h1>Generate Invoice</h1>
      <button onClick={generatePDF}>See Invoice Preview</button>
    </div>
    </>
  );
};

export default InvoiceGenerator;