// import React, { useState, useEffect } from 'react';
// import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, FormControl, InputLabel, useTheme } from '@mui/material';
// import { useDispatch, useSelector } from 'react-redux';
// import toast from 'react-hot-toast';
// import { getAllExpert, getAllPoc, addTask, getPocCandidate, fetchAllTasks } from '../../actions/Admin';
// import { tokens } from '../../theme';
// import { Box } from '@mui/system';

// const AddTask = ({ open, handleClose }) => {
//   const [taskData, setTaskData] = useState({
//     candidateId: '',
//     pocId: '',
//     expertId: '',
//     taskType: '',
//     ISTTIME: '',
//     link: '',
//     hour: 0,
//     minute: 0,
//     cv: null,
//   });

//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [filteredCandidates, setFilteredCandidates] = useState([]);

//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const { experts, poc } = useSelector((state) => state.admin);
//   const dispatch = useDispatch();

//   const taskTypes = [
//     { label: 'INTERVIEW', value: 'interview' },
//     { label: 'ASSESSMENT', value: 'assessment' },
//     { label: 'JD SESSION', value: 'jdSession' },
//     { label: 'RU SESSION', value: 'ruSession' },
//     { label: 'RESUME BUILDING', value: 'resumeBuilding' },
//     { label: 'TRAINING', value: 'training' },
//   ];

//   const handleChange = (e) => {
//     const { name, value, files } = e.target;
//     if (name === 'cv') {
//       setTaskData((prevData) => ({
//         ...prevData,
//         [name]: files && files.length > 0 ? files[0] : null,
//       }));
//     } else {
//       setTaskData((prevData) => ({
//         ...prevData,
//         [name]: value,
//       }));
//     }
//   };

// const handleCandidateChange=(e)=>{

// };


//   const handlePocChange = async (e) => {
//     const { name, value } = e.target;
//     setTaskData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));

//     if (value) {
//       try {
//         const {candidates}=await dispatch(getPocCandidate(value))
//         setFilteredCandidates(candidates);
//       } catch (error) {
//         toast.error('Error fetching candidates');
//       }
//     } else {
//       setFilteredCandidates([]);
//     }
//   };

//   const handleSubmit = async () => {
//     setIsSubmitting(true);
//     const formDataToSend = new FormData();
//     Object.entries(taskData).forEach(([key, value]) => {
//       formDataToSend.append(key, value);
//     });

//     const result = await dispatch(addTask(formDataToSend));
//     setIsSubmitting(false);


//     if (result.success) {
//       toast.success('Task added successfully!');
//       dispatch(fetchAllTasks());
//       handleClose(); 
//     } else {
//       toast.error(result.message);
//     }
//   };

//   useEffect(() => {
//     dispatch(getAllPoc());
//     dispatch(getAllExpert());
//   }, [dispatch]);

//   return (
//     <Dialog open={open} onClose={handleClose}>
//       <DialogTitle>ADD NEW TASK</DialogTitle>
//       <DialogContent>
//         <FormControl fullWidth margin="dense">
//           <InputLabel>POC</InputLabel>
//           <Select
//             label="POC"
//             name="pocId"
//             value={taskData.pocId}
//             onChange={handlePocChange}
//           >
//             {poc.poc && poc.poc.map((p) => (
//               <MenuItem key={p._id} value={p._id}>
//                 {p.name}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//         {/* SEARCH CANDIDATE */}
//         <TextField
//         label="CANDIDATE"
//             name="candidateId"
//             value={taskData.candidateId}
//             onChange={handleChange}
//             disabled={!taskData.pocId}>
//             <InputLabel>CANDIDATE</InputLabel>
//             <Select
//             label="CANDIDATE"
//             name="candidateId"
//             value={taskData.candidateId}
//             onChange={handleCandidateChange}
//             disabled={!taskData.pocId}
//           >
//             {filteredCandidates.map((candidate) => (
//               <MenuItem key={candidate._id} value={candidate._id}>
//                 {candidate.name}
//               </MenuItem>
//             ))}
//           </Select>
//         </TextField>
//         {/* DROPDOWN BUTTON */}
//         <FormControl fullWidth margin="dense">
//           <InputLabel>CANDIDATE</InputLabel>

//           <Select
//             label="CANDIDATE"
//             name="candidateId"
//             value={taskData.candidateId}
//             onChange={handleChange}
//             disabled={!taskData.pocId}
//           >
//             {filteredCandidates.map((candidate) => (
//               <MenuItem key={candidate._id} value={candidate._id}>
//                 {candidate.name}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//         {/* <FormControl fullWidth margin="dense">
//           <InputLabel>CANDIDATE</InputLabel>
//           <Select
//             label="CANDIDATE"
//             name="candidateId"
//             value={taskData.candidateId}
//             onChange={handleChange}
//             disabled={!taskData.pocId}
//           >
//             {filteredCandidates.map((candidate) => (
//               <MenuItem key={candidate._id} value={candidate._id}>
//                 {candidate.name}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl> */}
//         <FormControl fullWidth margin="dense">
//           <InputLabel>EXPERT</InputLabel>
//           <Select
//   label="EXPERT"
//   name="expertId"
//   value={taskData.expertId ? taskData.expertId : ''}
//   onChange={handleChange}
// >
//   <MenuItem value="">
//     <em>None</em>
//   </MenuItem>
//   {experts.experts && experts.experts.map((expert) => (
//     <MenuItem key={expert._id} value={expert._id}>
//       {expert.name}
//     </MenuItem>
//   ))}
// </Select>
//         </FormControl>
//         <FormControl fullWidth margin="dense">
//           <InputLabel>TASK TYPE</InputLabel>
//           <Select
//             label="TASK TYPE"
//             name="taskType"
//             value={taskData.taskType}
//             onChange={handleChange}
//           >
//             {taskTypes.map((type) => (
//               <MenuItem key={type.value} value={type.value}>
//                 {type.label}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>

//         <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
//           <img src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/255px-Flag_of_India.svg.png" height="40rem" width="60px" alt="IND FLAG" />
//           <TextField
//             margin="dense"
//             name="ISTTIME"
//             label="IST TIME" 
//             type="datetime-local"
//             fullWidth
//             value={taskData.ISTTIME}
//             onChange={handleChange}
//             InputLabelProps={{
//               shrink: true,
//             }}
//             sx={{ marginLeft: 2 }}
//           />
//         </Box>
//         <TextField
//           margin="dense"
//           name="link"
//           label="LINK"
//           type="text"
//           fullWidth
//           value={taskData.link}
//           onChange={handleChange}
//         />
//         <InputLabel>TASK DURATION</InputLabel>
//         <FormControl margin="dense">
//           <TextField
//             margin="dense"
//             label="Hour"
//             name="hour"
//             type="number"
//             fullWidth
//             value={taskData.hour}
//             onChange={handleChange}
//           />
//         </FormControl>
//         <FormControl margin="dense">
//           <TextField
//             margin="dense"
//             label="Minute"
//             name="minute"
//             type="number"
//             value={taskData.minute}
//             onChange={handleChange}
//           />
//         </FormControl>
//         <InputLabel><strong>Optional Document</strong></InputLabel>
//         <TextField
//           margin="dense"
//           name="cv"
//           type="file"
//           onChange={handleChange}
//         />
//       </DialogContent>
//       <DialogActions>
//         <Button
//           variant='outlined'
//           sx={{ color: colors.redAccent[500] }}
//           onClick={handleClose}
//           disabled={isSubmitting}
//         >
//           CANCEL
//         </Button>
//         <Button
//           variant='outlined'
//           sx={{ color: colors.greenAccent[500] }}
//           onClick={handleSubmit}
//           disabled={isSubmitting}
//         >
//           ADD TASK
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default AddTask;


// import React, { useState, useEffect } from 'react';
// import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, FormControl, InputLabel, useTheme } from '@mui/material';
// import { useDispatch, useSelector } from 'react-redux';
// import toast from 'react-hot-toast';
// import { getAllExpert, getAllPoc, addTask, getPocCandidate, fetchAllTasks } from '../../actions/Admin';
// import { tokens } from '../../theme';
// import { Box } from '@mui/system';

// const AddTask = ({ open, handleClose }) => {
//   const [taskData, setTaskData] = useState({
//     candidateId: '',
//     pocId: '',
//     expertId: '',
//     taskType: '',
//     ISTTIME: '',
//     link: '',
//     hour: 0,
//     minute: 0,
//     cv: null,
//   });

//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [filteredCandidates, setFilteredCandidates] = useState([]);

//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const { experts, poc } = useSelector((state) => state.admin);
//   const dispatch = useDispatch();

//   const taskTypes = [
//     { label: 'INTERVIEW', value: 'interview' },
//     { label: 'ASSESSMENT', value: 'assessment' },
//     { label: 'JD SESSION', value: 'jdSession' },
//     { label: 'RU SESSION', value: 'ruSession' },
//     { label: 'RESUME BUILDING', value: 'resumeBuilding' },
//     { label: 'TRAINING', value: 'training' },
//   ];

//   const handleChange = (e) => {
//     const { name, value, files } = e.target;
//     if (name === 'cv') {
//       setTaskData((prevData) => ({
//         ...prevData,
//         [name]: files && files.length > 0 ? files[0] : null,
//       }));
//     } else {
//       setTaskData((prevData) => ({
//         ...prevData,
//         [name]: value,
//       }));
//     }
//   };

// const handleCandidateChange=(e)=>{

// };


//   const handlePocChange = async (e) => {
//     const { name, value } = e.target;
//     setTaskData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));

//     if (value) {
//       try {
//         const {candidates}=await dispatch(getPocCandidate(value))
//         setFilteredCandidates(candidates);
//       } catch (error) {
//         toast.error('Error fetching candidates');
//       }
//     } else {
//       setFilteredCandidates([]);
//     }
//   };

//   const handleSubmit = async () => {
//     setIsSubmitting(true);
//     const formDataToSend = new FormData();
//     Object.entries(taskData).forEach(([key, value]) => {
//       formDataToSend.append(key, value);
//     });

//     const result = await dispatch(addTask(formDataToSend));
//     setIsSubmitting(false);


//     if (result.success) {
//       toast.success('Task added successfully!');
//       dispatch(fetchAllTasks());
//       handleClose(); 
//     } else {
//       toast.error(result.message);
//     }
//   };

//   useEffect(() => {
//     dispatch(getAllPoc());
//     dispatch(getAllExpert());
//   }, [dispatch]);

//   return (
//     <Dialog open={open} onClose={handleClose}>
//       <DialogTitle>ADD NEW TASK</DialogTitle>
//       <DialogContent>
//         <FormControl fullWidth margin="dense">
//           <InputLabel>POC</InputLabel>
//           <Select
//             label="POC"
//             name="pocId"
//             value={taskData.pocId}
//             onChange={handlePocChange}
//           >
//             {poc.poc && poc.poc.map((p) => (
//               <MenuItem key={p._id} value={p._id}>
//                 {p.name}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//         {/* SEARCH CANDIDATE  then select candidate*/}
//         <TextField
//         label="CANDIDATE"
//             name="candidateId"
//             value={taskData.candidateId}
//             onChange={handleChange}
//             disabled={!taskData.pocId}>
//             <InputLabel>CANDIDATE</InputLabel>
//             <Select
//             label="CANDIDATE"
//             name="candidateId"
//             value={taskData.candidateId}
//             onChange={handleCandidateChange}
//             disabled={!taskData.pocId}
//           >
//             {filteredCandidates.map((candidate) => (
//               <MenuItem key={candidate._id} value={candidate._id}>
//                 {candidate.name}
//               </MenuItem>
//             ))}
//           </Select>
//         </TextField>
//         {/* DROPDOWN BUTTON */}
//         <FormControl fullWidth margin="dense">
//           <InputLabel>CANDIDATE</InputLabel>

//           <Select
//             label="CANDIDATE"
//             name="candidateId"
//             value={taskData.candidateId}
//             onChange={handleChange}
//             disabled={!taskData.pocId}
//           >
//             {filteredCandidates.map((candidate) => (
//               <MenuItem key={candidate._id} value={candidate._id}>
//                 {candidate.name}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//         {/* <FormControl fullWidth margin="dense">
//           <InputLabel>CANDIDATE</InputLabel>
//           <Select
//             label="CANDIDATE"
//             name="candidateId"
//             value={taskData.candidateId}
//             onChange={handleChange}
//             disabled={!taskData.pocId}
//           >
//             {filteredCandidates.map((candidate) => (
//               <MenuItem key={candidate._id} value={candidate._id}>
//                 {candidate.name}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl> */}
//         <FormControl fullWidth margin="dense">
//           <InputLabel>EXPERT</InputLabel>
//           <Select
//   label="EXPERT"
//   name="expertId"
//   value={taskData.expertId ? taskData.expertId : ''}
//   onChange={handleChange}
// >
//   <MenuItem value="">
//     <em>None</em>
//   </MenuItem>
//   {experts.experts && experts.experts.map((expert) => (
//     <MenuItem key={expert._id} value={expert._id}>
//       {expert.name}
//     </MenuItem>
//   ))}
// </Select>
//         </FormControl>
//         <FormControl fullWidth margin="dense">
//           <InputLabel>TASK TYPE</InputLabel>
//           <Select
//             label="TASK TYPE"
//             name="taskType"
//             value={taskData.taskType}
//             onChange={handleChange}
//           >
//             {taskTypes.map((type) => (
//               <MenuItem key={type.value} value={type.value}>
//                 {type.label}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>

//         <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
//           <img src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/255px-Flag_of_India.svg.png" height="40rem" width="60px" alt="IND FLAG" />
//           <TextField
//             margin="dense"
//             name="ISTTIME"
//             label="IST TIME" 
//             type="datetime-local"
//             fullWidth
//             value={taskData.ISTTIME}
//             onChange={handleChange}
//             InputLabelProps={{
//               shrink: true,
//             }}
//             sx={{ marginLeft: 2 }}
//           />
//         </Box>
//         <TextField
//           margin="dense"
//           name="link"
//           label="LINK"
//           type="text"
//           fullWidth
//           value={taskData.link}
//           onChange={handleChange}
//         />
//         <InputLabel>TASK DURATION</InputLabel>
//         <FormControl margin="dense">
//           <TextField
//             margin="dense"
//             label="Hour"
//             name="hour"
//             type="number"
//             fullWidth
//             value={taskData.hour}
//             onChange={handleChange}
//           />
//         </FormControl>
//         <FormControl margin="dense">
//           <TextField
//             margin="dense"
//             label="Minute"
//             name="minute"
//             type="number"
//             value={taskData.minute}
//             onChange={handleChange}
//           />
//         </FormControl>
//         <InputLabel><strong>Optional Document</strong></InputLabel>
//         <TextField
//           margin="dense"
//           name="cv"
//           type="file"
//           onChange={handleChange}
//         />
//       </DialogContent>
//       <DialogActions>
//         <Button
//           variant='outlined'
//           sx={{ color: colors.redAccent[500] }}
//           onClick={handleClose}
//           disabled={isSubmitting}
//         >
//           CANCEL
//         </Button>
//         <Button
//           variant='outlined'
//           sx={{ color: colors.greenAccent[500] }}
//           onClick={handleSubmit}
//           disabled={isSubmitting}
//         >
//           ADD TASK
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default AddTask;



import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  useTheme,
} from '@mui/material';
import { Autocomplete } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import {
  getAllExpert,
  getAllPoc,
  addTask,
  getPocCandidate,
  fetchAllTasks,
} from '../../actions/Admin';
import { tokens } from '../../theme';
import { Box } from '@mui/system';

const AddTask = ({ open, handleClose }) => {
  const [taskData, setTaskData] = useState({
    candidateId: '',
    pocId: '',
    expertId: '',
    taskType: '',
    ISTTIME: '',
    link: '',
    hour: 0,
    minute: 0,
    cv: null,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { experts, poc } = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  const taskTypes = [
    { label: 'INTERVIEW', value: 'interview' },
    { label: 'ASSESSMENT', value: 'assessment' },
    { label: 'JD SESSION', value: 'jdSession' },
    { label: 'RU SESSION', value: 'ruSession' },
    { label: 'RESUME BUILDING', value: 'resumeBuilding' },
    { label: 'TRAINING', value: 'training' },
  ];

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'cv') {
      setTaskData((prevData) => ({
        ...prevData,
        [name]: files && files.length > 0 ? files[0] : null,
      }));
    } else {
      setTaskData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handlePocChange = async (e) => {
    const { name, value } = e.target;
    setTaskData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (value) {
      try {
        const { candidates } = await dispatch(getPocCandidate(value));
        setFilteredCandidates(candidates);
        setSearchQuery(''); // Clear the search query when a new POC is selected
      } catch (error) {
        toast.error('Error fetching candidates');
      }
    } else {
      setFilteredCandidates([]);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredCandidateList = filteredCandidates.filter(candidate =>
    candidate.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const formDataToSend = new FormData();
    Object.entries(taskData).forEach(([key, value]) => {
      formDataToSend.append(key, value);
    });

    const result = await dispatch(addTask(formDataToSend));
    setIsSubmitting(false);

    if (result.success) {
      toast.success('Task added successfully!');
      dispatch(fetchAllTasks());
      handleClose();
    } else {
      toast.error(result.message);
    }
  };

  useEffect(() => {
    dispatch(getAllPoc());
    dispatch(getAllExpert());
  }, [dispatch]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>ADD NEW TASK</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="dense">
          <InputLabel>POC</InputLabel>
          <Select
            label="POC"
            name="pocId"
            value={taskData.pocId}
            onChange={handlePocChange}
          >
            {poc.poc && poc.poc.map((p) => (
              <MenuItem key={p._id} value={p._id}>
                {p.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Candidate Search Input */}

{/* 
<Autocomplete
  options={searchQuery ? filteredCandidateList : []}
  getOptionLabel={(option) => option.name}
  renderInput={(params) => (
    <TextField
      {...params}
      margin="dense"
      label="Search Candidate"
      type="text"
      
      value={searchQuery}
      onChange={handleSearchChange}
      disabled={!taskData.pocId}
      placeholder="Search by candidate name"
      sx={{ marginBottom: 2,width:"20rem" }}
    />
  )}
  onChange={(event, newValue) => {
    setTaskData((prevData) => ({
      ...prevData,
      candidateId: newValue ? newValue._id : '',
    }));
  }}
  isOptionEqualToValue={(option, value) => option._id === value._id}
  noOptionsText="No candidates found"
/>

        {/* Candidate Selection */}
          {/* <Select
            label="CANDIDATE"
            name="candidateId"
            value={taskData.candidateId}
            onChange={handleChange}
            disabled={!taskData.pocId}
          >
            {filteredCandidateList.map((candidate) => (
              <MenuItem key={candidate._id} value={candidate._id}>
                {candidate.name}
              </MenuItem>
            ))}
          </Select> */} 

        
  <Autocomplete fullWidth
    options={searchQuery ? filteredCandidateList : []}
    getOptionLabel={(option) => option.name}
    renderInput={(params) => (
      <TextField
        {...params}
        margin="dense"
        label="Search Candidate"
        type="text"
        value={searchQuery}
        onChange={handleSearchChange}
        disabled={!taskData.pocId}
        placeholder="Search by candidate name"
        sx={{ marginBottom: 2 }}
      />
    )}
    onChange={(event, newValue) => {
      setTaskData((prevData) => ({
        ...prevData,
        candidateId: newValue ? newValue._id : '',
      }));
    }}
    isOptionEqualToValue={(option, value) => option._id === value._id}
    noOptionsText="No candidates found"
  />
  

  {/* <FormControl sx={{ width: "3rem" }}>
    <Select
      label="CANDIDATE"
      name="candidateId"
      value={taskData.candidateId}
      onChange={handleChange} 
      disabled={!taskData.pocId}
    >
      {filteredCandidateList.map((candidate) => (
        <MenuItem key={candidate._id} value={candidate._id}>
          {candidate.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl> */}

        <FormControl fullWidth margin="dense">
          <InputLabel>EXPERT</InputLabel>
          <Select
            label="EXPERT"
            name="expertId"
            value={taskData.expertId ? taskData.expertId : ''}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {experts.experts && experts.experts.map((expert) => (
              <MenuItem key={expert._id} value={expert._id}>
                {expert.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="dense">
          <InputLabel>TASK TYPE</InputLabel>
          <Select
            label="TASK TYPE"
            name="taskType"
            value={taskData.taskType}
            onChange={handleChange}
          >
            {taskTypes.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
          <img
            src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/255px-Flag_of_India.svg.png"
            height="40rem"
            width="60px"
            alt="IND FLAG"
          />
          <TextField
            margin="dense"
            name="ISTTIME"
            label="IST TIME"
            type="datetime-local"
            fullWidth
            value={taskData.ISTTIME}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ marginLeft: 2 }}
          />
        </Box>
        <TextField
          margin="dense"
          name="link"
          label="LINK"
          type="text"
          fullWidth
          value={taskData.link}
          onChange={handleChange}
        />
        <InputLabel>TASK DURATION</InputLabel>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            margin="dense"
            label="Hour"
            name="hour"
            type="number"
            fullWidth
            value={taskData.hour}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Minute"
            name="minute"
            type="number"
            value={taskData.minute}
            onChange={handleChange}
          />
        </Box>
        <InputLabel>
          <strong>Optional Document</strong>
        </InputLabel>
        <TextField
          margin="dense"
          name="cv"
          type="file"
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          sx={{ color: colors.redAccent[500] }}
          onClick={handleClose}
          disabled={isSubmitting}
        >
          CANCEL
        </Button>
        <Button
          variant="outlined"
          sx={{ color: colors.greenAccent[500] }}
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          ADD TASK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTask;
