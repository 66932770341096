import axios from 'axios';
import toast from 'react-hot-toast';
import {requestStart, requestFailure,getAllTaskSuccess, todayTaskSuccess} from '../reducers/Expert';


const getAuthHeaders = () => {
  const token =localStorage.getItem("admin");
  return {
    headers: {
      "Content-Type": "application/json",
      "Authorization": token,
    },
    withCredentials: true,
  };
};  

export const getAllTask = () => async (dispatch) => {
  try {
    dispatch(requestStart());
    const { data } = await axios.get(`/api/v1/expert/expertTasks`, getAuthHeaders());
    console.log("data",data);
    dispatch(getAllTaskSuccess(data));
  } catch (error) {
    dispatch(requestFailure(error));
    toast.error(error);
  }
}; 
export const todayTask = () => async (dispatch) => {
  try {
    dispatch(requestStart());
    const { data } = await axios.get(`/api/v1/expert/todayTasks`,getAuthHeaders());
    console.log(data)
    dispatch(todayTaskSuccess(data.tasks));
  } catch (error) {
    dispatch(requestFailure(error));
    toast.error(error);
  }
};
