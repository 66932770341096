import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearError } from '../../reducers/POC';
import toast from 'react-hot-toast';
import Loader from '../Loading/Loading';
import { getAllCandidate, getAllTask} from '../../actions/POC';
import {
  Container,
  Typography,
  Button,
  Fab,
  Box,
  useTheme,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import AddTask from './AddTask';
import moment from 'moment';
import { tokens } from '../../theme';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';
const AllTask = () => {
  const dispatch = useDispatch();
  const { tasks, isLoading, error } = useSelector((state) => state.poc);
  const [formOpen, setFormOpen] = useState(false);
  const theme=useTheme();
const colors=tokens(theme.palette.mode)

  useEffect(() => {
    dispatch(getAllTask());
    dispatch(getAllCandidate());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
  }, [error, dispatch]);

  const handleAddTaskClick = () => {
    setFormOpen(true);
  };

  const handleFormClose = () => {
    setFormOpen(false);
  };

 


  const getStatusColor = (status) => {
    switch (status) {
      case 'Pending':
        return '#FFD700';
      case 'Assigned':
        return '#1E90FF';
      case 'Reschedule':
        return '#FFA500';
      case 'Complete':
        return '#008000';
      case 'Cancelled':
        return '#FF0000';
      default:
        return '#000000';
    }
  };

  const columns = [
    { field: 'ESTTIME', headerName: 'EST Time', flex: 1, headerAlign: 'center', align: 'center', renderCell: (params) => moment(params.value).format('D/M/YYYY hh:mm A') },
    { field: 'candidateName', headerName: 'Candidate', flex: 1.5, headerAlign: 'center',align: 'center', renderCell:(params)=>(
        <Box>
            <Typography>{params.row.candidateName}</Typography>
            <Typography>{params.row.candidateType}</Typography>
        </Box>
    ) },
    { field: 'taskType', headerName: 'Task Type / PRICE(₹)', flex: 1, headerAlign: 'center',renderCell:(params)=>(
        <Box>
            <Typography>{params.row.taskType}</Typography>
            <Typography>{params.row.pocPrice}</Typography>
        </Box>
    ) },
    { field: 'ISTTIME', headerName: 'IST Time', flex: 1, headerAlign: 'center', align: 'center', renderCell: (params) => moment(params.value).format('D/M/YYYY hh:mm A') },
    { field: 'taskDuration', headerName: 'Task Duration', flex: 1, headerAlign: 'center', align: 'center', renderCell: (params) => `${params.row.hour}:${params.row.minute}` },
    { field: 'subPoc', headerName: 'Tag', flex: 1, headerAlign: 'center', align: 'center', renderCell: (params) => params.value || "-" },
    // { field: 'PaymentStatus', headerName: 'Payment(₹)', flex: 1, headerAlign: 'center', align: 'center' },
    {
      field: 'PaymentStatus',
      headerName: 'Payment(₹)',
      width: 150,
       headerAlign: 'center', align: 'center',
      renderCell: (params) => (
        params.value === 'Completed' ? <TaskAltIcon color='success' /> :
        params.value === 'Unpaid' ? <CancelIcon color='error' /> :
        params.value === 'Pending' ? <HourglassDisabledIcon color='action' /> :
        params.value
      ),
    },
    { field: 'status', headerName: 'Task Status', flex: 1, headerAlign: 'center', align: 'center', renderCell: (params) => <Fab variant="extended" style={{ color: 'black', backgroundColor: getStatusColor(params.value), height: '1.5rem', width: '7rem' }}><strong>{params.value}</strong></Fab> },
  ];

  return (
    <>
      <Container style={{ maxWidth: '1750px' }}> 
        <Button variant='outlined' style={{ position: "relative",marginTop:"2rem" }} 
        sx={{ 
          color: colors.greenAccent[500], 
          borderColor: colors.greenAccent[500],
          '&:hover': {
            borderColor: colors.greenAccent[700],
          },
          '&.Mui-focused': {
            borderColor: colors.greenAccent[700],
          },
        }}   onClick={handleAddTaskClick}>Add Task</Button>
        {formOpen && <AddTask open={formOpen} handleClose={handleFormClose} />}
        {isLoading ? (
          <Loader />
        ) : (
          <div style={{ height: 600, width: '100%', marginTop: '3rem',display:"block" }}>
            <DataGrid
              rows={tasks}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              getRowId={(row) => row._id}
               sx={{
              ".MuiDataGrid-root": {
                bgcolor: colors.primary[600],
              },
              ".MuiDataGrid-cell": {
                color: colors.grey[100],
              },
              ".MuiDataGrid-columnHeader": {
                bgcolor: colors.primary[700],
                color: colors.greenAccent[500],
              },
              ".MuiCheckbox-root": {
                color: colors.grey[100],
              },
              "& .Mui-disabled": {
                pointerEvents: "none",
                opacity: 0.6,
              },
            }}
            />
          </div>
        )}
      </Container>
    </>
  );
};

export default AllTask;