import { createSlice } from '@reduxjs/toolkit';

const initialState = { 
  isLoading: false,
  isAuthenticated:false,
  role: null,
  error: null,
};
 
const loginSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    }, 
    loginSuccess: (state, action) => {
      state.isLoading = false;
      state.isAuthenticated=true;
      state.role = action.payload; 
    },
    loginFailure: (state, action) => {
      state.isLoading = false;
      state.isAuthenticated=false;
      state.error = action.payload;
    },
    loadUserRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    loadUserSuccess: (state, action) => {
      state.isLoading = false;
      state.isAuthenticated=true;
      state.role = action.payload.role; 
    },
    loadUserFailure: (state, action) => {
      state.isLoading = false;
      state.isAuthenticated=false;
      state.error = action.payload;
    },
    logout: (state) => {
      state.isLoading = false;
      state.isAuthenticated=false;
      state.error = null;
      state.user = null;
      state.role = null; 
    },
  },
});

export const { loginRequest, loginSuccess, loginFailure,loadUserRequest, loadUserSuccess, loadUserFailure,logout } = loginSlice.actions;

export default loginSlice.reducer;
