import axios from 'axios';
import toast from 'react-hot-toast';
import { loginRequest,loginSuccess,loginFailure,loadUserRequest,loadUserSuccess,loadUserFailure } from "../reducers/User";
// import {jwtDecode} from "jwt-decode"
//  import{Navigate} from "react-router-dom" 

export const userLogin = (phone, password,userType) => async (dispatch) => {
    try {
        dispatch(loginRequest());
    const { data } = await axios.post(`/api/v1/${userType}/login`, { phone, password }, {
        headers: {
            "Content-Type": "application/json",
        },
        withCredentials: true
    });
    
    localStorage.setItem("admin", data.token);
    localStorage.setItem("userType",userType );
    dispatch(loginSuccess(data.role)); 
    toast.success('Login successful!');
} catch (error) { 
    dispatch(loginFailure(error.response.data.message)); 
    toast.error(error.response.data.message);
}  
};

export const loadUser = (userType) => async (dispatch) => {
    try {
        dispatch(loadUserRequest());
        const token=localStorage.getItem("admin"); 
        const role=localStorage.getItem("userType"); 
        const  {data}  = await axios.get(`/api/v1/${role}/me`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization":token,
            },  
            withCredentials: true
        });
        dispatch(loadUserSuccess({role:data.user.role,user:data.user}));
        } catch (error) {
            dispatch(loadUserFailure(error.response.data.message));
            toast.error(error.response.data.message);
    }
};


// export const loadUser = (userType) => async (dispatch) => {
//     try {
//         dispatch(loadUserRequest());

//         const token = localStorage.getItem("admin"); 
//         const role = localStorage.getItem("userType");

//         if (!token) {
//             throw new Error("No token found. Please log in again.");
//         }

//         // Decode the token and check if it has expired
//         const decodedToken = jwtDecode(token);
//         const currentTime = Date.now() / 1000;
// console.log("decodedToken",decodedToken)
//         if (decodedToken.exp < currentTime) {
//             // Token has expired, remove it and reload the page
//             localStorage.removeItem("admin");
//             toast.error("Session has expired. Please log in again.");
//             // window.location.href = '/'; // Redirect to login page
//             <Navigate to="/"/>
//             return;
//         }

//         const { data } = await axios.get(`/api/v1/${role}/me`, {
//             headers: {
//                 "Content-Type": "application/json",
//                 "Authorization": `Bearer ${token}`,  // Ensure the token is prefixed with 'Bearer'
//             },
//             withCredentials: true
//         });

//         dispatch(loadUserSuccess({ role: data.user.role, user: data.user }));
//     } catch (error) {
//         const errorMessage = error.response?.data?.message || error.message;
//         dispatch(loadUserFailure(errorMessage));
//         toast.error(errorMessage);
//     }
// };




// export const loadUser = (userType) => async (dispatch) => {
//     try {
//         dispatch(loadUserRequest());

//         const token = localStorage.getItem("admin"); 
//         const role = localStorage.getItem("userType");

//         if (!token) {
//             throw new Error("No token found");
//         }

//         // Decode the token and check if it is expired
//         const decodedToken = jwtDecode(token);
//         const currentTime = Date.now() / 1000;

//         if (decodedToken.exp < currentTime) {
//             throw new Error("Token has expired. Please log in again.");
//         }

//         const { data } = await axios.get(`/api/v1/${role}/me`, {
//             headers: {
//                 "Content-Type": "application/json",
//                 "Authorization": token
//             },
//             withCredentials: true
//         });

//         dispatch(loadUserSuccess({ role: data.user.role, user: data.user }));
//     } catch (error) {
//         const errorMessage = error.response?.data?.message || error.message;
//         dispatch(loadUserFailure(errorMessage));
//         toast.error(errorMessage);
//     }
// };

