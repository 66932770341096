import axios from 'axios';
import toast from 'react-hot-toast';
import {
  requestStart,
  requestFailure,
  addCompanySuccess,
  addCandidateSuccess,
  addExpertSuccess,
  addPocSuccess,
  addTaskSuccess,
  fetchAllTasksSuccess,
  fetchTaskSuccess,
  fetchAllCompanyTasksSuccess,
  getAllCompanySuccess,
  getAllPocSuccess,
  getAllExpertSuccess,
  getAllCandidateSuccess,
  getPocSuccess
} from '../reducers/Admin';

const getAuthHeaders = () => {
  const token = localStorage.getItem("admin");
  return {
    headers: {
      "Content-Type": "application/json",
      "Authorization": token,
    },
    withCredentials: true,
  };
};

const getFileAuthHeaders = () => {
  const token = localStorage.getItem("admin");
  return {
    headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": token,
    },
    withCredentials: true,
  };
};

// Add Company
export const addCompany = (company) => async (dispatch) => {
  try {
    dispatch(requestStart());
    const { data } = await axios.post(`/api/v1/admin/addCompany`, company, getAuthHeaders());
    dispatch(addCompanySuccess(data.company));
    toast.success('Company added successfully!');
    return { success: true };
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
    return { success: false, message: error.response.data.message };
  }
};

// Get All Companies
export const getAllCompany = () => async (dispatch) => {
  try {
    dispatch(requestStart());
    const { data } = await axios.get(`/api/v1/admin/allCompany`, getAuthHeaders());
    dispatch(getAllCompanySuccess(data.company));
    return { success: true };
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
    return { success: false, message: error.response.data.message };
  }
};

// Add Candidate
export const addCandidate = (candidateData) => async (dispatch) => {
  try {
    dispatch(requestStart());
    const { data } = await axios.post(`/api/v1/admin/addCandidate`, candidateData, getFileAuthHeaders());
    console.log("data",data)
    dispatch(addCandidateSuccess(data.candidate));
    toast.success('Candidate added successfully!');
    return { success: true };
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
    return { success: false, message: error.response.data.message };
  }
};

// Get All Candidates
export const getAllCandidate = () => async (dispatch) => {
  try {
    dispatch(requestStart());
    const { data } = await axios.get(`/api/v1/admin/allCandidate`, getAuthHeaders());
    dispatch(getAllCandidateSuccess(data.candidate));
    return { success: true };
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
    return { success: false, message: error.response.data.message };
  }
};

// Get Poc Candidates
export const getPocCandidate = (value) => async (dispatch) => {
  try {
    dispatch(requestStart());
    const { data } = await axios.get(`/api/v1/admin/getpoc/Candidate/${value}`, getAuthHeaders());
    return {candidates:data.candidate};
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
    return { success: false, message: error.response.data.message };
  }
};

// Add POC
export const addPoc = (poc) => async (dispatch) => {
  try {
    dispatch(requestStart());
    const { data } = await axios.post(`/api/v1/admin/addPoc`, poc, getAuthHeaders());
    dispatch(addPocSuccess({ p: data.poc }));
    toast.success('POC added successfully!');
    return { success: true };
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
    return { success: false, message: error.response.data.message };
  }
};

// Get POC by ID
export const getPoc = (id) => async (dispatch) => {
  try {
    dispatch(requestStart());
    const { data } = await axios.get(`/api/v1/admin/getPoc/${id}`, getAuthHeaders());
    dispatch(getPocSuccess(data));
    return { success: true };
  } catch (error) {
    dispatch(requestFailure(error.response.data ? error.response.data.message : "null"));
    toast.error(error.response.data ? error.response.data.message : "null");
    return { success: false, message: error.response.data ? error.response.data.message : "null" };
  }
};

// Get All POCs
export const getAllPoc = () => async (dispatch) => {
  try {
    dispatch(requestStart());
    const { data } = await axios.get(`/api/v1/admin/allpoc`, getAuthHeaders());
    dispatch(getAllPocSuccess(data.poc));
    return { success: true };
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
    return { success: false, message: error.response.data.message };
  } 
};
 
// Add Expert
export const addExpert = (expertData) => async (dispatch) => {
  console.log("expertData",expertData);
  try {
    dispatch(requestStart());
    const { data } = await axios.post(`/api/v1/admin/addExpert`, expertData, getAuthHeaders());
    console.log(data)
    dispatch(addExpertSuccess(data.expert));
    toast.success('Expert added successfully!');
    return { success: true };
  } catch (error) {
    console.log(error)
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
    return { success: false, message: error.message };
  }
};

// Get All Experts
export const getAllExpert = () => async (dispatch) => {
  try {
    dispatch(requestStart());
    const { data } = await axios.get(`/api/v1/admin/allExpert`,getAuthHeaders());
    console.log(data.experts.experts);
    dispatch(getAllExpertSuccess(data.experts));
    toast.success("All Experts");
    return { success: true };
  } catch (error) {
    // dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
    return { success: false, message: error.response.data.message };
  }
};

// Assign Task to Expert
export const assignToExpert = (taskData) => async (dispatch) => {
  try {
    dispatch(requestStart());
    const { data } = await axios.post(`/api/v1/admin/assignToExpert`, taskData, getAuthHeaders());
    dispatch(fetchAllCompanyTasksSuccess(data.companyTasks));
    toast.success('Task assigned successfully!');
    return { success: true };
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
    return { success: false, message: error.response.data.message };
  }
};

// Add Task
export const addTask = (taskData) => async (dispatch) => {
  try {
    dispatch(requestStart());
    const res = await axios.post(`/api/v1/admin/addTask`, taskData, getFileAuthHeaders());
    if (res.status === 200) {
      dispatch(addTaskSuccess(res.data.task));
      toast.success('Task added successfully!');
      return { success: true };
    }
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
    return { success: false, message: error.response.data.message };
  }
};

// Update Task Status
// export const updateTaskStatus = ({ taskId, status }) => async (dispatch) => {
//   try {
//     dispatch(requestStart());
// const res= await axios.put(`/api/v1/admin/updateStatus`, { taskId, status }, getAuthHeaders());
// console.log(res.status);
//     if (res.status === 200) {
//       dispatch(addTaskSuccess(res.data.task));
//       toast.success('Task added successfully!');
//       return { success: true };
//     }
//   } catch (error) {
//     dispatch(requestFailure(error.response.data.message));
//     toast.error(error.response.data.message);
//     return { success: false, message: error.response.data.message };
//   }
// };


export const updateTaskStatus = ({ taskId, status }) => async (dispatch) => {
  try {
    dispatch(requestStart());
    
    // Execute the API call
    await axios.put(`/api/v1/admin/updateStatus`, { taskId, status }, getAuthHeaders());

    // If successful, return a success response
    // return { success: true };
    return { success: true, message: 'Status updated successfully' };
  } catch (error) {
    // Capture the error message from the response
    const errorMessage = error.response?.data?.message || 'An error occurred while updating the status';
    // Dispatch the failure action and return the error
    // dispatch(requestFailure(errorMessage));
    return { success: false, message: errorMessage };
  }
};


// Update Task Time
export const updateTaskTime = (timeData) => async (dispatch) => {
  try {
    dispatch(requestStart());
    await axios.put(`/api/v1/admin/updateTime`, { ISTTIME: timeData.ISTTIME, taskId: timeData.taskId }, getAuthHeaders());
    toast.success('Task time updated successfully!');
    return { success: true };
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
    return { success: false, message: error.response.data.message };
  }
};

// Update Task Duration
export const updateTaskDuration = (durationData) => async (dispatch) => {
  try {
    dispatch(requestStart());
    await axios.put(`/api/v1/admin/updateTaskDuration`, durationData, getAuthHeaders());
    toast.success('Task duration updated successfully!');
    return { success: true };
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
    return { success: false, message: error.response.data.message };
  }
};

// Update Task Price
export const updateTaskPrice = ({ taskId, expertPrice, pocPrice }) => async (dispatch) => {
  try {
    dispatch(requestStart());
    await axios.put(`/api/v1/admin/updatePrice`, { taskId, expertPrice, pocPrice }, getAuthHeaders());
    toast.success('Task price updated successfully!');
    return { success: true };
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
    return { success: false, message: error.response.data.message };
  }
};

// Update Candidate
export const updateCandidate = (candidateId, formData) => async (dispatch) => {
  try {
    console.log(formData);
    dispatch(requestStart());
    const { data } = await axios.put(`/api/v1/admin/updateCandidate/${candidateId}`, formData, getFileAuthHeaders());
    console.log(data);
    toast.success('Candidate updated successfully!');
    return { success: true };
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
    return { success: false, message: error.response.data.message };
  }
};

// Update Expert
export const updateExpert = (expertId, formData) => async (dispatch) => {
  try {
    dispatch(requestStart());
    const { data } = await axios.put(`/api/v1/admin/updateExpert/${expertId}`, formData, getAuthHeaders());
    console.log(data);
    toast.success('expert updated successfully!');
    return { success: true };
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
    return { success: false, message: error.response.data.message };
  }
};


// Update Poc
export const updatePoc = (pocId, formData) => async (dispatch) => {
  try {
    dispatch(requestStart());
    const { data } = await axios.put(`/api/v1/admin/updatePoc/${pocId}`, formData, getAuthHeaders());
    toast.success('poc updated successfully!');
    return { success: true,data };
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
    return { success: false, message: error.response.data.message };
  }
};

//update expert payment 
export const updateExpertStatus = (expertId, taskIds) => async (dispatch) => {
  console.log(expertId)
  console.log(taskIds)
  try {
    dispatch(requestStart());
    const { data } = await axios.put(`/api/v1/admin/updateExpertPStatus/${expertId}`, taskIds, getAuthHeaders());
    return { success: true,data };
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
    return { success: false, message: error.response.data.message };
  }
};
//update poc payment status
export const updatePocStatus = (pocId, taskIds) => async (dispatch) => {
  console.log(pocId)
  console.log(taskIds)
  try {
    dispatch(requestStart());
    const { data } = await axios.put(`/api/v1/admin/updatePocPStatus/${pocId}`, taskIds, getAuthHeaders());
    return { success: true,data };
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
    return { success: false, message: error.response.data.message };
  }
};


// Fetch All Tasks
export const fetchAllTasks = () => async (dispatch) => {
  try {
    dispatch(requestStart());
    const { data } = await axios.get(`/api/v1/admin/getAllTasks`, getAuthHeaders());
    dispatch(fetchAllTasksSuccess({ tasks: data.tasks, PAtasks: data.PAtasks }));
    return { success: true };
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
    return { success: false, message: error.response.data.message };
  }
};

// Fetch All Company Tasks
export const fetchAllCompanyTasks = () => async (dispatch) => {
  try {
    dispatch(requestStart());
    const { data } = await axios.get(`/api/v1/admin/getCompanyTask`, getAuthHeaders());
    dispatch(fetchAllCompanyTasksSuccess(data.companyTasks));
    return { success: true };
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
    return { success: false, message: error.response.data.message };
  }
};

// Fetch Task by ID
export const fetchTask = (taskId) => async (dispatch) => {
  try {
    dispatch(requestStart());
    const { data } = await axios.post(`/api/v1/admin/getTask`, { id: taskId }, getAuthHeaders());
    dispatch(fetchTaskSuccess(data));
    return { success: true };
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
    return { success: false, message: error.response.data.message };
  }
};

// Give Feedback
export const giveFeedback = ({ taskId, feedback, screenshot }) => async (dispatch) => {
  try {
    dispatch(requestStart());
    await axios.post(`/api/v1/admin/feedback/screenshot`, { taskId, feedback, screenshot }, getFileAuthHeaders());
    toast.success('Feedback submitted successfully!');
    return { success: true };
  } catch (error) {
    dispatch(requestFailure(error.response.data.message));
    toast.error(error.response.data.message);
    return { success: false, message: error.response.data.message };
  }
};
