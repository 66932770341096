// import React from 'react';
// import { Link } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { logout } from '../../reducers/User';
// import { clearAdmin } from '../../reducers/Admin';
// import {
//   Box,
//   Drawer,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   Typography,
//   Button,
//   IconButton,
//   Tooltip,
// } from '@mui/material';
// import {
//   Menu as MenuIcon,
//   ChevronLeft as ChevronLeftIcon,
// } from '@mui/icons-material';
// import { useTheme } from '@mui/material/styles';
// import { tokens } from '../../theme';

// import { RxDashboard } from "react-icons/rx";
// import { GrMoney } from "react-icons/gr";

// const Esidebar = ({ isSidebarOpen, toggleSidebar }) => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const dispatch = useDispatch();

//   const handleLogout = (e) => {
//     e.preventDefault();
//     localStorage.removeItem('admin');
//     localStorage.removeItem('userType');
//     dispatch(clearAdmin());
//     dispatch(logout());
//     window.location.reload();
//   };

//   return (
//     <div>
//       <Drawer
//         variant="persistent"
//         anchor="left"
//         open
//         sx={{
//           width: isSidebarOpen ? 240 : 120,
//           flexShrink: 0,
//           '& .MuiDrawer-paper': {
//             width: isSidebarOpen ? 240 : 120,
//             boxSizing: 'border-box',
//             overflowX: 'hidden',
//             backgroundColor: colors.primary[500],
//             color: colors.grey[100],
//           },
//         }}
//       >
//         <Box
//           sx={{
//             display: 'flex',
//             alignItems: 'center',
//             padding: theme.spacing(2),
//             justifyContent: 'space-between',
//           }}
//         >
//           {isSidebarOpen && (
//             <Typography variant="h6" noWrap sx={{ color: colors.grey[100] }}>
//               TEKIZZ
//             </Typography>
//           )}
//           <IconButton onClick={toggleSidebar} sx={{ color: colors.grey[100] }}>
//             {isSidebarOpen ? <ChevronLeftIcon /> : <MenuIcon />}
//           </IconButton>
//         </Box>
//         <List>
//           <Tooltip title="DASHBOARD" placement="right" disableHoverListener={isSidebarOpen}>
//             <ListItem button component={Link} to="/poc/dashboard">
//               <ListItemIcon sx={{ color: colors.grey[100] }}>
//                 <RxDashboard />
//               </ListItemIcon>
//               {isSidebarOpen && <ListItemText primary="DASHBOARD" />}
//             </ListItem>
//           </Tooltip>
//           <Tooltip title="PAYMENT" placement="right" disableHoverListener={isSidebarOpen}>
//             <ListItem button component={Link} to="/expert/payment">
//               <ListItemIcon sx={{ color: colors.grey[100] }}>
//                 <GrMoney />
//               </ListItemIcon>
//               {isSidebarOpen && <ListItemText primary="PAYMENT" />}
//             </ListItem>
//           </Tooltip>
//         </List>
//         {isSidebarOpen && (
//           <Box sx={{ padding: theme.spacing(2) }}>
//             <Button variant="contained" color="secondary" onClick={handleLogout}>
//               Logout
//             </Button>
//           </Box>
//         )}
//       </Drawer>
//     </div>
//   );
// };

// export default Esidebar;



import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../reducers/User';
import { clearAdmin } from '../../reducers/Admin';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { tokens } from '../../theme';

import { RxDashboard } from "react-icons/rx";
import { GrMoney } from "react-icons/gr";
import Clock from './Clock'; 
import Tekizz from "../Images/Head_ekkiz.png"

const Esidebar = ({ isSidebarOpen, toggleSidebar }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem('admin');
    localStorage.removeItem('userType');
    dispatch(clearAdmin());
    dispatch(logout());
    window.location.reload();
  };

  return ( 
    <div>
      <Drawer
        variant="persistent"
        anchor="left"
        open
        sx={{
          width: isSidebarOpen ? 240 : 120,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: isSidebarOpen ? 240 : 120,
            boxSizing: 'border-box',
            overflowX: 'hidden',
            backgroundColor: colors.primary[500],
            color: colors.grey[100],
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(2),
            justifyContent: 'space-between',
          }}
        >
          {isSidebarOpen && (
            <>
          <img src={Tekizz} height="50px" alt="Tekizz" />
          </>
          )}
          <IconButton onClick={toggleSidebar} sx={{ color: colors.grey[100] }}>
            {isSidebarOpen ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
        </Box>
        <List>
          <Tooltip title="DASHBOARD" placement="right" disableHoverListener={isSidebarOpen}>
            <ListItem button component={Link} to="/poc/dashboard">
              <ListItemIcon sx={{ color: colors.grey[100] }}>
                <RxDashboard />
              </ListItemIcon>
              {isSidebarOpen && <ListItemText primary="DASHBOARD" />}
            </ListItem>
          </Tooltip>
          <Tooltip title="PAYMENT" placement="right" disableHoverListener={isSidebarOpen}>
            <ListItem button component={Link} to="/expert/payment">
              <ListItemIcon sx={{ color: colors.grey[100] }}>
                <GrMoney />
              </ListItemIcon>
              {isSidebarOpen && <ListItemText primary="PAYMENT" />}
            </ListItem>
          </Tooltip>
        </List>
        {isSidebarOpen && (<>
          <Box sx={{ padding: theme.spacing(2) }}>
            <Button variant="contained" color="secondary" onClick={handleLogout}>
              Logout
            </Button>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: theme.spacing(2) }}>
              <img src="https://cdn.britannica.com/33/4833-004-828A9A84/Flag-United-States-of-America.jpg" alt="USA FLAG" style={{marginRight: '1rem', width: '30px', height: '20px' }} />
              <Clock timezone="America/New_York" label="EST TIME" colors={colors} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: theme.spacing(2) }}>
              <img src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/255px-Flag_of_India.svg.png" alt="IND FLAG" style={{marginRight: '1rem', width: '30px', height: '20px' }} />
              <Clock timezone="Asia/Kolkata" label="IST TIME" colors={colors} />
            </Box>
          
          </Box>
          </>
        )}
      </Drawer>
    </div>
  );
};

export default Esidebar;
