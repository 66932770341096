import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, MenuItem, Select, InputLabel,Box} from '@mui/material';
import { addCompany, addPoc, getAllCompany, getAllPoc } from '../../actions/Admin';
import BasicTable from './PocTable';
import { useTheme } from '@mui/material/styles';
import { tokens } from '../../theme';
import toast from 'react-hot-toast';
import { clearError } from '../../reducers/Admin';

function AddCompanyPOC({ isSidebarOpen }) {
  const [open, setOpen] = useState(false);
  const [formType, setFormType] = useState('');
  const [company, setCompany] = useState({ name: '' });
  const [formData, setFormData] = useState({
    name: '',
    companyId: '',
    taskType: '',
    email: '',
    phone: '',
    password: '',
    interview: "",
    tillPlacementInterview: "",
    jdSession: "",
    assessment: "",
    ruSession: "",
    training: "",
    resumeBuilding: "",
  });
  const { companies, error } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    dispatch(getAllCompany());
    dispatch(getAllPoc());
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
  }, [dispatch, error]);

  const handleClose = () => {
    setOpen(false);
    setFormType('');
    setCompany({ name: '' });
    setFormData({
      name: '',
      companyId: '',
      taskType: '',
      email: '',
      phone: '',
      password: '',
      interview: "",
      tillPlacementInterview: "",
      jdSession: "",
      assessment: "",
      ruSession: "",
      training: "",
      resumeBuilding: "",
    });
  };

  const handleCompanyChange = (e) => {
    setCompany({ ...company, [e.target.name]: e.target.value });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmitCompany = async (e) => {
    e.preventDefault();
    const result = await dispatch(addCompany(company));
    if (result.success) {
      toast.success('Company added successfully!');
      handleClose();
    } else {
      toast.error(result.message);
    }
  };

  const handleSubmitPoc = async (e) => {
    e.preventDefault();
    const result = await dispatch(addPoc(formData));
    if (result.success) {
      toast.success('POC added successfully!');
      handleClose();
    } else {
      toast.error(result.message);
    }
  };

  let sx={
                                '& .MuiInputLabel-root': {
                                    color: colors.greenAccent[500],
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: colors.greenAccent[500],
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.greenAccent[500],
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.greenAccent[500],
                                    },
                                    '& input::placeholder': {
                                        color: colors.greenAccent[500],
                                    },
                                },
                            }

  return (
    <>
    
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
      <Dialog open={open && formType === 'company'} onClose={handleClose} aria-labelledby="company-form-dialog-title">
        <DialogTitle id="company-form-dialog-title" sx={{ backgroundColor: colors.primary[600], color: colors.grey[100] }}>
          Add Company
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: colors.primary[700], color: colors.grey[100] }}>
          <DialogContentText>
            Please fill in the details to add a new company.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense" 
            id="name"
            name="name"
            label="Company Name"
            placeholder="Enter Company Name"
            type="text"
            fullWidth
            value={company.name}
            onChange={handleCompanyChange}
            sx={sx}
          />
        </DialogContent>
        <DialogActions sx={{ backgroundColor: colors.primary[700] }}>
          <Button onClick={handleClose} variant="outlined" sx={{ color:"red", borderColor: "red" }}>
            Cancel
          </Button>
          <Button onClick={handleSubmitCompany} variant="outlined" sx={{ color:colors.greenAccent[500], borderColor:colors.greenAccent[500]  }}>
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open && formType === 'poc'} onClose={handleClose} aria-labelledby="poc-form-dialog-title">
        <DialogTitle id="poc-form-dialog-title" sx={{ backgroundColor: colors.primary[600], color: colors.grey[100] }}>
          Add Point Of Contact
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: colors.primary[700], color: colors.grey[100] }}>
          <DialogContentText>
            Please fill in the details for the Point Of Contact.
          </DialogContentText>
          <InputLabel id="company-id-label" sx={{ color: colors.grey[100] }}>Select Company</InputLabel>
          <Select
            labelId="company-id-label"
            id="company-id"
            required
            name="companyId"
            value={formData.companyId}
            onChange={handleChange}
            fullWidth
            sx={{ color: colors.grey[100] }}
          >
            {companies && companies.map((com) => (
              <MenuItem key={com._id} value={com._id}>{com.name}</MenuItem>
            ))}
          </Select>
          <TextField
            margin="dense"
            id="poc-name"
            name="name"
            label="POC Name"
            type="text"
            required
            fullWidth
            value={formData.name}
            onChange={handleChange}
            sx={sx}
          />
          <TextField
            margin="dense"
            id="poc-email"
            name="email"
            label="Email"
            type="email"
            required
            fullWidth
            value={formData.email}
            onChange={handleChange}
            sx={sx}
          />
          <TextField
            margin="dense"
            id="poc-mobile"
            name="phone"
            label="Mobile"
            type="text"
            required
            fullWidth
            value={formData.phone}
            onChange={handleChange}
            sx={sx}
          />
          <TextField
            margin="dense"
            id="poc-password"
            name="password"
            label="Password"
            type="password"
            required
            fullWidth
            value={formData.password}
            onChange={handleChange}
            sx={sx}
          />
          <TextField
            margin="dense"
            id="Interview-Price"
            label="Interview Price"
            type="number"
            name="interview"
            fullWidth
            value={formData.interview}
            onChange={handleChange}
            sx={sx}
          />
          <TextField
            margin="dense"
            id="Till-Placement-Interview-Price"
            label="Till Placement Interview Price"
            type="number"
            name="tillPlacementInterview"
            fullWidth
            value={formData.tillPlacementInterview}
            onChange={handleChange}
            sx={sx}
          />
          <TextField
            margin="dense"
            id="JD-Session-Price"
            label="JD Session Price"
            type="number"
            name="jdSession"
            fullWidth
            value={formData.jdSession}
            onChange={handleChange}
            sx={sx}
          />
          <TextField
            margin="dense"
            id="assessment-price"
            label="Assessment Price"
            type="number"
            name="assessment"
            fullWidth
            value={formData.assessment}
            onChange={handleChange}
            sx={sx}
          />
          <TextField
            margin="dense"
            id="ru-session-price"
            label="RU Session Price"
            type="number"
            name="ruSession"
            fullWidth
            value={formData.ruSession}
            onChange={handleChange}
            sx={sx}
          />
          <TextField
            margin="dense"
            id="training-price"
            label="Training Price"
            type="number"
            fullWidth
            name="training"
            value={formData.training}
            onChange={handleChange}
            sx={sx}
          />
          <TextField
            margin="dense"
            id="resume-building-price"
            label="Resume Building Price"
            type="number"
            fullWidth
            name="resumeBuilding"
            value={formData.resumeBuilding}
            onChange={handleChange}
            sx={sx}
          />
        </DialogContent>
        <DialogActions sx={{ backgroundColor: colors.primary[700] }}>
          <Button onClick={handleClose}variant="outlined" sx={{ color:"red", borderColor: "red" }}>
            Cancel
          </Button>
          <Button onClick={handleSubmitPoc} variant="outlined" sx={{ color:colors.greenAccent[500], borderColor:colors.greenAccent[500]  }}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
      <BasicTable isSidebarOpen={isSidebarOpen} />
    </>
  );
}

export default AddCompanyPOC;
