import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Box,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { tokens } from '../../theme';

const EditExpert = ({ open, handleClose, expert, handleEditChange, handleEditSubmit }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let sx={
                                '& .MuiInputLabel-root': {
                                    color: colors.greenAccent[500],
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: colors.greenAccent[500],
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.greenAccent[500],
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.greenAccent[500],
                                    },
                                    '& input::placeholder': {
                                        color: colors.greenAccent[500],
                                    },
                                },
                            }


                            const technologyOptions = [
                              "Business Analyst",
                              "Financial Analyst",
                              "Project Manager",
                              "Scrum Master",
                              "Data Analyst",
                              "Data Engineer",
                              "Data Scientists",
                              "Java Developer",
                              "Java Full Stack Developer",
                              "Software Engineer",
                              "Python Developer",
                              "Front-End Developer",
                              "Backend Developer",
                              "Cyber Security Analyst",
                              "Network Engineer",
                              "ASP .NET Developer",
                              "Others"
                          ];    
                      




  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ backgroundColor: colors.primary[600], color: colors.grey[100] }}>
        Edit Expert Details 
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: colors.primary[700], color: colors.grey[100] }}>
        <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2,padding:2 }}>
          <TextField
            label="Name"
            name="name"
            value={expert.name || ''}
            onChange={handleEditChange} 
            fullWidth
            variant="outlined"
            sx={sx}
          />
          <TextField
            label="Email"
            name="email"
            value={expert.email || ''}
            onChange={handleEditChange}
            fullWidth
            variant="outlined"
            sx={sx}
          />
          <TextField
            label="Phone"
            name="phone"
            value={expert.phone || ''}
            onChange={handleEditChange}
            fullWidth
            variant="outlined"
            sx={sx}
          />
          {/* <TextField
            label="Technology"
            name="technology"
            value={expert.technology || ''}
            onChange={handleEditChange}
            fullWidth
            variant="outlined"
            sx={sx}
          /> */}
          <FormControl fullWidth margin="normal">
                            <InputLabel
                                id="technology-label"
                                sx={{
                                    color: colors.greenAccent[500],
                                    '&.Mui-focused': {
                                        color: colors.greenAccent[500],
                                    },
                                }}
                            >
                                Technology
                            </InputLabel>
                            <Select
                                labelId="technology-label"
                                id="technology"
                                required
                                name="technology"
                                value={expert.technology}
                                onChange={handleEditChange}
                                sx={{
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.greenAccent[500],
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.greenAccent[500],
                                    },
                                }}
                            >
                                {technologyOptions.map((tech, index) => (
                                    <MenuItem key={index} value={tech}>{tech}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
          <TextField
            label="TILL PLACEMENT INTERVIEW"
            name="tillPlacementInterview"
            value={expert.tillPlacementInterview || ''}
            onChange={handleEditChange}
            fullWidth
            variant="outlined"
            
            sx={sx}
          />
          <TextField
            label="INTERVIEW"
            name="interview"
            value={expert.interview || ''}
            onChange={handleEditChange}
            fullWidth
            variant="outlined"
            sx={sx}
          />
          <TextField
            label="JD SESSION"
            name="jdSession"
            value={expert.jdSession || ''}
            onChange={handleEditChange}
            fullWidth
            variant="outlined"
            sx={sx}
          />
          <TextField 
            label="ASSESSMENT"
            name="assessment"
            value={expert.assessment || ''}
            onChange={handleEditChange} 
            fullWidth
            variant="outlined"
            sx={sx}
          />
          <TextField
            label="RU SESSION"
            name="ruSession"
            value={expert.ruSession || ''}
            onChange={handleEditChange}
            fullWidth
            variant="outlined"
            sx={sx}
          />
          <TextField
            label="TRAINING"
            name="training"
            value={expert.training || ''}
            onChange={handleEditChange}
            fullWidth
            variant="outlined"
            sx={sx}
          />
          <TextField
            label="RESUME BUILDING"
            name="resumeBuilding"
            value={expert.resumeBuilding || ''}
            onChange={handleEditChange}
            fullWidth
            variant="outlined"
            sx={sx}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: colors.primary[700], color: colors.grey[100] }}>
        <Button onClick={handleClose} variant="outlined" sx={{ color:"red", borderColor: "red" }}>
          Cancel
        </Button>
        <Button type='submit' onClick={handleEditSubmit} variant="outlined" sx={{ color:colors.greenAccent[500], borderColor:colors.greenAccent[500]  }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditExpert;
