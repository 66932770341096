import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Box,
  useTheme,
} from '@mui/material';
import { tokens } from '../../theme';

const EditCandidate = ({ open, handleClose, candidate, handleEditChange, handleEditSubmit }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ backgroundColor: colors.primary[600], color: colors.grey[100] }}>
        Edit Candidate Details
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: colors.primary[700], color: colors.grey[100] }}>
        <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2,padding:2 }}>
          <TextField
            label="Name"
            name="name"
            value={candidate.name || ''}
            onChange={handleEditChange}
            fullWidth
            autoHeight
            variant="outlined"
            sx={{ backgroundColor: colors.primary[600], input: { color: colors.grey[100] } }}
          />
          <TextField
            label="Email"
            name="email"
            value={candidate.email || ''}
            onChange={handleEditChange}
            fullWidth
            variant="outlined"
            sx={{ backgroundColor: colors.primary[600], input: { color: colors.grey[100] } }}
          />
          <TextField
            label="Phone"
            name="phone"
            value={candidate.phone || ''}
            onChange={handleEditChange}
            fullWidth
            variant="outlined"
            sx={{ backgroundColor: colors.primary[600], input: { color: colors.grey[100] } }}
          />
          <TextField
            label="Technology"
            name="technology"
            value={candidate.technology || ''}
            onChange={handleEditChange}
            fullWidth
            variant="outlined"
            sx={{ backgroundColor: colors.primary[600], input: { color: colors.grey[100] } }}
          />
            <TextField
            label="TAG"
            name="subPoc"
            value={candidate.subPoc || ''}
            onChange={handleEditChange}
            fullWidth
            variant="outlined"
            sx={{ backgroundColor: colors.primary[600], input: { color: colors.grey[100] } }}
          />
          <TextField
            margin="dense"
            fullWidth
            name="cv"
            type="file"
            variant="outlined"
            onChange={handleEditChange}
            sx={{ backgroundColor: colors.primary[600], input: { color: colors.grey[100] } }}
            InputLabelProps={{
              shrink: true,
            }}
          />
         
        </Box>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: colors.primary[700], color: colors.grey[100] }}>
        <Button onClick={handleClose} sx={{ color: colors.grey[100] }}>
          Cancel
        </Button>
        <Button type='submit' onClick={handleEditSubmit} sx={{ color: colors.grey[100] }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCandidate;
