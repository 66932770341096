import axios from 'axios';
import toast from 'react-hot-toast';
import { PerPayment } from '../../actions/POC';
import Tekizz from "../Images/Asset-3@2x -256 X 256.png"

const getAuthHeaders = () => {
  const token = localStorage.getItem("admin");
  return {
    headers: {
      "Content-Type": "application/json",
      "Authorization": token,
    },
    withCredentials: true, 
  };
};

const makePayment = async ({amount,tasksIds,paymentType} ,navigate,dispatch) => {
  try {
    const { data: { key } } = await axios.get(`/api/v1/poc/gerazorpayKey`, getAuthHeaders());
    const { data: { order } } = await axios.post(`/api/v1/poc/checkout`, { amount }, getAuthHeaders());

    const options = {
      key,
      amount: order.amount,
      currency: "INR",
      name: "Tekizz IT Services",
      description: "Pay At Tekizz IT Services",
      // image:"https://tekizz.com/wp-content/uploads/elementor/thumbs/Tekizz-Logo-final-qgyebk17o79an8x3clp28tr7rjil879r9m580993dy.png",
      // image:  <img src={Tekizz} height="50px" alt="Tekizz" />,
      image:Tekizz,
      order_id: order.id,
      handler: async (response) => {
        const data = {
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
          tasksIds,
          paymentType,
          amount:order.amount
        };
        try {
          const datas=await axios.post(`/api/v1/poc/paymentverification`, data, getAuthHeaders());
          // window.location.href=`${window.location.protocol}//${window.location.host}/paymentsuccess?reference=${datas.data.razorpay_payment_id}`
          navigate("/poc/make/payment")
          dispatch(PerPayment());
          toast.success(datas.data.razorpay_payment_id)
        } catch (verificationError) {
          console.log('Error during payment verification:', verificationError.response.data.message);
          toast.error(verificationError.response.data.message);
        }
      },
      prefill: {
        name: "",
        email: "",
        contact: "",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#14AF19",
      },
    };

    const razor = new window.Razorpay(options);
    razor.open();
  } catch (error) {
    console.error('Error during checkout:', error);
  }
};

export default makePayment;
