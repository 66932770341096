// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Button, Grid, Box, Typography, useTheme } from '@mui/material';
// import { Link } from 'react-router-dom';
// import { DataGrid } from '@mui/x-data-grid';
// import { tokens } from '../../theme';
// import EditPoc from './EditPoc';
// import { FaEdit } from 'react-icons/fa';
// import { getAllPoc, updatePoc } from '../../actions/Admin';

// const BasicTable = ({ isSidebarOpen }) => {
//   const { poc } = useSelector((state) => state.admin);
//   const [editPoc, setEditPoc] = useState({});
//   const [openEdit, setOpenEdit] = useState(false);
//   const { totalPendingPayment, totalReceivedPayment, currentPendingPayment, currentReceivedPayment } = poc;
//   const dispatch = useDispatch();
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);

//   useEffect(() => {
//     dispatch(getAllPoc());
//   }, [dispatch]);

//   const handleEditClick = (pocs) => {
//     setEditPoc(pocs);
//     setOpenEdit(true);
//   };

//   const handleEditChange = (e) => {
//     const { name, value } = e.target;
//     setEditPoc({ ...editPoc, [name]: value });
//   };

//   const handleEditSubmit = async () => {
//     dispatch(updatePoc(editPoc._id, editPoc)).then((result) => {
//       if (result.success) {
//         dispatch(getAllPoc());
//         setOpenEdit(false);
//       }
//     });
//   };

//   const handleCloseEdit = () => {
//     setOpenEdit(false);
//   };

//   const columns = [
//     { field: 'companyName', headerName: 'COMPANY NAME', flex: 1, headerAlign: 'center', align: 'center' },
//     { field: 'name', headerName: 'POC NAME', flex: 1, headerAlign: 'center', align: 'center' },
//     { field: 'email', headerName: 'EMAIL', flex: 1, headerAlign: 'center', align: 'center' },
//     { field: 'phone', headerName: 'PHONE', flex: 1, headerAlign: 'center', align: 'center' },
//     { field: 'role', headerName: 'ROLE', flex: 1, headerAlign: 'center', align: 'center' },
//     {
//       field: 'profile',
//       headerName: 'PAYMENT',
//       flex: 1,
//       headerAlign: 'center',
//       align: 'center',
//       renderCell: (params) => (
//         <Button variant="outlined" sx={{ color: colors.grey[100], borderColor: colors.grey[100] }} component={Link} to={`/admin/pocProfile/${params.row._id}`}>
//           PAYMENT
//         </Button>
//       )
//     },
//     {
//       field: 'actions',
//       headerName: 'ACTIONS',
//       flex: 1,
//       headerAlign: 'center',
//       align: 'center',
//       renderCell: (params) => (<>
//         <Button variant="outlined" sx={{ color: colors.grey[100], borderColor: colors.grey[100], marginLeft: 1 }} onClick={() => handleEditClick(params.row)}>
//           <FaEdit />
//         </Button>
//         </>
//       )
//     }
//   ];

//   const rows = poc.poc ? poc.poc.map((pocItem, index) => ({ id: index, ...pocItem })) : [];

//   return (
//     <>
//     <br />
//     <Box sx={{ height: 600, width: '99%', minWidth: '85%' }}>
//     <Typography variant="h4" align="center" gutterBottom sx={{ color: colors.grey[100] }}>
//         All POC
//       </Typography>
//     <Grid container justifyContent="center" spacing={1} style={{ marginTop: '20px' }}>
//         <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
//           <Box width="80%" maxWidth="300px" boxShadow={5} borderRadius={5} bgcolor={colors.primary[300]} color="success.contrastText" p={1}>
//             <Typography variant="h5" align="center">Total Paid Amount</Typography>
//             <Typography variant="h5" align="center">{totalReceivedPayment}</Typography>
//           </Box>
//         </Grid>
//         <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
//           <Box width="80%" maxWidth="300px" boxShadow={5} borderRadius={5} bgcolor={colors.primary[300]} color="success.contrastText" p={1}>
//             <Typography variant="h5" align="center">Total Remaining Amount</Typography>
//             <Typography variant="h5" align="center">{totalPendingPayment}</Typography>
//           </Box>
//         </Grid>
//         <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
//           <Box width="80%" maxWidth="300px" boxShadow={5} borderRadius={5} bgcolor={colors.primary[300]} color="success.contrastText" p={1}>
//             <Typography variant="h5" align="center">This Month Paid Amount</Typography>
//             <Typography variant="h5" align="center">{currentReceivedPayment}</Typography>
//           </Box>
//         </Grid>
//         <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
//           <Box width="80%" maxWidth="300px" boxShadow={5} borderRadius={5} bgcolor={colors.primary[300]} color="success.contrastText" p={1}>
//             <Typography variant="h5" align="center">This Month Remaining Amount</Typography>
//             <Typography variant="h5" align="center">{currentPendingPayment}</Typography>
//           </Box>
//         </Grid>
//       </Grid>
//       <br />
//         <DataGrid
//           rows={rows}
//           columns={columns}
//           pageSize={10}
//           rowsPerPageOptions={[10]}
//           disableSelectionOnClick
//           sx={{
//             '.MuiDataGrid-root': {
//               bgcolor: colors.primary[600],
//             },
//             '.MuiDataGrid-cell': {
//               color: colors.grey[100],
//             },
//             '.MuiDataGrid-columnHeader': {
//               bgcolor: colors.primary[700],
//               color: colors.greenAccent[500],
//             },
//             '.MuiCheckbox-root': {
//               color: colors.grey[100],
//             },
//             '& .Mui-disabled': {
//               pointerEvents: 'none',
//               opacity: 0.6,
//             },
//           }}
//         />
//       </Box>
   
 
    
//       <EditPoc
//         open={openEdit}
//         handleClose={handleCloseEdit}
//         pocs={editPoc}
//         handleEditChange={handleEditChange}
//         handleEditSubmit={handleEditSubmit}
//       />
//     </>
//   );
// };

// export default BasicTable;


import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Box, Typography, useTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, MenuItem, Select, InputLabel } from '@mui/material';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import EditPoc from './EditPoc';
import { FaEdit } from 'react-icons/fa';
import { getAllPoc, updatePoc, addCompany, addPoc, getAllCompany } from '../../actions/Admin';
import toast from 'react-hot-toast';
import { clearError } from '../../reducers/Admin';

const PocTable = () => {
  const { poc, companies, error } = useSelector((state) => state.admin);
  const [editPoc, setEditPoc] = useState({});
  const [openEdit, setOpenEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [formType, setFormType] = useState('');
  const [company, setCompany] = useState({ name: '' });
  const [formData, setFormData] = useState({
    name: '',
    companyId: '',
    taskType: '',
    email: '',
    phone: '',
    password: '',
    interview: "",
    tillPlacementInterview: "",
    jdSession: "",
    assessment: "",
    ruSession: "",
    training: "",
    resumeBuilding: "",
  });
  const { totalPendingPayment, totalReceivedPayment, currentPendingPayment, currentReceivedPayment } = poc;
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    dispatch(getAllPoc());
    dispatch(getAllCompany());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
  }, [error, dispatch]);

  const handleEditClick = (pocs) => {
    setEditPoc(pocs);
    setOpenEdit(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditPoc({ ...editPoc, [name]: value });
  };

  const handleEditSubmit = async () => {
    dispatch(updatePoc(editPoc._id, editPoc)).then((result) => {
      if (result.success) {
        dispatch(getAllPoc());
        setOpenEdit(false);
      }
    });
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleClose = () => {
    setOpen(false);
    setFormType('');
    setCompany({ name: '' });
    setFormData({
      name: '',
      companyId: '',
      taskType: '',
      email: '',
      phone: '',
      password: '',
      interview: "",
      tillPlacementInterview: "",
      jdSession: "",
      assessment: "",
      ruSession: "",
      training: "",
      resumeBuilding: "",
    });
  };

  const handleCompanyChange = (e) => {
    setCompany({ ...company, [e.target.name]: e.target.value });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmitCompany = async (e) => {
    e.preventDefault();
    const result = await dispatch(addCompany(company));
    if (result.success) {
      toast.success('Company added successfully!');
      handleClose();
    } else {
      toast.error(result.message);
    }
  };

  const handleSubmitPoc = async (e) => {
    e.preventDefault();
    const result = await dispatch(addPoc(formData));
    if (result.success) {
      toast.success('POC added successfully!');
      handleClose();
    } else {
      toast.error(result.message);
    }
  };

  const columns = [
    { field: 'companyName', headerName: 'COMPANY NAME', flex: 1, headerAlign: 'center', align: 'center' },
    { field: 'name', headerName: 'POC NAME', flex: 1, headerAlign: 'center', align: 'center' },
    { field: 'email', headerName: 'EMAIL', flex: 1, headerAlign: 'center', align: 'center' },
    { field: 'phone', headerName: 'PHONE', flex: 1, headerAlign: 'center', align: 'center' },
    { field: 'role', headerName: 'ROLE', flex: 1, headerAlign: 'center', align: 'center' },
    {
      field: 'profile',
      headerName: 'PAYMENT',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Button variant="outlined" sx={{ color: colors.grey[100], borderColor: colors.grey[100] }} component={Link} to={`/admin/pocProfile/${params.row._id}`}>
          PAYMENT
        </Button>
      )
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Button variant="outlined" sx={{ color: colors.grey[100], borderColor: colors.grey[100], marginLeft: 1 }} onClick={() => handleEditClick(params.row)}>
          <FaEdit />
        </Button>
      )
    }
  ];

  const rows = poc.poc ? poc.poc.map((pocItem, index) => ({ id: index, ...pocItem })) : [];

  const sx={
              '& .MuiInputLabel-root': {
                color: colors.greenAccent[500],
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: colors.greenAccent[500],
              },
              '& .MuiOutlinedInput-root': {
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.greenAccent[500],
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.greenAccent[500],
                },
                '& input::placeholder': {
                  color: colors.greenAccent[500],
                },
              },
            }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px',minWidth:"80%",marginLeft:"15rem"}}>
<Typography>
  ALL POC
</Typography>
      <Grid container justifyContent="center" spacing={1} style={{ marginTop: '20px' }}>
        <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
          <Box width="80%" maxWidth="300px" boxShadow={5} borderRadius={5} bgcolor={colors.primary[300]} color="success.contrastText" p={1}>
            <Typography variant="h5" align="center">Total Paid Amount</Typography>
            <Typography variant="h5" align="center">{totalReceivedPayment}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
          <Box width="80%" maxWidth="300px" boxShadow={5} borderRadius={5} bgcolor={colors.primary[300]} color="success.contrastText" p={1}>
            <Typography variant="h5" align="center">Total Remaining Amount</Typography>
            <Typography variant="h5" align="center">{totalPendingPayment}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
          <Box width="80%" maxWidth="300px" boxShadow={5} borderRadius={5} bgcolor={colors.primary[300]} color="success.contrastText" p={1}>
            <Typography variant="h5" align="center">This Month Paid Amount</Typography>
            <Typography variant="h5" align="center">{currentReceivedPayment}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
          <Box width="80%" maxWidth="300px" boxShadow={5} borderRadius={5} bgcolor={colors.primary[300]} color="success.contrastText" p={1}>
            <Typography variant="h5" align="center">This Month Remaining Amount</Typography>
            <Typography variant="h5" align="center">{currentPendingPayment}</Typography>
          </Box>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2} justifyContent="center" style={{ marginBottom: '20px' }}>
        <Grid item>
          <Button
            variant="outlined"
            onClick={() => { setOpen(true); setFormType('company'); }}
            sx={{ color: colors.grey[100], borderColor: colors.grey[100] }}
          >
            Add Company
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            onClick={() => { setOpen(true); setFormType('poc'); }}
            sx={{ color: colors.grey[100], borderColor: colors.grey[100] }}
          >
            Add POC
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ height: 500, width: '99%', minWidth: '85%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
         sx={sx}
         
        />
      </Box>
      <EditPoc
        open={openEdit}
        handleClose={handleCloseEdit}
        pocs={editPoc}
        handleEditChange={handleEditChange}
        handleEditSubmit={handleEditSubmit}
      />

      <Dialog open={open && formType === 'company'} onClose={handleClose} aria-labelledby="company-form-dialog-title">
        <DialogTitle id="company-form-dialog-title" sx={{ backgroundColor: colors.primary[600], color: colors.grey[100] }}>
          Add Company
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: colors.primary[700], color: colors.grey[100] }}>
          <DialogContentText>
            Please fill in the details to add a new company.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Company Name"
            placeholder="Enter Company Name"
            type="text"
            fullWidth
            value={company.name}
            onChange={handleCompanyChange}
            sx={sx}
          />
        </DialogContent>
        <DialogActions sx={{ backgroundColor: colors.primary[700] }}>
          <Button onClick={handleClose} variant="outlined" sx={{ color: "red", borderColor: "red" }}>
            Cancel
          </Button>
          <Button onClick={handleSubmitCompany} variant="outlined" sx={{ color: colors.greenAccent[500], borderColor: colors.greenAccent[500] }}>
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open && formType === 'poc'} onClose={handleClose} aria-labelledby="poc-form-dialog-title">
        <DialogTitle id="poc-form-dialog-title" sx={{ backgroundColor: colors.primary[600], color: colors.grey[100] }}>
          Add Point Of Contact
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: colors.primary[700], color: colors.grey[100] }}>
          <DialogContentText>
            Please fill in the details for the Point Of Contact.
          </DialogContentText>
          <InputLabel id="company-id-label" sx={{ color: colors.grey[100] }}>Select Company</InputLabel>
          <Select
            labelId="company-id-label"
            id="company-id"
            required
            name="companyId"
            value={formData.companyId}
            onChange={handleChange}
            fullWidth
            sx={{ color: colors.grey[100] }}
          >
            {companies && companies.map((com) => (
              <MenuItem key={com._id} value={com._id}>{com.name}</MenuItem>
            ))}
          </Select>
          <TextField
            margin="dense"
            id="poc-name"
            name="name"
            label="POC Name"
            type="text"
            required
            fullWidth
            value={formData.name}
            onChange={handleChange}
            sx={sx}
          />
          <TextField
            margin="dense"
            id="poc-email"
            name="email"
            label="Email"
            type="email"
            required
            fullWidth
            value={formData.email}
            onChange={handleChange}
            sx={sx}
          />
          <TextField
            margin="dense"
            id="poc-mobile"
            name="phone"
            label="Mobile"
            type="text"
            required
            fullWidth
            value={formData.phone}
            onChange={handleChange}
            sx={sx}
          />
          <TextField
            margin="dense"
            id="poc-password"
            name="password"
            label="Password"
            type="password"
            required
            fullWidth
            value={formData.password}
            onChange={handleChange}
            sx={sx}
          />
          <TextField
            margin="dense"
            id="Interview-Price"
            label="Interview Price"
            type="number"
            name="interview"
            fullWidth
            value={formData.interview}
            onChange={handleChange}
            sx={sx}
          />
          <TextField
            margin="dense"
            id="Till-Placement-Interview-Price"
            label="Till Placement Interview Price"
            type="number"
            name="tillPlacementInterview"
            fullWidth
            value={formData.tillPlacementInterview}
            onChange={handleChange}
            sx={sx}
          />
          <TextField
            margin="dense"
            id="JD-Session-Price"
            label="JD Session Price"
            type="number"
            name="jdSession"
            fullWidth
            value={formData.jdSession}
            onChange={handleChange}
            sx={sx}
          />
          <TextField
            margin="dense"
            id="assessment-price"
            label="Assessment Price"
            type="number"
            name="assessment"
            fullWidth
            value={formData.assessment}
            onChange={handleChange}
            sx={sx}
          />
          <TextField
            margin="dense"
            id="ru-session-price"
            label="RU Session Price"
            type="number"
            name="ruSession"
            fullWidth
            value={formData.ruSession}
            onChange={handleChange}
            sx={sx}
          />
          <TextField
            margin="dense"
            id="training-price"
            label="Training Price"
            type="number"
            fullWidth
            name="training"
            value={formData.training}
            onChange={handleChange}
            sx={sx}
          />
          <TextField
            margin="dense"
            id="resume-building-price"
            label="Resume Building Price"
            type="number"
            fullWidth
            name="resumeBuilding"
            value={formData.resumeBuilding}
            onChange={handleChange}
            sx={sx}
          />
        </DialogContent>
        <DialogActions sx={{ backgroundColor: colors.primary[700] }}>
          <Button onClick={handleClose} variant="outlined" sx={{ color: "red", borderColor: "red" }}>
            Cancel
          </Button>
          <Button onClick={handleSubmitPoc} variant="outlined" sx={{ color: colors.greenAccent[500], borderColor: colors.greenAccent[500] }}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default PocTable;
