// import React, { useEffect, useState } from 'react';
// import { Route, Routes, Navigate } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
// import LoginForm from './component/Login/index';
// import DashboardAdmin from './component/Admin/DashboardAdmin';
// import DashboardExpert from './component/Expert/DashboardExpert';
// import DashboardPOC from './component/POC/DashboardPOC';
// import Loading from './component/Loading/Loading';
// import ProtectedRoute from './component/Protectred/ProtectedRoute';
// import { loadUser } from './actions/User';
// import CompanyTasks from './component/Admin/CompanyTasks';
// import NotFound from './component/NotFound/NotFound';
// import AddCompanyPOC from './component/Admin/AddPoc';
// import AddExpert from './component/Admin/AddExpert';
// import AddCandidate from './component/Admin/AddCandidate';
// import AddPCandidate from './component/POC/AddCandiate';
// import MakePayment from './component/POC/MakePayment';
// import PaymentSuccess from './component/POC/PaymentSuccess';
// import InvoiceGenerator from './component/Admin/Invoice';
// import ManageTasks from './component/Admin/ManageTasks';
// import PocProfile from './component/Admin/PocProfile';
// import Sidebar from './component/layout/Sidebar';
// import CSidebar from './component/layout/CsideBar';
// import ESidebar from './component/layout/Esidebar';
// import { ColorModeContext, useMode } from './theme';
// import { CssBaseline, ThemeProvider } from '@mui/material';
// import ExpertProfile from './component/Admin/ExpertProfile';
// import AllTask from './component/POC/AllTAsks';
// import Payment from './component/Expert/Payment';

// function App() {
//   const [theme, colorMode] = useMode();
//   const { role, isAuthenticated, isLoading } = useSelector((state) => state.user);
//   const dispatch = useDispatch();
//   const [isSidebarOpen, setSidebarOpen] = useState(true);

//   useEffect(() => {
//     const token = localStorage.getItem('admin');
//     const role = localStorage.getItem('userType');
//     if (token && role) {
//       dispatch(loadUser());
//     }
//   }, [dispatch]);

//   const toggleSidebar = () => {
//     setSidebarOpen(!isSidebarOpen);
//   };

//   const renderSidebar = () => {
//     switch (role) {
//       case 'admin':
//         return <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />;
//       case 'poc':
//         return <CSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />;
//       case 'expert':
//         return <ESidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />;
//       default:
//         return null;
//     }
//   };

//   return (
//       <ColorModeContext.Provider value={colorMode}>
//         <ThemeProvider theme={theme}>
//           <CssBaseline />
//           {isLoading ? (
//             <p>loading.....</p>
//           ) : (
//             <div className="app" style={{ display: 'flex' }}>
//               {isAuthenticated && renderSidebar()}
//               <div style={{ flexGrow: 1, marginLeft: isSidebarOpen ? '10rem' : '10px'}}>
//                 <Routes>
//                   <Route
//                     path="/"
//                     element={
//                       isAuthenticated ? (
//                         <Navigate to={`/${role}/dashboard`} />
//                       ) : (
//                         <LoginForm />
//                       )
//                     }
//                   />
//                   <Route path="/razorpay" element={<Loading />} />
//                   <Route path="/invoice" element={<InvoiceGenerator />} />
//                   {/* ADMIN ROUTES */}
//                   <Route element={<ProtectedRoute allowedRoles={['admin']} />}>
//                     <Route path="/admin/dashboard" element={<DashboardAdmin />} />
//                     <Route path="/admin/add/poc/company" element={<AddCompanyPOC isSidebarOpen={isSidebarOpen} />} />
//                     <Route path="/admin/pocProfile/:id" element={<PocProfile />} />
//                     <Route path="/admin/expertProfile/:id" element={<ExpertProfile />} />
//                     <Route path="/admin/add/expert" element={<AddExpert />} />
//                     <Route path="/admin/add/candidate" element={<AddCandidate />} />
//                     <Route path="/admin/companyTasks" element={<CompanyTasks />} />
//                     <Route path="/admin/manageTasks" element={<ManageTasks />} />
//                   </Route>
//                   {/* POC ROUTES */}
//                   <Route element={<ProtectedRoute allowedRoles={['poc']} />}>
//                     <Route path="/poc/dashboard" element={<DashboardPOC />} />
//                     <Route path="/poc/alltask" element={<AllTask/>} />
//                     <Route path="/poc/add/candidate" element={<AddPCandidate />} />
//                     <Route path="/poc/make/payment" element={<MakePayment />} />
//                   </Route>
//                   <Route path="/paymentsuccess" element={<PaymentSuccess />} />
//                   {/* EXPERT ROUTES */}
//                   <Route element={<ProtectedRoute allowedRoles={['expert']} />}>
//                     <Route path="/expert/dashboard" element={<DashboardExpert />} />
//                     <Route path="/expert/payment" element={<Payment />} />
//                   </Route>
//                   <Route path="*" element={<NotFound />} />
//                 </Routes>
//               </div>
//             </div>
//           )}
//         </ThemeProvider>
//       </ColorModeContext.Provider>
//   );
// }

// export default App;



// import React, { useEffect, useState } from 'react';
// import { Route, Routes, Navigate } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
// import LoginForm from './component/Login/index';
// import DashboardAdmin from './component/Admin/DashboardAdmin';
// import DashboardExpert from './component/Expert/DashboardExpert';
// import DashboardPOC from './component/POC/DashboardPOC';
// import Loading from './component/Loading/Loading';
// import ProtectedRoute from './component/Protectred/ProtectedRoute';
// import { loadUser } from './actions/User';
// import CompanyTasks from './component/Admin/CompanyTasks';
// import NotFound from './component/NotFound/NotFound';
// import AddCompanyPOC from './component/Admin/AddPoc';
// import AddExpert from './component/Admin/AddExpert';
// import AddCandidate from './component/Admin/AddCandidate';
// import AddPCandidate from './component/POC/AddCandiate';
// import MakePayment from './component/POC/MakePayment';
// import PaymentSuccess from './component/POC/PaymentSuccess';
// import InvoiceGenerator from './component/Admin/Invoice';
// import ManageTasks from './component/Admin/ManageTasks';
// import PocProfile from './component/Admin/PocProfile';
// import Sidebar from './component/layout/Sidebar';
// import CSidebar from './component/layout/CsideBar';
// import ESidebar from './component/layout/Esidebar';
// import { ColorModeContext, useMode } from './theme';
// import { CssBaseline, ThemeProvider } from '@mui/material';
// import ExpertProfile from './component/Admin/ExpertProfile';
// import AllTask from './component/POC/AllTAsks';
// import Payment from './component/Expert/Payment';
// import "./component/layout/responsive.css"

// function App() {
//   const [theme, colorMode] = useMode();
//   const { role, isAuthenticated, isLoading } = useSelector((state) => state.user);
//   const dispatch = useDispatch();
//   const [isSidebarOpen, setSidebarOpen] = useState(true);
//   const [sidebarClass, setSidebarClass] = useState('sidebar-expanded');

//   useEffect(() => {
//     const token = localStorage.getItem('admin');
//     const role = localStorage.getItem('userType');
//     if (token && role) {
//       dispatch(loadUser());
//     }
//   }, [dispatch]);

//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth / window.outerWidth > 1.3) {
//         setSidebarOpen(false);
//         setSidebarClass('sidebar-collapsed');
//       } else {
//         setSidebarOpen(true);
//         setSidebarClass('sidebar-expanded');
//       }
//     };

//     window.addEventListener('resize', handleResize);
//     handleResize();

//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);

//   const toggleSidebar = () => {
//     setSidebarOpen(!isSidebarOpen);
//   };

//   const renderSidebar = () => {
//     switch (role) {
//       case 'admin':
//         return <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />;
//       case 'poc':
//         return <CSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />;
//       case 'expert':
//         return <ESidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />;
//       default:
//         return null;
//     }
//   };

//   return (
//     <ColorModeContext.Provider value={colorMode}>
//       <ThemeProvider theme={theme}>
//         <CssBaseline />
//         {isLoading ? (
//           <p>loading.....</p>
//         ) : (
//           <div className="app" style={{ display: 'flex' }}>
//             {isAuthenticated && renderSidebar()}
//             <div className={`content ${sidebarClass}`} style={{ flexGrow:1.5}}>
//               <Routes>
//                 <Route
//                   path="/"
//                   element={
//                     isAuthenticated ? (
//                       <Navigate to={`/${role}/dashboard`} />
//                     ) : (
//                       <LoginForm  />
//                     )
//                   }
//                 />
//                 <Route path="/razorpay" element={<Loading />} />
//                 <Route path="/invoice" element={<InvoiceGenerator />} />
//                 {/* ADMIN ROUTES */}
//                 <Route element={<ProtectedRoute allowedRoles={['admin']} />}>
//                   <Route path="/admin/dashboard" element={<DashboardAdmin />} />
//                   <Route path="/admin/add/poc/company" element={<AddCompanyPOC isSidebarOpen={isSidebarOpen} />} />
//                   <Route path="/admin/pocProfile/:id" element={<PocProfile />} />
//                   <Route path="/admin/expertProfile/:id" element={<ExpertProfile />} />
//                   <Route path="/admin/add/expert" element={<AddExpert />} />
//                   <Route path="/admin/add/candidate" element={<AddCandidate />} />
//                   <Route path="/admin/companyTasks" element={<CompanyTasks />} />
//                   <Route path="/admin/manageTasks" element={<ManageTasks />} />
//                 </Route>
//                 {/* POC ROUTES */}
//                 <Route element={<ProtectedRoute allowedRoles={['poc']} />}>
//                   <Route path="/poc/dashboard" element={<DashboardPOC />} />
//                   <Route path="/poc/alltask" element={<AllTask/>} />
//                   <Route path="/poc/add/candidate" element={<AddPCandidate />} />
//                   <Route path="/poc/make/payment" element={<MakePayment />} />
//                 </Route>
//                 <Route path="/paymentsuccess" element={<PaymentSuccess />} />
//                 {/* EXPERT ROUTES */}
//                 <Route element={<ProtectedRoute allowedRoles={['expert']} />}>
//                   <Route path="/expert/dashboard" element={<DashboardExpert />} />
//                   <Route path="/expert/payment" element={<Payment />} />
//                 </Route>
//                 <Route path="*" element={<NotFound />} />
//               </Routes>
//             </div>
//           </div>
//         )}
//       </ThemeProvider>
//     </ColorModeContext.Provider>
//   );
// }

// export default App;


import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import LoginForm from './component/Login/index';
import DashboardAdmin from './component/Admin/DashboardAdmin';
import DashboardExpert from './component/Expert/DashboardExpert';
import DashboardPOC from './component/POC/DashboardPOC';
import Loading from './component/Loading/Loading';
import ProtectedRoute from './component/Protectred/ProtectedRoute';
import { loadUser } from './actions/User';
import CompanyTasks from './component/Admin/CompanyTasks';
import NotFound from './component/NotFound/NotFound';
import AddCompanyPOC from './component/Admin/AddPoc';
import AddExpert from './component/Admin/AddExpert';
import AddCandidate from './component/Admin/AddCandidate';
import AddPCandidate from './component/POC/AddCandiate';
import MakePayment from './component/POC/MakePayment';
import PaymentSuccess from './component/POC/PaymentSuccess';
import InvoiceGenerator from './component/Admin/Invoice';
import ManageTasks from './component/Admin/ManageTasks';
import PocProfile from './component/Admin/PocProfile';
import Sidebar from './component/layout/Sidebar';
import CSidebar from './component/layout/CsideBar';
import ESidebar from './component/layout/Esidebar';
import { ColorModeContext, useMode } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import ExpertProfile from './component/Admin/ExpertProfile';
import AllTask from './component/POC/AllTAsks';
import Payment from './component/Expert/Payment';
import "./component/layout/responsive.css"



function App() {
  const [theme, colorMode] = useMode();
  const { role, isAuthenticated, isLoading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [sidebarClass, setSidebarClass] = useState('sidebar-expanded');

  useEffect(() => {
    const token = localStorage.getItem('admin');
    const role = localStorage.getItem('userType');
    if (token && role) {
      dispatch(loadUser());
    }
  }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth / window.outerWidth > 1.3) {
        setSidebarOpen(false);
        setSidebarClass('sidebar-collapsed');
      } else {
        setSidebarOpen(true);
        setSidebarClass('sidebar-expanded');
      }
    };


    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const renderSidebar = () => {
    switch (role) {
      case 'admin':
        return <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />;
      case 'poc':
        return <CSidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />;
      case 'expert':
        return <ESidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />;
      default:
        return null;
    } 
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {isLoading ? (
          <p>loading.....</p>
        ) : (
          <div className="app" style={{ display: 'flex' }}>
            {isAuthenticated && renderSidebar()}
            <div className={`content ${sidebarClass}`}>
              <Routes>
                <Route
                  path="/"
                  element={
                    isAuthenticated ? (
                      <Navigate to={`/${role}/dashboard`} />
                    ) : (
                      <LoginForm />
                    )
                  }
                />
                <Route path="/razorpay" element={<Loading />} />
                <Route path="/invoice" element={<InvoiceGenerator />} />
                {/* ADMIN ROUTES */}
                <Route element={<ProtectedRoute allowedRoles={['admin']} />}>
                  <Route path="/admin/dashboard" element={<DashboardAdmin />} />
                  <Route path="/admin/add/poc/company" element={<AddCompanyPOC isSidebarOpen={isSidebarOpen} />} />
                  <Route path="/admin/pocProfile/:id" element={<PocProfile />} />
                  <Route path="/admin/expertProfile/:id" element={<ExpertProfile />} />
                  <Route path="/admin/add/expert" element={<AddExpert />} />
                  <Route path="/admin/add/candidate" element={<AddCandidate />} />
                  <Route path="/admin/companyTasks" element={<CompanyTasks />} />
                  <Route path="/admin/manageTasks" element={<ManageTasks />} />
                </Route>
                {/* POC ROUTES */}
                <Route element={<ProtectedRoute allowedRoles={['poc']} />}>
                  <Route path="/poc/dashboard" element={<DashboardPOC />} />
                  <Route path="/poc/alltask" element={<AllTask/>} />
                  <Route path="/poc/add/candidate" element={<AddPCandidate />} />
                  <Route path="/poc/make/payment" element={<MakePayment />} />
                  <Route path="/poc/payment/success" element={<PaymentSuccess />} />
                </Route>
                <Route path="/paymentsuccess" element={<PaymentSuccess />} />
                {/* EXPERT ROUTES */}
                <Route element={<ProtectedRoute allowedRoles={['expert']} />}>
                  <Route path="/expert/dashboard" element={<DashboardExpert />} />
                  <Route path="/expert/payment" element={<Payment />} />
                </Route>
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </div>
        )}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
