import React from 'react'
import ExpertTasks from './TodayTask'
 
const DashboardExpert = () => {
  return (
    <>
    <ExpertTasks/>
    </>
  ) 
}

export default DashboardExpert