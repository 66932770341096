// import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { addTask } from '../../actions/POC';
// import {
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Button,
//   Select,
//   MenuItem,
//   InputLabel,
//   TextField,
//   FormControl,
//   useTheme
// } from '@mui/material';

// import { tokens } from '../../theme';
// import { Box } from '@mui/system';
// const AddTask = ({ open, handleClose }) => {
//   const dispatch = useDispatch();
//   const taskTypes = [
//     { label: 'INTERVIEW', value: 'interview' },
//     { label: 'ASSESSMENT', value: 'assessment' },
//     { label: 'JD SESSION', value: 'jdSession' },
//     { label: 'RU SESSION', value: 'ruSession' },
//     { label: 'RESUME BUILDING', value: 'resumeBuilding' },
//     { label: 'TRAINING', value: 'training' },
//   ];

//   const theme=useTheme();
//   const colors=tokens(theme.palette.mode);
//   const { candidates } = useSelector((state) => state.poc);

//   const [formData, setFormData] = useState({
//     candidateId: '',
//     taskType: '',
//     ISTTIME: '',
//     minute: 0,
//     hour: 0,
//     cv:null
//   });

//   const handleChange = (e) => {
//     const { name, value, files } = e.target;
//     setFormData((prevData) => ({
//             ...prevData,
//             [name]: files && files.length > 0 ? files[0] : value,
//         }));
//   };

//   const handleSubmit = () => {
//     const formDataToSubmit=new FormData();
//     Object.entries(formData).forEach(([key,value])=>{
//               formDataToSubmit.append(key,value);
//     })
//     dispatch(addTask(formDataToSubmit));
//     handleClose();
//   };

//   return (
//     <Dialog open={open} onClose={handleClose}>
//       <DialogTitle align='center' color={"secondary"}> <strong>ADD TASK</strong></DialogTitle>
//       <DialogContent >
        
//           <InputLabel id="candidateId-label"><strong>Select Candidate </strong></InputLabel>
//           <Select fullWidth
//           search 
//             name="candidateId"
//             value={formData.candidateId}
//             onChange={handleChange}
//           >
//             {candidates &&
//               candidates.map((candidate) => (
//                 <MenuItem key={candidate._id} value={candidate._id}>
//                   {candidate.name}
//                 </MenuItem>
//               ))}
//           </Select>
//         <br /><br />
//           <InputLabel id="taskType-label"><strong>Task Type</strong></InputLabel>
//           <Select fullWidth margin="normal"
//             labelId="taskType-label"
//             name="taskType"
//             value={formData.taskType}
//             onChange={handleChange}
//           >
//             {taskTypes &&
//               taskTypes.map((type) => (
//                 <MenuItem key={type.value} value={type.value}>
//                   {type.label}
//                 </MenuItem>
//               ))}
//           </Select>
//           <br /><br /> 
//         <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
//           <img src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/255px-Flag_of_India.svg.png" height="40rem" width="60px" alt="IND FLAG" />
//           <TextField
//             margin="dense"
//             name="ISTTIME"
//             label="IST TIME"
//             type="datetime-local"
//             fullWidth
//             value={formData.ISTTIME}
//             onChange={handleChange}
//             InputLabelProps={{
//               shrink: true,
//             }}
//             sx={{ marginLeft: 2 }}
//           />
//         </Box>
//         <br /><br />
//         <InputLabel><strong>Task Duration</strong></InputLabel>
//             <FormControl  margin="dense">
//           <TextField
//             margin="dense"
//             label="Hour"
//             name="hour"
//             type="number"
//             fullWidth
//             value={formData.hour}
//             onChange={handleChange}
//           />
//         </FormControl>
        
//         <FormControl  margin="dense">
//           <TextField
//             margin="dense"
//             label="Minut"
//             name="minute"
//             type="number"
//             value={formData.minute}
//             onChange={handleChange}
//           />
//         </FormControl>
//         <br />
//         <br />
//         <InputLabel><strong>Optional Document</strong></InputLabel> 
//         <TextField
//           margin="dense"
//           name="cv"
//           type="file"
//           fullWidth
//           onChange={handleChange}
//         />
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={handleClose} variant="outlined" sx={{ color:"red", borderColor: "red" }}>
//           Cancel
//         </Button>
//         <Button onClick={handleSubmit} variant="outlined" sx={{ color:colors.greenAccent[500], borderColor:colors.greenAccent[500]  }}>
//           Add Task
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default AddTask;


import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addTask } from '../../actions/POC';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  FormControl,
  useTheme,
  Autocomplete,
  Box
} from '@mui/material';

import { tokens } from '../../theme';

const AddTask = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const taskTypes = [
    { label: 'INTERVIEW', value: 'interview' },
    { label: 'ASSESSMENT', value: 'assessment' },
    { label: 'JD SESSION', value: 'jdSession' },
    { label: 'RU SESSION', value: 'ruSession' },
    { label: 'RESUME BUILDING', value: 'resumeBuilding' },
    { label: 'TRAINING', value: 'training' },
  ];

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { candidates } = useSelector((state) => state.poc);

  const [formData, setFormData] = useState({
    candidateId: '',
    taskType: '',
    ISTTIME: '',
    minute: 0,
    hour: 0,
    cv: null
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files && files.length > 0 ? files[0] : value,
    }));
  };

  const handleCandidateChange = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      candidateId: newValue ? newValue._id : '',
    }));
  };

  const handleSubmit = () => {
    const formDataToSubmit = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSubmit.append(key, value);
    });
    dispatch(addTask(formDataToSubmit));
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle align='center' color={"secondary"}><strong>ADD TASK</strong></DialogTitle>
      <DialogContent>

        <InputLabel id="candidateId-label"><strong>Select Candidate </strong></InputLabel>
        <Autocomplete
          fullWidth
          options={candidates || []}
          getOptionLabel={(option) => option.name}
          onChange={handleCandidateChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Candidate"
              margin="dense"
              variant="outlined"
            />
          )}
        />
        <br /><br />
        <InputLabel id="taskType-label"><strong>Task Type</strong></InputLabel>
        <Select
          fullWidth
          margin="normal"
          labelId="taskType-label"
          name="taskType"
          value={formData.taskType}
          onChange={handleChange}
        >
          {taskTypes.map((type) => (
            <MenuItem key={type.value} value={type.value}>
              {type.label}
            </MenuItem>
          ))}
        </Select>
        <br /><br />
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
          <img src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/255px-Flag_of_India.svg.png" height="40rem" width="60px" alt="IND FLAG" />
          <TextField
            margin="dense"
            name="ISTTIME"
            label="IST TIME"
            type="datetime-local"
            fullWidth
            value={formData.ISTTIME}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ marginLeft: 2 }}
          />
        </Box>
        <br /><br />
        <InputLabel><strong>Task Duration</strong></InputLabel>
        <FormControl margin="dense">
          <TextField
            margin="dense"
            label="Hour"
            name="hour"
            type="number"
            fullWidth
            value={formData.hour}
            onChange={handleChange}
          />
        </FormControl>

        <FormControl margin="dense">
          <TextField
            margin="dense"
            label="Minute"
            name="minute"
            type="number"
            value={formData.minute}
            onChange={handleChange}
          />
        </FormControl>
        <br /><br />
        <InputLabel><strong>Optional Document</strong></InputLabel>
        <TextField
          margin="dense"
          name="cv"
          type="file"
          fullWidth
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" sx={{ color: "red", borderColor: "red" }}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="outlined" sx={{ color: colors.greenAccent[500], borderColor: colors.greenAccent[500] }}>
          Add Task
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTask;
