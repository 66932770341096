import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, useTheme,Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import { getAllExpert, addExpert } from '../../actions/Admin';
import BasicTable from "./ExpertTable";
import { tokens } from '../../theme';
import toast from 'react-hot-toast';

function AddExpert() {
    const [open, setOpen] = useState(false);
    const [formType, setFormType] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        password: '',
        technology: '',
        additionalSkills: '',
        interview: "",
        tillPlacementInterview: "",
        jdSession: "",
        assessment: "",
        ruSession: "",
        training: "",
        resumeBuilding: ""
    });
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        dispatch(getAllExpert());
    }, [dispatch]);

    const handleClose = () => {
        setOpen(false);
        setFormType('');
        setFormData({
            name: '',
            email: '',
            phone: '',
            password: '',
            technology: '',
            additionalSkills: '',
            interview: "",
            tillPlacementInterview: "",
            jdSession: "",
            assessment: "",
            ruSession: "",
            training: "",
            resumeBuilding: ""
        });
    };



    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmitExpert = async (e) => {
        e.preventDefault();
        const result = await dispatch(addExpert(formData));
        console.log(result);
        if (result.success) {
            toast.success('Expert added successfully!');
            handleClose();
        } else {
            toast.error(result.message);
        }
    };

    let sx={
                                '& .MuiInputLabel-root': {
                                    color: colors.greenAccent[500],
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: colors.greenAccent[500],
                                },
                                '& .MuiOutlinedInput-root': {
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.greenAccent[500],
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.greenAccent[500],
                                    },
                                    '& input::placeholder': {
                                        color: colors.greenAccent[500],
                                    },
                                },
                            }

const technologyOptions = [
        "Business Analyst",
        "Financial Analyst",
        "Project Manager",
        "Scrum Master",
        "Data Analyst",
        "Data Engineer",
        "Data Scientists",
        "Java Developer",
        "Java Full Stack Developer",
        "Software Engineer",
        "Python Developer",
        "Front-End Developer",
        "Backend Developer",
        "Cyber Security Analyst",
        "Network Engineer",
        "ASP .NET Developer",
        "Others"
    ];    



    return (
        <>
            <Button
                style={{ marginLeft: "45rem", marginTop: "1rem",position:"relative" }}
                sx={{ color: colors.grey[100], borderColor: colors.grey[100] }}
                variant="outlined"
                onClick={() => { setOpen(true); setFormType('expert'); }}
            >
                ADD EXPERT
            </Button>

            <BasicTable />
            <Dialog
                style={{ textAlign: "center" }}
                open={open && formType === 'expert'}
                onClose={handleClose}
                aria-labelledby="expert-form-dialog-title"
            >
                <DialogTitle id="expert-form-dialog-title">
                    ADD Expert
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please fill in the details for the Expert.
                    </DialogContentText>
                    <TextField
                        margin="dense"
                        id="Expert-Name"
                        name="name"
                        label="Expert Name"
                        type="text"
                        required
                        fullWidth
                        value={formData.name}
                        onChange={handleChange}
                        sx={sx}
                    />
                    <TextField
                        margin="dense"
                        id="expert-email"
                        name="email"
                        label="Email"
                        type="email"
                        required
                        fullWidth
                        value={formData.email}
                        onChange={handleChange}
                        sx={sx}
                    />
                    <TextField
                        margin="dense"
                        id="expert-mobile"
                        name="phone"
                        label="Mobile"
                        type="text"
                        required
                        fullWidth
                        value={formData.phone}
                        onChange={handleChange}
                        sx={sx}
                    />
                    <TextField
                        margin="dense"
                        id="expert-password"
                        name="password"
                        label="Password"
                        type="password"
                        required
                        fullWidth
                        value={formData.password}
                        onChange={handleChange}
                        sx={sx}
                    />
                    <FormControl fullWidth margin="normal">
                            <InputLabel
                                id="technology-label"
                                sx={{
                                    color: colors.greenAccent[500],
                                    '&.Mui-focused': {
                                        color: colors.greenAccent[500],
                                    },
                                }}
                            >
                                Technology
                            </InputLabel>
                            <Select
                                labelId="technology-label"
                                id="technology"
                                required
                                name="technology"
                                value={formData.technology}
                                onChange={handleChange}
                                sx={{
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.greenAccent[500],
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.greenAccent[500],
                                    },
                                }}
                            >
                                {technologyOptions.map((tech, index) => (
                                    <MenuItem key={index} value={tech}>{tech}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    <TextField
                     margin="dense"
                     id="expert-AdditionalSkills"
                     name="additionalSkills"
                     label="Additional Skills"
                     type="text"
                     required
                     fullWidth
                     value={formData.additionalSkills}
                     onChange={handleChange}
                     multiline
                     rows={4} 
                     sx={sx}
                          />
                    <TextField
                        margin="dense"
                        id="interview-price"
                        label="Interview Price"
                        type="number"
                        name="interview"
                        fullWidth
                        value={formData.interview}
                        onChange={handleChange}
                        sx={sx}
                    />
                    <TextField
                        margin="dense"
                        id="till-placement-interview-price"
                        label="Till Placement Interview Price"
                        type="number"
                        name="tillPlacementInterview"
                        fullWidth
                        value={formData.tillPlacementInterview}
                        onChange={handleChange}
                        sx={sx}
                    />
                    <TextField
                        margin="dense"
                        id="jd-session-price"
                        label="JD Session Price"
                        type="number"
                        name="jdSession"
                        fullWidth
                        value={formData.jdSession}
                        onChange={handleChange}
                        sx={sx}
                    />
                    <TextField
                        margin="dense"
                        id="assessment-price"
                        label="Assessment Price"
                        type="number"
                        name="assessment"
                        fullWidth
                        value={formData.assessment}
                        onChange={handleChange}
                        sx={sx}
                    />
                    <TextField
                        margin="dense"
                        id="ru-session-price"
                        label="RU Session Price"
                        type="number"
                        name="ruSession"
                        fullWidth
                        value={formData.ruSession}
                        onChange={handleChange}
                        sx={sx}
                    />
                    <TextField
                        margin="dense"
                        id="training-price"
                        label="Training Price"
                        type="number"
                        fullWidth
                        name="training"
                        value={formData.training}
                        onChange={handleChange}
                        sx={sx}
                    />
                    <TextField
                        margin="dense"
                         id="resume-building-price"
                        label="Resume Building Price"
                        type="number"
                        fullWidth
                        name="resumeBuilding"
                        value={formData.resumeBuilding}
                        onChange={handleChange}
                        sx={sx}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" sx={{ color:"red", borderColor: "red" }}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmitExpert}  variant="outlined" sx={{ color:colors.greenAccent[500], borderColor:colors.greenAccent[500]  }}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default AddExpert;