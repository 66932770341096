import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { getAllExpert, updateExpert } from '../../actions/Admin';
import { Box, Typography, Grid, useTheme, Button } from '@mui/material';
import { tokens } from '../../theme';
import { Link } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import EditExpert from './EditExpert';

export default function ExpertTable({ isSidebarOpen }) {
  const [editExpert, setEditExpert] = React.useState({});
  const [openEdit, setOpenEdit] = React.useState(false);
  const { experts } = useSelector((state) => state.admin);
  
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {currentPendingPayment,currentReceivedPayment, totalPendingPayment,totalReceivedPayment } = experts;
  

  React.useEffect(() => {
    dispatch(getAllExpert());
  }, [dispatch]);

  const handleEditClick = (exp) => {
    setEditExpert(exp);
    setOpenEdit(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditExpert({...editExpert,[name]:  value});
  };

  const handleEditSubmit = async () => {
    dispatch(updateExpert(editExpert._id, editExpert)).then((result) => {
      if (result.success) {
        dispatch(getAllExpert());
        setOpenEdit(false);
      }
    });
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const columns = [
    { field: 'name', headerName: 'EXPERT NAME', flex: 1 },
    { field: 'email', headerName: 'EMAIL', flex: 1 },
    { field: 'phone', headerName: 'PHONE', flex: 1 },
    { field: 'technology', headerName: 'TECHNOLOGY', flex: 1 },
    {
      field: 'profile',
      headerName: 'PROFILE',
      flex: 1,
      renderCell: (params) => (
        <Button variant="outlined" sx={{ color: colors.grey[100], borderColor: colors.grey[100] }} component={Link} to={`/admin/expertProfile/${params.row._id}`}>
          Profile
        </Button>
      ),
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      flex: 1,
      renderCell: (params) => (
        <>
        <Button
          variant="outlined"
          sx={{ color: colors.grey[100], borderColor: colors.grey[100] }}
          onClick={() => handleEditClick(params.row)}
        >
          <FaEdit />
        </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Grid container justifyContent="center" spacing={1} ml={10}>
        <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
          <Box width="80%" maxWidth="300px" boxShadow={5} borderRadius={5} bgcolor={colors.primary[300]} color="success.contrastText" p={1}>
            <Typography variant="h5" align="center">Total Paid Amount</Typography>
            <Typography variant="h5" align="center">{totalReceivedPayment}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
          <Box width="80%" maxWidth="300px" boxShadow={5} borderRadius={5} bgcolor={colors.primary[300]} color="success.contrastText" p={1}>
            <Typography variant="h5" align="center">Total Remaining Amount</Typography>
            <Typography variant="h5" align="center">{totalPendingPayment}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
          <Box width="80%" maxWidth="300px" boxShadow={5} borderRadius={5} bgcolor={colors.primary[300]} color="success.contrastText" p={1}>
            <Typography variant="h5" align="center">This Month Paid Amount</Typography>
            <Typography variant="h5" align="center">{currentReceivedPayment}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
          <Box width="80%" maxWidth="300px" boxShadow={5} borderRadius={5} bgcolor={colors.primary[300]} color="success.contrastText" p={1}>
            <Typography variant="h5" align="center">This Month Remaining Amount</Typography>
            <Typography variant="h5" align="center">{currentPendingPayment}</Typography>
          </Box>
        </Grid>
      </Grid>
      <br />
      <br />
      <br />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: isSidebarOpen ? 'calc(90%)' : 'calc(95%)',
          overflowX: 'auto',
          position: "relative",
        }}
      >
        <Box sx={{ height: 500,marginLeft:"17rem",position:"relative",minWidth:"85%"}}>
          <DataGrid
            rows={experts.experts || []}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]} 
            autoHeight
             getRowId={(row) => row._id}
            sx={{
            '.MuiDataGrid-root': {
              bgcolor: colors.primary[600],
            },
            '.MuiDataGrid-cell': {
              color: colors.grey[100],
            },
            '.MuiDataGrid-columnHeader': {
              bgcolor: colors.primary[700],
              color: colors.greenAccent[500],
            },
            '.MuiCheckbox-root': {
              color: colors.grey[100],
            },
            '& .Mui-disabled': {
              pointerEvents: 'none',
              opacity: 0.6,
            }, 
          }}
          />
        </Box>
        <EditExpert
          open={openEdit}
          handleClose={handleCloseEdit}
          expert={editExpert}
          handleEditChange={handleEditChange}
          handleEditSubmit={handleEditSubmit}
        />
      </Box>
    </>
  );
}
