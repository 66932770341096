import {createSlice} from "@reduxjs/toolkit"

const initialState={
    isLoading: false,
    error: null,
    tasks: [],
    todaytasks: [],
    complatedTasks: [],
}


const expertSlice=createSlice({
    name:"expert",
    initialState,
    reducers:{
        requestStart: (state) => {
            state.isLoading = false;
            state.error = null;
          },
          requestFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
          },
          clearError: (state) => {
              state.error = null;
            },
        getAllTaskSuccess:(state,action)=>{
            state.isLoading=false
            state.tasks=action.payload.tasks
            state.complatedTasks=action.payload.complatedTasks
        },
        todayTaskSuccess:(state,action)=>{
            state.isLoading=false
            state.todaytasks=action.payload
        },
    } 
})

export const {requestStart,requestFailure,getAllTaskSuccess,todayTaskSuccess,clearError}=expertSlice.actions

export default expertSlice.reducer